import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Slider.sass";

export const Carousel = ({children, arrows}:{children:any, arrows:boolean}) => {
	const settings = {
		dots: false,
		arrows: arrows,
		variableWidth: true,
		infinite: false,
	};

	return (
		<Slider {...settings}>
			{children}
		</Slider>
	)
}