import React, { useState } from "react";
import { useTranslation } from 'react-i18next'

const LanguageToggle = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language);

  const changeLanguageHandler = (language: any) => {
    i18n.changeLanguage(language)
    setLang(language);
  }

  const offset = (lang: string) =>
    ({
      en: "left-0.3333",
      uk: "left-1/3",
      tr: "left-2/3",
    }[lang]);
  
  return (
    <div className="fixed left-mobile md:left-auto md:right-mobile top-[17px] z-50">
      <div className={`flex items-center relative bg-light-grey font-bold rounded-full p-px`}>
        <div onClick={() => changeLanguageHandler("en")} className={`text-16 px-5 py-2.5 cursor-pointer transition duration-300 relative z-10 ${lang === "en" ? 'text-white' : 'text-dark-grey hover:text-black'}`}>EN</div>
        <div onClick={() => changeLanguageHandler("uk")} className={`text-16 px-5 py-2.5 cursor-pointer transition duration-300 relative z-10 ${lang === "uk" ? 'text-white' : 'text-dark-grey hover:text-black'}`}>UA</div>
        <div onClick={() => changeLanguageHandler("tr")} className={`text-16 px-5 py-2.5 cursor-pointer transition duration-300 relative z-10 ${lang === "tr" ? 'text-white' : 'text-dark-grey hover:text-black'}`}>TR</div>
        <div className={`bg-offers shadow-toggle rounded-full w-[62px] h-[40px] absolute transition-all duration-300 z-0 ${offset(lang)}`} />
      </div>
    </div>
  );
};
export default LanguageToggle;