import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import BackButton from "../core/BackButton";

import SavedOffer from "../offers/SavedOffer";
import { default as Member } from "../members/Post";

import { UserType, UserTypeEdge, OfferType } from "../../types";

// Queries
import { USER_PROFILE_QUERY } from "./_queries";

const Saved = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(USER_PROFILE_QUERY, {
    variables: { id: userId },
  });

  if (loading || error) return null;

  const offers = data.user.profile.savedOffers;
  const users = data.user.profile.savedUsers.edges.map(
    (edge: UserTypeEdge) => edge.node
  );
  const total = offers.length + users.length;

  return (
    <>
      <div className="relative z-10 w-full p-[34px] border-b border-border">
        <BackButton
          onBackLinkClick={() => {
            navigate(`/profile/${userId}`);
          }}
        />
        <h3 className="w-full text-center">
          ⭐️ You have <span className="text-members font-bold">{total}</span>{" "}
          saved items
        </h3>
      </div>

      {total > 0 ? (
        <>
          {offers.map(
            (offer: OfferType, index: string | number | undefined) => (
              <SavedOffer offer={offer} key={index} />
            )
          )}
          {users.map((user: UserType, index: string | number | undefined) => (
            <Member user={user} key={index} />
          ))}
        </>
      ) : (
        <span className="text-dark-grey p-mobile md:p-desktop block">
          Offers and members that you save will appear here
        </span>
      )}
    </>
  );
};

export default Saved;
