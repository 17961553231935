import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../core/Button";

import { ROUTES } from "../../constants";

const Compose = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`hidden md:flex flex-col justify-center w-1/2 border-l border-border w-full px-mobile md:px-desktop`}
    >
      <div className="flex flex-col items-center text-center">
        <h2 className="mb-2">{t("messages.new.start")}</h2>
        <span className="text-dark-grey mb-desktop">
          {t("messages.new.placeholder")}
        </span>
        <Button
          className="w-full max-w-sm"
          type="members"
          title={t("messages.new.new")}
          onClick={() => navigate(`${ROUTES.MESSAGES}/new`)}
        />
      </div>
    </div>
  );
};

export default Compose;
