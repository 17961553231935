import React from "react";
import { Link } from "react-router-dom";

import { IMAGE_PATH } from "../../constants";

import { UserType } from "../../types";

type UserProps = {
  user: UserType;
  small?: boolean;
};

const Image = ({ user, small }: UserProps) => {
  return (
    <div className="flex items-start">
      <Link
        to={{ pathname: `/profile/${user.id}` }}
        state={{ prevPath: window.location.pathname }}
      >
        {user.profile?.picture ? (
          <div
            style={{ backgroundImage: `url(${IMAGE_PATH}${user.profile?.picture})` }}
            className={`${small && 'w-[30px] h-[30px]'} w-[50px] h-[50px] md:w-[60px] md:h-[60px] !bg-center !bg-cover rounded-full gradient-1`}
          />
        ) : (
          <span
            className={`${small && '!w-[30px] !h-[30px]'} w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-full font-bold text-20 text-white flex items-center justify-center gradient-1 pt-px`}
          >
            {!small && user.firstName?.charAt(0)}{!small && user.lastName?.charAt(0)}
          </span>
        )}
      </Link>
    </div>
  )
};
export default Image;
