import { ApolloClient, createHttpLink, fromPromise } from "@apollo/client";

import { onError } from "@apollo/client/link/error";

import { ME_QUERY } from "../components/accounts/_queries";

import { REFRESH_TOKEN_MUTATION } from "./queries";

import {
  DEFAULT_PATH,
  GQLAuthErrors,
  REFRESH_TOKEN,
  TOKEN,
} from "../constants";

import cache from "./cache";

const API_URL =
  process.env.GRAPHQL_HTTP_ENDPOINT || window.location.protocol + DEFAULT_PATH;

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const refreshTokenApiClient = new ApolloClient({
  link: createHttpLink({ uri: API_URL }),
  cache,
  credentials: "include",
});

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async () => {
  const oldRefreshToken = localStorage.getItem(REFRESH_TOKEN);

  const {
    data: {
      refreshToken: { token: newToken, refreshToken: newRefreshToken },
      success,
    },
  } = await refreshTokenApiClient.mutate({
    mutation: REFRESH_TOKEN_MUTATION,
    variables: { input: { refreshToken: oldRefreshToken } },
  })!;

  if (success) {
    localStorage.set(TOKEN, newToken);
    localStorage.setItem(REFRESH_TOKEN, newRefreshToken);
  }
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.message) {
        case GQLAuthErrors.EXPIRED_TOKEN:
          if (!isRefreshing) {
            setIsRefreshing(true);

            return fromPromise(
              getNewToken().catch(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                localStorage.clear();

                // Cache shared with main client instance
                refreshTokenApiClient!.writeQuery({
                  query: ME_QUERY,
                  data: { me: null },
                });

                return forward(operation);
              })
            ).flatMap(() => {
              resolvePendingRequests();
              setIsRefreshing(false);

              return forward(operation);
            });
          } else {
            return fromPromise(
              new Promise<void>((resolve) => {
                addPendingRequest(() => resolve());
              })
            ).flatMap(() => {
              return forward(operation);
            });
          }
      }
    }
  }
});

export default errorLink;
