import React, { useEffect /*SyntheticEvent*/, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MessageType } from "../../types";
import { ROUTES } from "../../constants";

// Components
import { TradeMessage } from "./TradeMessage";
import { TradeInput } from "./TradeInput";
import { MessageInput } from "./MessageInput";
import { Message } from "./Message";
import { Modal } from "../core/Modal";

// Queries
import { DIALOGUE_QUERY } from "./_queries";
import { NOTIFICATION_SUBSCRIPTION } from "../core/_queries";
import { ME_QUERY } from "../accounts/_queries";

// Helpers
import { modes } from "./reducer";

// Assets
import BackButton from "../core/BackButton";
import Echo from "../../assets/svg/brand/echo.svg";

interface ConversationProps {
  id: string;
}

const Conversation = ({ id }: ConversationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentMode, updateMode] = useState(modes.SEND_MESSAGE);

  const { data: dataMe } = useQuery(ME_QUERY);

  const { loading, error, data, subscribeToMore } = useQuery(DIALOGUE_QUERY, {
    variables: { id: id },
  });

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATION_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.notificationSubscription;
        return Object.assign({}, prev, {
          dialogue: {
            messages: [newMessage, ...prev.dialogue.messages],
          },
        });
      },
    });
  }, [subscribeToMore]);

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const initSendMessage = () => {
    updateMode(modes.SEND_MESSAGE);
  };

  const initCreateTrade = () => {
    updateMode(modes.CREATE_TRADE);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (loading || error) return null;

  const handleBackButtonClick = () => {
    navigate(ROUTES.MESSAGES);
  };

  const handleTradeComplete = () => {
    initSendMessage();
  };

  const handleTradeCancel = () => {
    initSendMessage();
  };

  const handleAddButtonClick = () => {
    initCreateTrade();
  };

  const messages = (
    <>
      {data.dialogue.messages?.map((message: MessageType, index: number) => {
        return message.trade ? (
          <TradeMessage message={message} key={index} />
        ) : (
          <Message message={message} key={index} />
        );
      })}
      <div ref={messagesEndRef} />
    </>
  );

  const placeholderMessage = t("messages.new.beginning", {
    name: data.dialogue?.formattedTitle,
  });

  const placeholderStatus = t("messages.new.only");

  const placeholder = (
    <div className="flex flex-col">
      <div className="flex">
        <img
          src={Echo}
          alt="Echo"
          className="w-[50px] h-[50px] rounded-full mr-mobile"
        />
        <div className="bg-light-grey px-5 py-3 rounded-xl">
          {placeholderMessage}
        </div>
      </div>
      <span className="text-12 text-grey ml-auto mt-5">
        {placeholderStatus}
      </span>
    </div>
  );

  return (
    <div className={`flex flex-col w-1/2 border-l border-border w-full`}>
      <div className="w-full flex justify-center items-center relative min-h-[99.5px] px-mobile md:px-desktop border-b border-border">
        <BackButton style="adaptive" onBackLinkClick={handleBackButtonClick} />
        <span className="font-bold text-black text-18">
          {data.dialogue?.formattedTitle}
        </span>
      </div>
      <div
        className="p-mobile md:p-desktop h-[calc(100vh-280px)] md:h-[calc(100vh-210px)] lg:h-[calc(100vh-330px)] overflow-auto"
        id="conversation-log"
      >
        {data.dialogue.messages.length ? messages : placeholder}
      </div>

      {currentMode === modes.SEND_MESSAGE ? (
        <MessageInput
          dialogue={data.dialogue}
          onAddButtonClick={handleAddButtonClick}
        />
      ) : (
        <Modal show={true} closeModal={handleTradeCancel}>
          <TradeInput
            dialogue={data.dialogue}
            user={dataMe.me}
            onCancel={handleTradeCancel}
            onComplete={handleTradeComplete}
            prevPath={location.pathname}
          />
        </Modal>
      )}
    </div>
  );
};

export default Conversation;
