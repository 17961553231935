import React from "react";
import { Trans } from "react-i18next";

export const Info = () => (
  <div className="flex flex-col">
    <h3 className="mb-5 text-center">
      💡 <Trans i18nKey="sign_up.info.what">What is Echo?</Trans>
    </h3>
    <span className="bg-[#FFE6DF]/50 p-mobile rounded-xl">
      <Trans i18nKey="sign_up.info.intro">
        Echo is a place where impact-focused entrepreneurs can access the
        support and skills they need to succeed. From finance and legal, to
        business modelling and wellbeing - tap into a community of experts
        offering their time, 1 hour for 1 Echo.
      </Trans>
    </span>
    <h3 className="mt-10 mb-5 text-center">
      💡 <Trans i18nKey="sign_up.info.how">How Echo works</Trans>
    </h3>
    <span className="bg-[#FFE6DF]/50 p-mobile rounded-xl mb-2.5">
      <Trans i18nKey="sign_up.info.get_help">
        <span className="font-bold">Get help:</span> Search for experts with the
        skills you need
      </Trans>
    </span>
    <span className="bg-[#FFE6DF]/50 p-mobile rounded-xl mb-2.5">
      <Trans i18nKey="sign_up.info.offer_help">
        <span className="font-bold">Offer help:</span> Add an offer to let other
        members know how you can help
      </Trans>
    </span>
    <span className="bg-[#FFE6DF]/50 p-mobile rounded-xl mb-2.5">
      <Trans i18nKey="sign_up.info.message">
        <span className="font-bold">Message:</span> Start a conversation and
        arrange to meet online or in person
      </Trans>
    </span>
    <span className="bg-[#FFE6DF]/50 p-mobile rounded-xl">
      <Trans i18nKey="sign_up.info.send">
        <span className="font-bold">Send Echos:</span> After your meeting, send
        an Echo to say thank you. 1 Echo = 1 hour of support
      </Trans>
    </span>
  </div>
);

export default Info;
