import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";

import useNotification from "../core/Notification";
import { NOTIFICATION_SUBSCRIPTION } from "./_queries";
import { Segment } from "../../types";

import LanguageToggle from "./LanguageToggle";

// Third-party packages
import { v4 as uuidv4 } from "uuid";

type LinkProps = {
  title: string;
  path: string;
  active?: boolean;
  notification?: boolean;
  onClick: () => void;
};

const Link = ({ title, path, notification }: LinkProps) => {
  const location = useLocation();

  return (
    <NavLink
      to={path}
      state={{ prevPath: window.location.pathname }}
      className={`text-white text-40 leading-loose underline-offset-8 decoration-1 transition duration-300 md:text-20 md:leading-relaxed md:py-3.5 md:px-6 md:w-full md:leading-tight md:rounded-full md:mb-3 md:hover:text-offers ${
        location.pathname.includes(path)
          ? `underline md:no-underline md:font-bold ${
              path === "/members" || path === "/messages"
                ? "md:bg-members-100 md:text-members hover:md:!text-members"
                : "md:bg-offers/10 md:text-offers md:hover:text-offers"
            }`
          : "md:bg-white md:text-black"
      }`}
    >
      <span className="title">{title}</span>
      {notification ? <span className="notification"></span> : ""}
    </NavLink>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data } = useSubscription(NOTIFICATION_SUBSCRIPTION);

  //console.log(error);
  //console.log(data);

  const { notifications, addNotification, removeNotification } =
    useNotification();

  useEffect(() => {
    if (data && data.notificationSubscription) {
      addNotification({
        id: uuidv4(),
        text: data.notificationSubscription.text,
        segment: data.notificationSubscription.segment,
        type: data.notificationSubscription.type,
        action: data.notificationSubscription.action,
        subselection: data.notificationSubscription.subselection,
        author: data.notificationSubscription.author,
      });
    }
  }, [data]); // eslint-disable-line

  const aNotifs = notifications.filter((n) => n.segment === Segment.ACTIVITY);
  const pNotifs = notifications.filter((n) => n.segment === Segment.PEOPLE);
  const mNotifs = notifications.filter((n) => n.segment === Segment.MESSAGES);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={`relative z-10 ${
          open ? "bg-white/20" : "navigation"
        } w-[50px] h-[50px] rounded-full flex flex-col justify-center items-center cursor-pointer md:hidden`}
      >
        <span className="bg-white w-[25px] h-[3px] my-[2.5px]"></span>
        <span className="bg-white w-[25px] h-[3px] my-[2.5px]"></span>
        <span className="bg-white w-[25px] h-[3px] my-[2.5px]"></span>
      </div>

      <LanguageToggle />

      <nav
        className={`${
          open ? "flex" : "hidden md:flex"
        } navigation fixed z-0 w-full h-full top-0 left-0 flex-col items-center justify-center md:relative md:order-2 md:w-auto md:h-auto`}
      >
        <Link
          title={t("navigation.offers")}
          path={"/offers"}
          notification={aNotifs.length > 0}
          onClick={() =>
            aNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        />
        <Link
          title={t("navigation.members")}
          path={"/members"}
          notification={pNotifs.length > 0}
          onClick={() =>
            pNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        />
        <Link
          title={t("navigation.messages")}
          path={"/messages"}
          active={true}
          notification={mNotifs.length > 0}
          onClick={() =>
            mNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        />
        <Link
          title={t("navigation.requests")}
          path={"/request"}
          active={true}
          onClick={() => null}
        />
      </nav>
    </>
  );
};
export default Navigation;
