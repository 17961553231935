import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

// Components
import { Logo } from "./_core";

// Configuration
import { ROUTES } from "../../constants";

// Types
import { ErrorType } from "../../types";

// Queries

// Core logic
import { ValidationType } from "./_types";

// Assets

// Stages
import CommunitiesStage from "./CommunitiesStage";
import ProfileStage from "./ProfileStage";

import { Step } from "./_types";
import SkillsStage from "./SkillsStage";

const SignUp = () => {
  const { community: communitySlug } = useParams();

  const [formState, setFormState] = useState({
    step: Step.Profile,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    picture: "",
    savingImage: false,
    editingImage: false,
    time: 1,
    skills: [] as string[],
    errors: [] as ErrorType[],
    validation: [] as ValidationType[],
    roles: [] as string[],
    places: [] as string[],
    selectedPlaces: [] as string[],
    communities: communitySlug ? [communitySlug] : [],
  });

  return (
    <>
      <Logo />
      <form className="relative flex flex-col w-full h-full items-center justify-center z-10">
        {formState.step === Step.Profile && (
          <ProfileStage formState={formState} setFormState={setFormState} />
        )}
        {formState.step === Step.Communities && (
          <CommunitiesStage formState={formState} setFormState={setFormState} />
        )}
        {formState.step === Step.Skills && (
          <SkillsStage formState={formState} setFormState={setFormState} />
        )}
        {formState.step === Step.Complete && (
          <Navigate to={ROUTES.OFFERS} replace />
        )}
      </form>
    </>
  );
};

export default SignUp;
