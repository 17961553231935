import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Components
import BackButton from "../core/BackButton";
import Button from "../core/Button";
import { Toast } from "../core/Toast";
import { Logo, Title, Input, CTA, Gradient } from "./_core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from "./_queries";

// Assets
import { ErrorType } from "../../types";

enum Step {
  Start,
  Complete,
}

const SendPasswordResetEmail = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    email: "",
    errors: [] as ErrorType[],
    step: Step.Start,
  });

  const [sendPasswordResetEmail, resultSendPasswordResetEmail] = useMutation(
    SEND_PASSWORD_RESET_EMAIL_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (resultSendPasswordResetEmail.data) {
      const success =
        resultSendPasswordResetEmail.data.sendPasswordResetEmail.success;
      if (success) {
        setFormState({
          ...formState,
          errors: [],
          step: Step.Complete,
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message: t("sign_up.error"),
            },
          ],
        });
      }
    }
  }, [resultSendPasswordResetEmail.data]);

  const validateEmail = () => {
    if (formState.email !== "") {
      return true;
    }
    return false;
  };

  return (
    <>
      <Logo />
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden md:w-[calc(100%-40px)] md:h-[calc(100%-100px)] md:top-20 md:left-5 md:rounded-[40px] md:bg-offers/10">
        <form className="absolute z-10 bg-transparent w-full h-full flex flex-col items-center justify-center px-5 md:right-0 md:w-1/2 md:bg-light-grey md:rounded-[40px]">
          <BackButton
            onBackLinkClick={() => {
              navigate(ROUTES.LOGIN);
            }}
          />
          {formState.step === Step.Start && (
            <>
              <Title title={t("reset.title") || ""} />
              <Input
                title={t("reset.email") || ""}
                value={formState.email}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value.toLowerCase(),
                  })
                }
                error={formState.errors.length > 0}
              />
              {formState.errors.length !== 0 && (
                <Toast
                  show={true}
                  className="error"
                  message={formState.errors[0].message}
                />
              )}
              <Button
                className="mt-16 w-full max-w-sm"
                title={t("login.next") || ""}
                onClick={(e) => {
                  e.preventDefault();
                  if (validateEmail()) {
                    sendPasswordResetEmail({
                      variables: { email: formState.email },
                    });
                  }
                }}
              />
              <CTA
                text={t("reset.remembered") || ""}
                path="login"
                link={t("reset.sign_in") || ""}
              />
            </>
          )}
          {formState.step === Step.Complete && (
            <>
              <Title title={t("reset.ready") || ""} />
              <CTA path="login" link={t("activate.ready") || ""} />
            </>
          )}
        </form>
        <Gradient />
      </div>
    </>
  );
};

export default SendPasswordResetEmail;
