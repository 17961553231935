import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Types
import { SkillType } from "../../types";
import {
  ROUTES,
  timeOptions,
  languageOptions,
  availabilityOptions,
} from "../../constants";

// Queries
import {
  SKILLS_QUERY,
  UPDATE_USER_PROFILE_FULL_MUTATION,
  UPDATE_USER_PROFILE_PICTURE_MUTATION,
  USER_PROFILE_QUERY,
} from "./_queries";
import { ME_QUERY } from "../accounts/_queries";

// Components
import Selector from "../core/Selector";
import Button from "../core/Button";
import SingleSelector from "../core/SingleSelector";
import ImageInput from "../core/ImageInput";
import BackButton from "../core/BackButton";

enum Step {
  Skills,
  SortSkills,
}

enum Mode {
  TIME = "time",
  SKILLS = "skills",
}

const ProfileEdit = () => {
  const { t } = useTranslation();
  const { userId, mode } = useParams();

  const { loading, data } = useQuery(USER_PROFILE_QUERY, {
    variables: { id: userId },
  });

  const { data: dataSkills } = useQuery(SKILLS_QUERY);

  const [coreState, setCoreState] = useState({
    skills: [],
    languages: [],
  });

  useEffect(() => {
    if (dataSkills) {
      setCoreState({
        ...coreState,
        skills: dataSkills.skills,
      });
    }
  }, [dataSkills]);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    project: "",
    role: "",
    picture: "",
    skills: [] as string[],
    starSkills: [] as string[],
    languages: [] as string[],
    availability: [] as string[],
    location: "",
    linkedIn: "",
    time: 0,
    step: Step.Skills,
    savingImage: false,
    editingImage: false,
  });

  useEffect(() => {
    if (data) {
      setFormState({
        ...formState,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        bio: data.user.profile?.bio,
        project: data.user.profile?.project,
        role: data.user.profile?.role,
        picture: data.user.profile?.picture,
        skills: data.user.profile?.skills?.map(
          (v: { skill: { title: any } }) => v.skill.title
        ),
        languages: data.user.profile?.languages || [],
        availability: data.user.profile?.availability || [],
        location: data.user.profile?.location,
        linkedIn: data.user.profile?.linkedIn,
        time: data.user.profile?.time,
      });
    }
  }, [loading, data]);

  const [updateUserProfile, result] = useMutation(
    UPDATE_USER_PROFILE_FULL_MUTATION,
    {
      variables: {
        firstName: formState.firstName,
        lastName: formState.lastName,
        bio: formState.bio,
        project: formState.project,
        role: formState.role,
        languages: formState.languages,
        availability: formState.availability,
        skills: formState.skills,
        starSkills: formState.starSkills,
        location: formState.location,
        linkedIn: formState.linkedIn,
        time: formState.time,
      },
      refetchQueries: [ME_QUERY],
    }
  );

  const [updateUserProfilePicture, resultPicture] = useMutation(
    UPDATE_USER_PROFILE_PICTURE_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (result.data) {
      navigate(`${ROUTES.PROFILE}/${data.user.id}`);
    }
  }, [result.data]);

  useEffect(() => {
    if (resultPicture.data) {
      const picture =
        resultPicture.data.updateUserProfilePicture.user.profile?.picture;
      setFormState({
        ...formState,
        picture: picture,
        savingImage: false,
        editingImage: false,
      });
    }
  }, [resultPicture.data]);

  const coreFields = (
    <div className="w-full h-full">
      <div className="flex relative items-center justify-between w-full h-[90px]">
        <BackButton
          onBackLinkClick={() => {
            navigate(`/profile/${userId}`);
          }}
        />
      </div>

      <div className="flex flex-col h-[calc(100%-210px)] overflow-scroll border-y border-border">
        <ImageInput
          imgURL={formState.picture}
          saving={formState.savingImage}
          editing={formState.editingImage}
          uploadImage={(params) => {
            setFormState({ ...formState, savingImage: true });
            updateUserProfilePicture(params);
          }}
          startEditing={() => {
            setFormState({ ...formState, editingImage: true });
          }}
          stopEditing={() => {
            setFormState({ ...formState, editingImage: false });
          }}
        />

        <div className="text-18 flex flex-col relative">
          <div className="grid grid-cols-2 w-full gap-mobile md:gap-desktop p-mobile md:p-desktop border-b border-border">
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="firstname">
                {t("profile.first_name")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="firstname"
                value={formState.firstName}
                placeholder="Jane"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="firstname">
                {t("profile.last_name")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="lastname"
                value={formState.lastName}
                placeholder="Smith"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="project">
                {t("profile.company")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="project"
                value={formState.project}
                placeholder="XYZ Club"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    project: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="role">
                {t("profile.current_role")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="role"
                value={formState.role}
                placeholder="Creative Director"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    role: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="location">
                {t("profile.location")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="location"
                value={formState.location}
                placeholder="London, UK"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    location: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="block mb-2.5 font-bold" htmlFor="linkedIn">
                LinkedIn
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="linkedIn"
                value={formState.linkedIn}
                placeholder="http://www.linkedin.com"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    linkedIn: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2.5 font-bold" htmlFor="bio">
                {t("profile.about_you")}
              </label>
              <input
                className="border border-border p-3.5 rounded-sm outline-members w-full"
                type="text"
                name="bio"
                value={formState.bio}
                placeholder="Marketer, Mom and Yogi"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    bio: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div>
          <label
            className="block mb-2.5 font-bold ml-mobile md:ml-desktop mt-mobile md:mt-desktop text-18"
            htmlFor="skills"
          >
            {t("profile.how_help")}
          </label>
          <Selector
            style="members"
            type="icon"
            options={coreState.skills.map((s: SkillType) => s.title)}
            selected={formState.skills}
            onSelect={(v: string[]) => {
              setFormState({
                ...formState,
                skills: v,
              });
            }}
          />
        </div>

        <div>
          <label
            className="block mb-2.5 font-bold ml-mobile md:ml-desktop mt-mobile md:mt-desktop text-18"
            htmlFor="skills"
          >
            {t("profile.add_languages")}
          </label>
          <Selector
            style="members"
            options={languageOptions}
            selected={formState.languages}
            onSelect={(v: string[]) => {
              setFormState({
                ...formState,
                languages: v,
              });
            }}
          />
        </div>

        <div>
          <label
            className="block mb-2.5 font-bold ml-mobile md:ml-desktop mt-mobile md:mt-desktop text-18"
            htmlFor="skills"
          >
            {t("profile.add_availability")}
          </label>
          <Selector
            style="members"
            options={availabilityOptions}
            selected={formState.availability}
            onSelect={(v: string[]) => {
              setFormState({
                ...formState,
                availability: v,
              });
            }}
          />
        </div>
      </div>
      <Button
        type="members"
        className="fixed w-[calc(100%-40px)] bottom-mobile left-1/2 translate-x-[-50%] md:w-[calc(700px-60px)] xl:w-[calc(50%-60px)]  md:bottom-desktop md:right-desktop md:left-auto md:translate-x-0 md:max-w-none"
        title="Update"
        onClick={() => {
          // e.preventDefault();
          updateUserProfile();
        }}
      />
    </div>
  );

  const timeFields = (
    <>
      <div className="relative z-10 w-full p-mobile md:p-desktop border-b border-border">
        <BackButton
          onBackLinkClick={() => {
            navigate(`/profile/${userId}`);
          }}
        />
        <h3 className="w-full text-center">{t("profile.how_much_time")}</h3>
      </div>
      <div className="p-mobile md:p-desktop">
        <SingleSelector
          options={timeOptions}
          selected={`${formState.time}`}
          onSelect={(v: string | number) => {
            setFormState({
              ...formState,
              time: parseInt(`${v}`),
            });
          }}
        />
        <Button
          className="w-full mt-5"
          type="members"
          title={t("profile.update")}
          onClick={(e) => {
            e.preventDefault();
            updateUserProfile();
          }}
        />
      </div>
    </>
  );

  switch (mode) {
    case Mode.TIME:
      return <>{timeFields}</>;
    default:
      return <>{coreFields}</>;
  }
};

export default ProfileEdit;
