import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  SaveOutline,
  SendOutline,
  MessageOutline,
  UnsaveOutline,
} from "../../assets/icons/global";

import { DEFAULT_URL, ROUTES } from "../../constants";
import { UserType } from "../../types";

import {
  UPDATE_SAVED_OFFERS,
  UPDATE_SAVED_USERS,
  USER_SAVED_QUERY,
} from "../members/_queries";

import { ME_QUERY } from "../accounts/_queries";

import {
  CREATE_DIALOGUE_MUTATION,
  DIALOGUES_QUERY,
} from "../conversation/_queries";
import { setError } from "./utils";

type DropdownProps = {
  type: string;
  invert?: boolean;
  direction?: string;
  id?: string;
  user?: UserType;
  me?: any;
};

const Dropdown = ({ type, invert, direction, id, user }: DropdownProps) => {
  const { t } = useTranslation();
  const [visible, updateVisibility] = useState(false);
  const [[status, label], setStatus] = useState([false, ""]);
  const { data } = useQuery(ME_QUERY);
  const me = data.me.pk;

  const navigate = useNavigate();

  const toggleDropdown = () => {
    updateVisibility(!visible);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStatus([false, ""]);
    }, 2000);
    return () => clearTimeout(timer);
  }, [status]);

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  const [formState, setFormState] = useState({
    offers: [] as string[],
    users: [] as string[],
  });

  const { loading: loadingSaved, data: dataSaved } = useQuery(
    USER_SAVED_QUERY,
    {
      variables: { id: `${me}` },
    }
  );

  useEffect(() => {
    if (dataSaved) {
      setFormState({
        ...formState,
        offers: dataSaved.user.profile?.savedOffers?.map(
          (o: { id: { id: string } }) => o.id
        ),
        users: dataSaved.user.profile?.savedUsers?.edges.map(
          (u: { node: any; id: { id: string } }) => u.node.id
        ),
      });
    }
  }, [loadingSaved, dataSaved]);

  const detectOffers = () => {
    if (formState.offers && formState.offers.includes(id!)) {
      return formState.offers.filter((item) => item !== id);
    } else {
      return [id!, ...formState.offers];
    }
  };

  const detectUsers = () => {
    if (formState.users && formState.users.includes(id!)) {
      return formState.users.filter((item) => item !== id);
    } else {
      return [id!, ...formState.users];
    }
  };

  const [updateSavedOffers] = useMutation(UPDATE_SAVED_OFFERS, {
    variables: {
      offers: detectOffers(),
    },
    refetchQueries: [USER_SAVED_QUERY],
  });

  const [updateSavedUsers] = useMutation(UPDATE_SAVED_USERS, {
    variables: {
      users: detectUsers(),
    },
    refetchQueries: [USER_SAVED_QUERY],
  });

  const message = () => {
    createDialogue({ variables: { userId: user?.id } });
  };

  const share = () => {
    navigator.clipboard.writeText(
      type === "member"
        ? `${DEFAULT_URL}/profile/${user?.id}`
        : `${DEFAULT_URL}/profile/${user?.id}/${id}`
    );
    setStatus([true, t("profile.copied")]);
  };

  const save = () => {
    if (type === "member") {
      updateSavedUsers();
      setStatus([
        true,
        formState.users && formState.users.includes(id!)
          ? t("profile.user_removed")
          : t("profile.user_saved"),
      ]);
    } else {
      updateSavedOffers();
      setStatus([
        true,
        formState.offers && formState.offers.includes(id!)
          ? t("profile.offer_removed")
          : t("profile.offer_saved"),
      ]);
    }
  };

  return (
    <>
      <div
        onClick={toggleDropdown}
        className="w-[30px] h-[30px] flex items-center justify-center group cursor-pointer -mr-3 z-30 relative"
      >
        <div className="flex flex-col justify-between h-[18px]">
          <span
            className={`rounded-full w-1 h-1 group-hover:bg-black transition duration-300 ${
              visible ? `bg-black` : `${invert ? `bg-white` : `bg-grey`}`
            }`}
          ></span>
          <span
            className={`rounded-full w-1 h-1 group-hover:bg-black transition duration-300 ${
              visible ? `bg-black` : `${invert ? `bg-white` : `bg-grey`}`
            }`}
          ></span>
          <span
            className={`rounded-full w-1 h-1 group-hover:bg-black transition duration-300 ${
              visible ? `bg-black` : `${invert ? `bg-white` : `bg-grey`}`
            }`}
          ></span>
        </div>

        {status && (
          <div
            className={`absolute right-[32px] text-12 font-bold px-2 py-1 text-white rounded-md w-auto whitespace-nowrap ${
              type === "members" ? "bg-members" : "bg-offers"
            }`}
          >
            {label}
          </div>
        )}

        <div
          className={`${visible ? "flex flex-col" : "hidden"} ${
            direction === "up"
              ? "bottom-[calc(100%+10px)]"
              : "top-[calc(100%+10px)]"
          } transition duration-300 absolute right-[13px] border border-border shadow-card overflow-hidden bg-white rounded-[14px] w-fit`}
        >
          {!user?.isCurrentUser && (
            <div
              onClick={message}
              className="flex items-center justify-end text-13 font-bold w-full py-3 pl-[18px] pr-[15px] border-b border-border transition duration-300 cursor-pointer bg-white hover:bg-light-grey"
            >
              <span className="mr-2.5 text-right whitespace-nowrap">
                {t("members.listing.send_message")}
              </span>
              <MessageOutline />
            </div>
          )}
          <div
            onClick={share}
            className="flex items-center justify-end text-13 font-bold w-full py-3 pl-[18px] pr-[15px] border-b border-border transition duration-300 cursor-pointer bg-white hover:bg-light-grey"
          >
            <span className="mr-2.5 text-right whitespace-nowrap">
              {type === "offer"
                ? t("offers.listing.share")
                : type === "member"
                ? t("members.listing.share")
                : null}
            </span>
            <SendOutline />
          </div>
          {!user?.isCurrentUser && (
            <div
              onClick={save}
              className="flex items-center justify-end text-13 font-bold w-full py-3 pl-[18px] pr-[15px] transition duration-300 cursor-pointer bg-white hover:bg-light-grey"
            >
              <span className="mr-2.5 text-right whitespace-nowrap">
                {type === "offer"
                  ? formState.offers && formState.offers.includes(id!)
                    ? t("profile.remove_offer")
                    : t("profile.save_offer")
                  : type === "member"
                  ? formState.users && formState.users.includes(id!)
                    ? t("profile.remove_profile")
                    : t("profile.save_profile")
                  : null}
              </span>
              {type === "offer" ? (
                formState.offers && formState.offers.includes(id!) ? (
                  <UnsaveOutline />
                ) : (
                  <SaveOutline />
                )
              ) : type === "member" ? (
                formState.users && formState.users.includes(id!) ? (
                  <UnsaveOutline />
                ) : (
                  <SaveOutline />
                )
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
