import React from "react";
import { useNavigate } from "react-router-dom";

import icon from "../../assets/svg/icons/cancel.svg";

type CancelButtonProps = {
  pathname?: string;
  onClick?: () => void;
  label?: string;
};

const CancelButton = ({
  pathname,
  onClick,
  label = "Cancel",
}: CancelButtonProps) => {
  const navigate = useNavigate();
  return (
    <button
      className="w-[30px] h-[30px] bg-black/20 rounded-full transition duration-300 hover:bg-black"
      type="button"
      id="cancel"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        pathname ? navigate(pathname, { replace: true }) : navigate(-1);
      }}
    >
      <img src={icon} alt={label} />
    </button>
  );
};

export default CancelButton;
