import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Echo } from "../../assets/svg/brand/echo.svg";

import { BalanceToast } from "./BalanceToast";

type BalanceProps = {
  balance: number;
};

const Balance = ({ balance }: BalanceProps) => {
  const { t } = useTranslation();
  const [visible, updateVisibility] = useState(false);
  const handleClick = () => {
    updateVisibility(!visible);
  };
  return (
    <div
      className="relative flex items-center h-[50px] md:h-[60px] pl-[11px] md:justify-end"
      onClick={handleClick}
    >
      <Echo className="w-auto h-[30px]" />
      <span className="mx-2.5 text-16 font-bold md:mr-0 md:text-grey">
        {balance}
      </span>
      {visible && (
        <BalanceToast
          show={visible}
          text={t("balance.message")}
          label={t("balance.label")}
          link=""
          onCloseClick={handleClick}
        />
      )}
    </div>
  );
};

export default Balance;
