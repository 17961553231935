import React from "react";

type SingleSelectorProps = {
  options: { label: string; value: string | number }[];
  selected: string | number;
  selectedIcon?: string;
  deselectedIcon?: string;
  onSelect: (v: string | number) => void;
};

const SingleSelector = ({
  options,
  selected,
  onSelect,
}: SingleSelectorProps) => {
  return (
    <div>
      {options.map((option, i) => (
        <span
          className={`bg-light-grey p-2.5 w-full block text-center rounded-full font-bold mb-2.5 cursor-pointer transition duration-300 hover:bg-members hover:text-white ${selected === option.value ? " bg-members text-white" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            onSelect(selected !== option.value ? option.value : "");
          }}
          key={i}
        >
          {option.label}
        </span>
      ))}
    </div>
  );
};

export default SingleSelector;
