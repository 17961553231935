import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client/react";
import { DIALOGUES_QUERY } from "./_queries";

import { DialogueType, NotificationType, Segment } from "../../types";
import Dialogue from "./Dialogue";

import useNotification from "../core/Notification";
import Greeting from "../core/Greeting";

interface DialogueListProps {
  dialogues?: DialogueType[];
}

const DialogueList = ({ dialogues }: DialogueListProps) => {
  const { loading, error, data, refetch } = useQuery(DIALOGUES_QUERY);
  const { id } = useParams();

  const { notifications } = useNotification();

  useEffect(() => {
    if (notifications.filter((n) => n.segment === Segment.MESSAGES).length) {
      // When a message notification arrives, trigger a refetch of dialogues,
      // in case there's a new dialogue.
      refetch();
    }
  }, [notifications]);

  if (loading || error) return null;

  const mNotifs = notifications.filter(
    (n) => n.segment === Segment.MESSAGES
  ) as NotificationType[];

  const isDialogue = (item: DialogueType | undefined): item is DialogueType => {
    return !!item;
  };

  const _dialogues = (dialogues || data.dialogues).filter(isDialogue);

  return (
    <>
      <div className="hidden lg:block p-mobile md:p-desktop border-b border-border">
        <Greeting type="messages" total={_dialogues.length} />
      </div>
      <div className="h-[calc(100vh-170px)] md:h-[calc(100vh-120px)] lg:h-[calc(100vh-220px)] overflow-scroll">
        {_dialogues
          .sort((a: DialogueType, b: DialogueType) =>
            a.timestamp < b.timestamp ? 1 : -1
          )
          .map((dialogue: DialogueType, index: number) => (
            <Dialogue
              dialogue={dialogue}
              key={index}
              selected={dialogue.id === id ? true : false}
              notifications={mNotifs.filter(
                (n): n is NotificationType =>
                  n.subselection !== undefined &&
                  n.subselection?.indexOf(`${dialogue.id}`) > -1
              )}
            />
          ))}
        </div>
    </>
  );
};
export default DialogueList;
