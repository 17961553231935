import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ME_QUERY } from "../accounts/_queries";

import {
  Search,
  Region,
  Availability,
  Community,
} from "../../assets/icons/global";

import {
  availabilityOptions,
  languageOptions,
  FilterMode,
} from "../../constants";

import { useQuery } from "@apollo/client";
import { CommunityType } from "../../types";

type SidebarProps = {
  toggleOverlay: any;
  overlay: boolean;
  languages?: string[];
  communities?: string[];
  availability?: string[];
  onChangeLanguage?: (languages: string, active: boolean) => void;
  onChangeAvailability?: (availability: string, active: boolean) => void;
  onChangeCommunity?: (community: string, active: boolean) => void;
};

const Sidebar = ({
  toggleOverlay,
  overlay,
  languages,
  availability,
  onChangeLanguage,
  onChangeAvailability,
}: SidebarProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [communityOptions, setCommunityOptions] = useState([]);

  const { data: dataMe } = useQuery(ME_QUERY);

  useEffect(() => {
    if (dataMe && dataMe.me.profile.communities) {
      setCommunityOptions(
        dataMe.me.profile.communities.map((e: CommunityType) => [
          e.name,
          e.slug,
        ])
      );
    }
  }, [dataMe]); // eslint-disable-line

  return (
    <div
      className={`${
        overlay ? "block" : "hidden xl:block"
      } fixed z-50 w-full h-full top-0 left-0 backdrop-blur xl:sticky xl:w-auto xl:h-auto xl:left-auto xl:bg-white xl:backdrop-blur-none ${
        location.pathname === "/members" ? "bg-members/80" : "bg-offers/80"
      }`}
    >
      <div
        className={`fixed top-desktop left-5 w-[calc(100%-40px)] h-[calc(100%-60px)] flex flex-col rounded-xl font-bold border xl:shrink-0 xl:h-auto xl:w-[320px] xl:px-desktop xl:pt-desktop xl:my-desktop xl:mr-desktop xl:sticky xl:relative xl:top-desktop ${
          location.pathname === "/members"
            ? "bg-members-100 border-members/50 text-members"
            : "bg-offers-100 border-offers/50 text-offers"
        }`}
      >
        <div className="h-[calc(100%-50px)] overflow-scroll">
          <Filter
            type={languageOptions.map((opt) => [opt, opt])}
            title={`${t("filter.language.title")}`}
            mode={FilterMode.Language}
            optionChange={onChangeLanguage}
            target={languages}
            location={location}
          />
          <Filter
            type={availabilityOptions.map((opt) => [opt, opt])}
            title={`${t("filter.availability.title")}`}
            mode={FilterMode.Availability}
            optionChange={onChangeAvailability}
            target={availability}
            location={location}
          />
          {/* {communityOptions.length > 0 && (
            <Filter
              type={communityOptions.map((opt) => [opt[0], opt[1]])}
              title={`${t("Filter by")} ${t("community")}`}
              mode={FilterMode.Community}
              optionChange={onChangeCommunity}
              target={communities}
              location={location}
            />
          )} */}
        </div>

        <div
          onClick={toggleOverlay}
          className={`w-full text-center cursor-pointer transition duration-300 absolute bottom-0 left-0 p-3.5 font-bold rounded-b-xl xl:hidden ${
            location.pathname === "/members"
              ? "bg-members-200 text-members"
              : "bg-offers-200 text-offers"
          }`}
        >
          {t("Done")}
        </div>
      </div>
    </div>
  );
};

export const FilterIcon = ({ toggleOverlay }: { toggleOverlay: any }) => {
  return (
    <div
      onClick={toggleOverlay}
      className={`xl:hidden w-[50px] h-[50px] shrink-0 ml-desktop rounded-full border border-border flex items-center justify-center cursor-pointer transition duration-300 hover:bg-offers group`}
    >
      <Search />
    </div>
  );
};

type FilterProps = {
  type: string[][];
  title: string;
  optionChange: any;
  mode: number;
  target?: string[];
  location?: any;
};

export const Filter = ({
  type,
  title,
  mode,
  optionChange,
  target,
  location,
}: FilterProps) => {
  return (
    <div className="py-[50px] border-b border-black/20 xl:py-0 xl:border-none xl:mb-[15px] overflow-hidden">
      <div className="flex items-center mb-[50px] text-22 text-center xl:mb-desktop xl:text-16 xl:text-left">
        {mode === FilterMode.Language && (
          <Region
            style={location.pathname === "/members" ? "members" : "offers"}
          />
        )}
        {mode === FilterMode.Availability && (
          <Availability
            type="filter"
            style={location.pathname === "/members" ? "members" : "offers"}
          />
        )}
        {mode === FilterMode.Community && (
          <Community
            type="filter"
            style={location.pathname === "/members" ? "members" : "offers"}
          />
        )}
        <span className="ml-1.5">{title}</span>
      </div>
      <div
        className={`${
          type.length === 2 ? "grid-cols-2" : "grid-cols-3"
        } grid px-5 gap-y-[50px] xl:grid-cols-2 xl:gap-x-[30px] xl:gap-y-0 xl:px-0`}
      >
        {type.map((option, index) => (
          <Checkbox
            key={index}
            label={option[0]}
            value={option[1]}
            optionChange={optionChange}
            active={target?.includes(option[1])}
          />
        ))}
      </div>
    </div>
  );
};

type CheckboxProps = {
  label: string;
  value: string;
  optionChange: any;
  active?: boolean;
};

export const Checkbox = ({
  label,
  value,
  optionChange,
  active,
}: CheckboxProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(active);

  const handleClick = () => {
    optionChange(value, isChecked);
    setIsChecked((prev) => !prev);
  };

  return (
    <label
      className={`${
        isChecked ? "opacity-100" : "opacity-50 hover:opacity-100"
      } cursor-pointer transition duration-300 flex flex-col shrink-0 items-center mb-[15px] xl:flex-row`}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleClick}
        className={`cursor-pointer relative rounded-md shrink-0 w-5 h-5 mb-3 appearance-none border-[2px] xl:mr-3 xl:mb-0 before:content-[''] before:transition before:duration-300 before:absolute before:top-1/2 before:left-1/2 before:translate-x-[-50%] before:translate-y-[-50%] before:w-[13px] before:h-[13px] before:rounded-[4px] before:opacity-0
          ${
            location.pathname === "/members"
              ? "border-members before:bg-members"
              : "border-offers before:bg-offers"
          } ${isChecked && `before:opacity-100`}`}
      />
      <span className="mt-px leading-none">{t(label)}</span>
    </label>
  );
};

export default Sidebar;
