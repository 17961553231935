import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CancelButton from "./CancelButton";
import { ReactComponent as Echo } from "../../assets/svg/brand/echo.svg";

type BalanceToastProps = {
  show: boolean;
  text: string;
  label: string;
  link: string;
  onCloseClick: () => void;
};

export const BalanceToast = ({
  show,
  text,
  label,
  link,
  onCloseClick,
}: BalanceToastProps) => {
  const { t } = useTranslation();
  const [state] = useState({
    show: show,
  });
  return (
    <div
      className="w-[calc(100%-40px)] left-mobile top-[80px] bg-offers-100 z-50 p-mobile fixed rounded-xl md:top-[70px] md:left-0 md:absolute md:w-full"
      style={{ display: state.show ? "block" : "none" }}
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <Echo className="w-auto h-[30px] mr-2.5" />
          <span className="font-bold">{t("balance.small")}</span>
        </div>
        <CancelButton onClick={onCloseClick} />
      </div>
      <span className="block my-2.5 text-14">{text}</span>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="font-bold text-offers transition duration-300 hover:text-black"
      >
        {label}
      </a>
    </div>
  );
};
