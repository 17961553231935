import React, { ReactChildren, ReactChild } from "react";

type ModalProps = {
  children: ReactChild | ReactChildren;
  show: boolean;
  closeModal?: () => void;
};

export const Modal = ({
  children,
  show,
  closeModal,
}: ModalProps) => (
  <div
    className="w-screen h-screen fixed z-50 top-0 left-0 flex justify-center items-center"
    style={{ display: show ? "block" : "none" }}
  >
    <div className="w-full h-full absolute z-0 bg-members/80 backdrop-blur-md" onClick={closeModal} />
    <div className="absolute w-[calc(100%-40px)] h-auto max-w-lg bg-white z-10 rounded-xl left-1/2 top-1/2 translate-y-[-50%] translate-x-[-50%]">
      {/*<span className="close" onClick={closeModal}>
        &times;
      </span>*/}
      {children}
    </div>
  </div>
);