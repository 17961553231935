import React, { useEffect } from "react";

type Props = {
  children: JSX.Element;
  type: string;
};

export const Drawer = ({ children, type }: Props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div
        className={`backdrop-blur w-screen h-screen fixed top-0 left-0 z-40 ${
          type === "offers"
            ? "bg-offers/80"
            : type === "requests"
            ? "bg-requests/80"
            : "bg-members/80"
        }`}
      ></div>
      <div className="fixed top-0 right-0 w-full md:w-[700px] xl:w-1/2 z-50 bg-white h-full z-50 md:rounded-l-[30px] overflow-y-scroll shadow-drawer">
        {children}
      </div>
    </>
  );
};

export default Drawer;
