import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

// Components
import Header from "../core/Header";
import { Search } from "../core/Search";
import Filter from "../core/Filter";
import Greeting from "../core/Greeting";
import CreateOffer from "../core/CreateOffer";
import Toggle from "../core/Toggle";
import Feed from "./Feed";
import { FilterIcon } from "../core/Filter";

// Types
import { CommunityType, UserTypeEdge } from "../../types";

// Queries
import { USER_SEARCH_QUERY } from "./_queries";
import { ME_QUERY } from "../accounts/_queries";

// Constants
import { DefaultPaginationLimit } from "../../constants";

const Members = () => {
  const [overlay, setOverlay] = useState(false);
  const [state, setState] = useState({
    query: "",
    languages: [] as string[],
    availability: [] as string[],
    communities: [] as string[],
    nodes: [],
    pageInfo: "",
    totalCount: 0,
    options: [],
  });

  const { data: dataMe } = useQuery(ME_QUERY);

  useEffect(() => {
    if (dataMe && dataMe.me.profile) {
      setState({
        ...state,
        communities: dataMe.me.profile.communities.map(
          (com: CommunityType) => com.slug
        ),
      });
    }
  }, [dataMe]); // eslint-disable-line

  const [executeSearch, { loading, data, fetchMore }] = useLazyQuery(
    USER_SEARCH_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data && data.userSearch) {
      setState({
        ...state,
        nodes: data.userSearch.edges.map((edge: UserTypeEdge) => edge.node),
        pageInfo: data.userSearch.pageInfo,
        totalCount: data.userSearch.totalCount,
      });
    }
  }, [data]); // eslint-disable-line

  const runQuery = () => {
    executeSearch({
      variables: {
        query: state.query,
        languages: state.languages,
        availability: state.availability,
        communities: state.communities,
        first: DefaultPaginationLimit,
        after: "",
      },
    });
  };

  // Initial set up.
  useEffect(() => {
    runQuery();
  }, []);

  useEffect(() => {
    runQuery();
  }, [state.query, state.availability, state.languages, state.communities]);

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  const updateLanguages = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        languages: state.languages.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        languages: [...state.languages, label],
      });
    }
  };

  const updateAvailability = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        availability: state.availability.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        availability: [...state.availability, label],
      });
    }
  };

  const updateCommunities = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        communities: state.communities.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        communities: [...state.communities, label],
      });
    }
  };

  const pageInfo = data ? data.userSearch.pageInfo : "";

  const toggleOverlay = () => setOverlay((overlay) => !overlay);

  return (
    <React.Fragment>
      <Header />
      <main>
        <div className="hidden md:block">
          <Search
            mode="Search"
            placeholder="Search by name or skill..."
            onChangeQuery={updateQuery}
            query={state.query}
          />
        </div>
        <div className="flex items-center justify-between mb-desktop mx-mobile md:mx-desktop">
          <Greeting type="members" total={state.totalCount} />
          <FilterIcon toggleOverlay={toggleOverlay} />
        </div>
        <Toggle />
        <div className="flex items-start border-t border-border">
          {!loading && (
            <>
              <Feed
                users={state.nodes}
                more={pageInfo.hasNextPage}
                onLoadMore={() => {
                  if (pageInfo.hasNextPage) {
                    fetchMore({
                      variables: {
                        after: pageInfo.endCursor,
                      },
                    });
                  }
                }}
              />
              <Filter
                overlay={overlay}
                onChangeLanguage={updateLanguages}
                onChangeAvailability={updateAvailability}
                onChangeCommunity={updateCommunities}
                toggleOverlay={toggleOverlay}
                languages={state.languages}
                availability={state.availability}
                communities={state.communities}
              />
            </>
          )}
        </div>
      </main>
      <CreateOffer />
    </React.Fragment>
  );
};

export default Members;
