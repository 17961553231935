import { gql } from "@apollo/client";

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileBase(
    $firstName: String
    $lastName: String
    $bio: String
    $project: String
    $role: String
    $location: String
    $linkedIn: String
    $time: Int
    $skills: [String]
    $languages: [String]
  ) {
    updateUserProfileBase(
      firstName: $firstName
      lastName: $lastName
      bio: $bio
      project: $project
      skills: $skills
      languages: $languages
      role: $role
      location: $location
      linkedIn: $linkedIn
      time: $time
    ) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          project
          skills {
            id
            skill
          }
          languages
          role
          location
          linkedIn
          time
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_FULL_MUTATION = gql`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $bio: String
    $project: String
    $role: String
    $linkedIn: String
    $location: String
    $skills: [String]
    $languages: [String]
    $availability: [String]
    $starSkills: [String]
    $time: Int
    $roles: [String]
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      bio: $bio
      project: $project
      role: $role
      linkedIn: $linkedIn
      skills: $skills
      starSkills: $starSkills
      languages: $languages
      availability: $availability
      location: $location
      time: $time
      roles: $roles
    ) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          balance
          complete
          project
          role
          skills {
            skill {
              id
              title
            }
            offering
          }
          availability
          languages
          linkedIn
          location
          time
          roles
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_SKILLS_MUTATION = gql`
  mutation UpdateUserProfileSkills($skills: [String], $starSkills: [String]) {
    updateUserProfileSkills(skills: $skills, starSkills: $starSkills) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          skills {
            skill {
              id
              title
            }
            offering
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateUserProfilePicture($file: Upload!) {
    updateUserProfilePicture(file: $file) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          skills {
            skill {
              id
              title
            }
            offering
          }
        }
      }
    }
  }
`;

export const UPDATE_OFFER_PICTURE_MUTATION = gql`
  mutation UpdateOfferPicture($id: String, $file: Upload!) {
    updateOfferPicture(id: $id, file: $file) {
      status
      formErrors
      offer {
        id
        picture
      }
    }
  }
`;

export const CREATE_BOOKING_MUTATION = gql`
  mutation CreateBooking($id: String!, $start: String!, $end: String!) {
    createBooking(id: $id, start: $start, end: $end) {
      success
      errors
    }
  }
`;

export const USER_PROFILE_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      isCurrentUser
      profile {
        id
        preferredName
        balance
        bio
        complete
        picture
        project
        role
        location
        linkedIn
        time
        taken
        skills {
          skill {
            id
            title
          }
          offering
        }
        languages
        calendar {
          timezone
          busy {
            start
            end
          }
        }
        offers {
          id
          title
          short
          description
          picture
          price
          availability
          skills {
            id
            title
          }
          languages
          taken
        }
        notes {
          creator {
            id
            profile {
              id
              preferredName
            }
          }
          text
        }
        savedOffers {
          id
          title
          short
          description
          price
          profile {
            id
            preferredName
            bio
            picture
            project
            role
            location
          }
          availability
          languages
        }
        savedUsers {
          edges {
            node {
              id
              firstName
              lastName
              profile {
                id
                preferredName
                bio
                picture
                project
                role
                time
                taken
                skills {
                  skill {
                    id
                    title
                  }
                  offering
                }
                offers {
                  id
                  title
                  short
                }
              }
            }
          }
          totalCount
        }
      }
    }
  }
`;

export const USER_SAVED_QUERY = gql`
  query Saved($id: String!) {
    user(id: $id) {
      id
      profile {
        id
        savedOffers {
          id
          title
          short
          description
          price
          profile {
            id
            preferredName
            bio
            picture
            project
            role
            location
          }
          availability
          languages
        }
        savedUsers {
          edges {
            node {
              id
              firstName
              lastName
              profile {
                id
                preferredName
                bio
                picture
                project
                role
                time
                taken
                skills {
                  skill {
                    id
                    title
                  }
                  offering
                }
                offers {
                  id
                  title
                  short
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_PROFILE_SHORT_QUERY = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      isCurrentUser
      profile {
        id
        preferredName
        picture
        project
        role
        time
        taken
        skills {
          skill {
            id
            title
          }
          offering
        }
      }
    }
  }
`;

export const USER_SEARCH_QUERY = gql`
  query UserSearch(
    $query: String!
    $availability: [String]
    $languages: [String]
    $communities: [String]
    $first: Int
    $after: String
  ) {
    userSearch(
      query: $query
      availability: $availability
      languages: $languages
      communities: $communities
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          firstName
          lastName
          profile {
            id
            preferredName
            bio
            picture
            project
            role
            time
            taken
            skills {
              skill {
                id
                title
              }
              offering
            }
            offers {
              id
              title
              short
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edgeCount
    }
  }
`;

export const SKILLS_QUERY = gql`
  query Skills {
    skills {
      title
      category
    }
  }
`;

export const UPDATE_SAVED_USERS = gql`
  mutation updateUserSavedUsers($users: [String]) {
    updateUserSavedUsers(users: $users) {
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          role
          time
          taken
          skills {
            skill {
              id
              title
            }
            offering
          }
          offers {
            id
            title
            short
          }
          savedUsers {
            edges {
              node {
                id
                firstName
                lastName
                profile {
                  id
                  preferredName
                  bio
                  picture
                  project
                  role
                  time
                  taken
                  skills {
                    skill {
                      id
                      title
                    }
                    offering
                  }
                  offers {
                    id
                    title
                    short
                  }
                }
              }
            }
          }
        }
      }
      status
      formErrors
    }
  }
`;

export const UPDATE_SAVED_OFFERS = gql`
  mutation updateUserSavedOffers($offers: [String]) {
    updateUserSavedOffers(offers: $offers) {
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          role
          time
          taken
          skills {
            skill {
              id
              title
            }
            offering
          }
          offers {
            id
            title
            short
          }
          savedOffers {
            id
            title
            short
            description
            price
            profile {
              id
              preferredName
              bio
              picture
              project
              role
              location
            }
            availability
            languages
          }
        }
      }
      status
      formErrors
    }
  }
`;
