import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Types
import { Mode } from "./_types";

// Components
import SignUp from "./SignUp";
import CancelButton from "../core/CancelButton";
import Info from "./Info";
import SignUpImage from "../../assets/images/sign-up.png";
import LanguageToggle from "./LanguageToggle";
import SignUpStart from "./SignUpStart";
import SignUpGoogle from "./SignUpGoogle";
import { Logo, Gradient } from "./_core";

export const SignUpView = () => {
  const { t } = useTranslation();

  const [info, setInfo] = useState(false);
  const [mode, setMode] = useState(Mode.START);

  const SignUpComponent = () => {
    switch (mode) {
      case Mode.SIGNUP:
        return <SignUp />;
      case Mode.SIGNUP_GOOGLE:
        return <SignUpGoogle />;
      default:
        return <SignUpStart setMode={setMode} />;
    }
  };

  return (
    <>
      <Logo />
      <div
        onClick={() => setInfo((prevInfo) => !prevInfo)}
        className="absolute flex items-center right-[140px] md:right-[235px] top-[29px] md:top-6 group cursor-pointer z-50"
      >
        <span className="w-5 h-5 flex justify-center items-center text-center rounded-full bg-base-red text-white mr-2.5 transition duration-300 group-hover:bg-black">
          i
        </span>
        <span className="text-base-red text-14 md:text-20 transition duration-300 group-hover:text-black">
          {t("sign_up.info.what")}
        </span>
      </div>
      <LanguageToggle />
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden md:w-[calc(100%-40px)] md:h-[calc(100%-100px)] md:top-20 md:left-5 md:rounded-[40px] md:bg-offers/10">
        <div className="absolute z-10 bg-transparent w-full h-full flex flex-col items-center justify-center md:right-0 md:w-1/2 md:bg-light-grey md:rounded-[40px] overflow-y-scroll">
          {SignUpComponent()}
        </div>
        <div className="hidden md:flex absolute w-1/2 h-full justify-center items-center z-50">
          {info ? (
            <div className="bg-white rounded-[40px] p-desktop m-desktop max-w-[640px]">
              <CancelButton onClick={() => setInfo((prevInfo) => !prevInfo)} />
              <Info />
            </div>
          ) : (
            <img
              src={SignUpImage}
              className="w-full h-auto max-w-[430px] p-desktop"
              alt=""
            />
          )}
        </div>
        <Gradient />
      </div>
    </>
  );
};

export default SignUpView;
