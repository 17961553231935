import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Components
import BackButton from "../core/BackButton";
import Button from "../core/Button";
import { Toast } from "../core/Toast";
import { Logo, Title, Input, Gradient } from "./_core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { PASSWORD_RESET_MUTATION } from "./_queries";

// Assets
import { ErrorType } from "../../types";

enum Step {
  Start,
  Complete,
}

const PasswordReset = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    newPassword1: "",
    newPassword2: "",
    errors: [] as ErrorType[],
    step: Step.Start,
  });

  const [passwordReset, resultPasswordReset] = useMutation(
    PASSWORD_RESET_MUTATION
  );

  const { token } = useParams();

  useEffect(() => {
    if (resultPasswordReset.data) {
      const success = resultPasswordReset.data.passwordReset.success;
      if (success) {
        setFormState({
          ...formState,
          errors: [],
          step: Step.Complete,
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message: t("reset.error"),
            },
          ],
        });
      }
    }
  }, [resultPasswordReset.data, formState]);

  const validatePassword = () => {
    if (
      formState.newPassword1 !== "" &&
      formState.newPassword1 === formState.newPassword2
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Logo />
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden md:w-[calc(100%-40px)] md:h-[calc(100%-100px)] md:top-20 md:left-5 md:rounded-[40px] md:bg-offers/10">
        {token && (
          <form className="absolute z-10 bg-transparent w-full h-full flex flex-col items-center justify-center px-5 md:right-0 md:w-1/2 md:bg-light-grey md:rounded-[40px]">
            {formState.step === Step.Start && (
              <>
                <Title title={t("reset.title") || ""} />
                <p>{t("reset.small")}</p>
                <Input
                  type="password"
                  placeholder={t("reset.placeholder") || ""}
                  value={formState.newPassword1}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      newPassword1: e.target.value,
                    })
                  }
                  error={formState.errors.length > 0}
                />
                <Input
                  type="password"
                  placeholder={t("reset.confirm") || ""}
                  value={formState.newPassword2}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      newPassword2: e.target.value,
                    })
                  }
                  error={formState.errors.length > 0}
                />
                {formState.errors.length !== 0 && (
                  <Toast
                    show={true}
                    className="error"
                    message={formState.errors[0].message}
                  />
                )}
                <Button
                  title={t("login.next") || ""}
                  className="mt-14 w-full max-w-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    if (validatePassword()) {
                      passwordReset({
                        variables: {
                          token: token,
                          newPassword1: formState.newPassword1,
                          newPassword2: formState.newPassword2,
                        },
                      });
                    }
                  }}
                />
              </>
            )}
            {formState.step === Step.Complete && (
              <>
                <Title title={t("reset.success") || ""} />
                <Link to={{ pathname: ROUTES.LOGIN }}>
                  {t("reset.sign_in")}
                </Link>
              </>
            )}
          </form>
        )}
        <Gradient />
      </div>
    </>
  );
};

export default PasswordReset;
