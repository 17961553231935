import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../core/Button";
import Post from "./Post";

import { ActionType } from "../../types";

type FeedProps = {
  actions?: ActionType[];
  more: boolean;
  onLoadMore: any;
};

const Feed = ({ actions, more, onLoadMore }: FeedProps) => {
  const { t } = useTranslation();

  const shuffle = (array: ActionType[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <div className="w-full">
      {actions?.length ? (
        shuffle(actions)?.map(
          (action: ActionType, index: string | number | undefined) =>
            action.target.title && (
              <Post offer={action.target} user={action.actor} key={index} />
            )
        )
      ) : (
        <h2 className="p-mobile md:p-desktop text-grey">{t("offers.empty")}</h2>
      )}
      {more && (
        <Button
          title="Load more"
          onClick={onLoadMore}
          type="offers"
          className="mb-[90px] w-[calc(100%-40px)] m-mobile md:m-desktop md:w-[calc(100%-60px)]"
        />
      )}
    </div>
  );
};

export default Feed;
