import React from "react";
import { Icon } from "../../assets/icons/skills";
import { useTranslation } from "react-i18next";

type SelectorProps = {
  options: string[];
  selected: string[];
  className?: string;
  type?: string;
  style?: string;
  centered?: boolean;
  onSelect: (v: string[]) => void;
};

const Selector = ({
  options,
  selected,
  type,
  style,
  centered,
  onSelect,
}: SelectorProps) => {
  const { t } = useTranslation();
  return (
    <div className="hide-scrollbar">
      <div className="whitespace-nowrap overflow-x-auto w-full">
        <div
          className={`relative inline-flex items-center ml-mobile my-desktop md:ml-desktop ${
            centered && "w-full justify-center mx-0 ml-0 md:ml-0"
          }`}
        >
          {options.map((option, i) => (
            <div
              className={`flex flex-col shrink-0 items-center font-bold mr-[30px] md:mr-[60px] group cursor-pointer transition duration-300 hover:opacity-100 ${
                centered ? "last:mr-[30px] md:last:mr-[60px]" : "last:mr-[30px]"
              } ${
                style === "members"
                  ? "text-members"
                  : style === "requests"
                  ? "text-requests"
                  : "text-offers"
              } ${
                selected.indexOf(option) === -1 ? "opacity-40" : "opacity-100"
              } ${centered && "last:mr-0"}`}
              onClick={(e) => {
                e.preventDefault();
                onSelect(
                  selected.indexOf(option) === -1
                    ? [...selected, option]
                    : selected.filter((v) => v !== option)
                );
              }}
              key={i}
            >
              {type === "icon" ? (
                <div className="group-hover:scale-110 transition duration-300">
                  <Icon type={style} title={option} />
                </div>
              ) : (
                <div
                  className={`cursor-pointer relative rounded-md w-5 h-5 appearance-none border-[2px] before:content-[''] before:transition before:duration-300 before:absolute before:top-1/2 before:left-1/2 before:translate-x-[-50%] before:translate-y-[-50%] before:w-[13px] before:h-[13px] before:rounded-[4px] before:opacity-0 ${
                    style === "members"
                      ? "border-members before:bg-members"
                      : style === "requests"
                      ? "border-requests before:bg-requests"
                      : "border-offers before:bg-offers"
                  } ${selected.indexOf(option) !== -1 && `before:opacity-100`}`}
                ></div>
              )}

              <span className="mt-3">{t(option)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Selector;
