import React from "react";

import ProfileImage from "./ProfileImage";

import { formatTime } from "../core/utils";

import { MessageType } from "../../types";

type MessageProps = {
  message: MessageType;
};

export const Message = ({ message }: MessageProps) => {
  return (
  <div className={`flex flex-col mb-5`}>
    <div className={`flex ${message.own && 'flex-row-reverse'}`}>
      <ProfileImage image={message.author?.profile?.picture} name={message.author?.profile?.preferredName} />
      <div className={`${message.own ? 'bg-members text-white mr-mobile' : 'bg-light-grey text-black ml-mobile'} px-5 py-3 rounded-xl`}>{message.text}</div>
    </div>
    <div className={`text-12 text-grey mt-5 ml-auto`} data-raw={message.timestamp}>{formatTime(message.timestamp)}</div>
  </div>
)
      }
