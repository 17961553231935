import React from "react";
import { useTranslation } from "react-i18next";

import { SkillSelect } from "../core/Skills";

type SearchProps = {
  placeholder?: string;
  mode?: string;
  query?: string;
  skills?: string[];
  filterOptions?: string[];
  onChangeQuery?: (query: string) => void;
  onChangeFilter?: (label: string, active: boolean) => void;
};

export const Search = ({
  placeholder = "All",
  mode = "Search",
  query = "",
  skills = [],
  filterOptions = [],
  onChangeQuery,
  onChangeFilter,
}: SearchProps) => {
  const { t } = useTranslation();
  if (mode === "Search") {
    return (
      <input
        className="bg-light-grey text-16 font-bold mx-mobile md:mx-desktop mb-desktop py-[14.5px] md:py-[19.5px] px-desktop rounded-full border border-border w-[calc(100%-40px)] md:w-[calc(100%-60px)] leading-none focus:outline-none"
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={(e) => onChangeQuery && onChangeQuery(e.target.value)}
      ></input>
    );
  } else if (mode === "Skills") {
    return (
      <div className="hide-scrollbar">
        <div className="whitespace-nowrap overflow-x-auto w-full">
          <div className="relative inline-flex items-center ml-mobile my-desktop md:ml-desktop">
            {[placeholder, ...filterOptions].map((skill, index) => (
              <SkillSelect
                title={index === 0 ? t("filter.all") : t(skill)}
                key={index}
                active={
                  skill === "All Offers"
                    ? skills.length === 0
                      ? true
                      : false
                    : skills.includes(skill)
                }
                onClick={(e) =>
                  onChangeFilter &&
                  onChangeFilter(skill, skills.includes(skill))
                }
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
