import React, { useState } from "react";

import { useTranslation } from "react-i18next";

type CheckboxProps = {
  label: string;
  value: string;
  optionChange: any;
  active?: boolean;
  bg?: string;
};

export const Checkbox = ({
  label,
  value,
  optionChange,
  active,
  bg,
}: CheckboxProps) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(active);

  const handleClick = () => {
    optionChange(value, isChecked);
    setIsChecked((prev) => !prev);
  };

  return (
    <label
      className={`${
        isChecked ? "opacity-100" : "opacity-50 hover:opacity-100"
      } cursor-pointer transition duration-300 flex flex-col shrink-0 items-center mb-[15px] xl:flex-row`}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleClick}
        className={`cursor-pointer relative rounded-md shrink-0 w-5 h-5 mb-3 appearance-none border-[2px] xl:mr-3 xl:mb-0 before:content-[''] before:transition before:duration-300 before:absolute before:top-1/2 before:left-1/2 before:translate-x-[-50%] before:translate-y-[-50%] before:w-[13px] before:h-[13px] before:rounded-[4px] before:opacity-0 ${
          bg ?? ""
        } ${isChecked && `before:opacity-100`}`}
      />
      <span className="mt-px leading-none">{t(label)}</span>
    </label>
  );
};

export default Checkbox;
