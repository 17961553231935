import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Types
import { OfferType } from "../../types";
import { IMAGE_PATH } from "../../constants";

// Assets
import { Availability } from "../../assets/icons/global";

import Time from "../core/Time";
import Dropdown from "../core/Dropdown";

type PostProps = {
  offer: OfferType;
};

const SavedOffer = ({ offer }: PostProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  // console.log(offer);

  return (
    <div className="w-full md:w-[calc(100%-60px)] border-b md:border border-border md:shadow-card md:rounded-xl p-mobile md:m-desktop md:p-desktop relative">
      <div className="flex">
        <div className="flex items-start">
          <Link
            to={{ pathname: `/profile/${offer.profile![0].id}` }}
            state={{ prevPath: window.location.pathname }}
          >
            {offer.profile![0].picture ? (
              <div
                style={{
                  backgroundImage: `url(${IMAGE_PATH}${
                    offer.profile![0].picture
                  })`,
                }}
                className={`w-[50px] h-[50px] md:w-[60px] md:h-[60px] !bg-center !bg-cover rounded-full gradient-1`}
              />
            ) : (
              <span
                className={`w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-full font-bold text-20 text-white flex items-center justify-center gradient-1 pt-px`}
              />
            )}
          </Link>
        </div>
        <div className="ml-mobile md:ml-desktop pr-[50px] flex flex-col">
          <Link
            className="text-18 md:text-24 leading-snug font-bold block text-offers md:my-2.5 md:order-2"
            to={{
              pathname: `/profile/${offer.profile![0].id}/${offer.id}`,
            }}
            state={{ prevPath: location.pathname }}
          >
            {offer.title}
          </Link>

          <div className="flex md:order-3">
            <span className="text-dark-grey mr-1">{t("offers.author")}</span>
            <Link
              className="text-dark-grey font-bold transition duration-300 hover:text-offers"
              to={{
                pathname: `/profile/${offer.profile![0].id}/`,
              }}
              state={{ prevPath: location.pathname }}
            >
              {offer.profile![0].preferredName}
            </Link>
          </div>

          <div className="flex flex-wrap items-center mt-1 md:mt-0 md:order-1">
            {offer.availability &&
              offer.availability.map((availability, index) => (
                <div
                  key={index}
                  className="flex shrink-0 items-center bg-offers-100 text-offers rounded-full mr-1.5 py-1 px-1.5 mt-1.5"
                >
                  <Availability type="button" />
                  <span className="ml-1 text-12 mt-px leading-none">
                    {t(availability)}
                  </span>
                </div>
              ))}
            {offer.languages &&
              offer.languages.map((language, index) => (
                <span
                  key={index}
                  className="border border-offers-100 text-offers rounded-[3px] mr-1.5 pt-[3px] pb-0.5 px-1.5 text-12 leading-snug mt-1.5"
                >
                  {t(language as unknown as string)}
                </span>
              ))}
          </div>
        </div>
      </div>

      <div className="absolute top-mobile md:top-desktop right-mobile md:right-desktop">
        <Time time={offer.price} />
      </div>

      <div className="absolute bottom-mobile md:bottom-desktop right-mobile md:right-desktop">
        <Dropdown type="offer" id={offer.id} user={offer.profile![0]} />
      </div>
    </div>
  );
};

export default SavedOffer;
