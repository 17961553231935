import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { LocationStateType } from "../../types";
import { availabilityOptions } from "../../constants";

import { ME_QUERY } from "../accounts/_queries";
import { CREATE_REQUEST } from "./_queries";

// import { SUBMIT_REQUEST } from "./_queries";

import Button from "../core/Button";
import CancelButton from "../core/CancelButton";
// import Saving from "../core/Saving";

// Components
import Selector from "../core/Selector";
import BasicEditor from "../external/BasicEditor";

enum Step {
  Start,
  Sent,
}

const Request = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const prevPath = (state as LocationStateType)?.prevPath;

  const {
    loading: loadingUser,
    error: errorUser,
  } = useQuery(ME_QUERY);

//   const user = dataMe?.me;

  const [formState, setFormState] = useState({
    step: Step.Start,
    title: "",
    description: "",
    availability: [] as string[],
    category: "",
    saving: false,
  });

  const [descriptionState, setDescriptionState] = useState("");

  useEffect(() => {
    if (descriptionState) {
      setFormState({
        ...formState,
        description: descriptionState,
      });
    }
  }, [descriptionState]);

  const [createRequest, result] = useMutation(CREATE_REQUEST, {
    variables: {
      title: formState.title,
      description: formState.description,
      availability: formState.availability,
      category: formState.category,
    },
  });

  useEffect(() => {
    if (result.data) {
      setFormState({ ...formState, saving: false, step: Step.Sent, });
    }
  }, [result.data]);

  if (loadingUser || errorUser) return null;

  const validate = () => {
    if (
      formState.title !== "" &&
      formState.description !== "" &&
      formState.description !== "<p><br></p>" &&
      formState.availability.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="w-full h-full">
      <div className="relative z-10 flex justify-between items-center p-mobile md:p-desktop border-b border-border">
        <CancelButton pathname={prevPath} />
        <h3>{t("requests.title")}</h3>
        <span className="w-[30px] h-[30px]"></span>
      </div>

      <div
        className={`flex flex-col h-[calc(100%-150px)] md:h-[calc(100%-210px)] overflow-scroll`}
      >
        {formState.step === Step.Start && (
          <>
            <span className="text-16 p-desktop border-b border-border text-center">
              {t("requests.small")}
            </span>
            <div className="flex flex-col grow">
              <div className="px-mobile py-desktop md:p-desktop">
                <input
                  type="text"
                  name="title"
                  className="text-22 font-bold mb-2.5 w-full focus:outline-none"
                  value={formState.title}
                  placeholder={t("requests.what_kind") || ""}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      title: e.target.value,
                    });
                  }}
                />
                <BasicEditor
                  placeholder={t("requests.tell_more") || ""}
                  initialValue=""
                  onEditorChange={(content: string) => {
                    setDescriptionState(content);
                  }}
                />
              </div>
              <div className="pt-desktop border-y border-border">
                <label htmlFor="availability">
                  <h3 className="w-full text-center">
                    {t("requests.how_support")}
                  </h3>
                </label>
                <Selector
                  style="requests"
                  options={availabilityOptions}
                  selected={formState.availability}
                  centered={true}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      availability: v,
                    });
                  }}
                />
              </div>
              <div className="px-mobile py-desktop md:p-desktop">
                <h3 className="w-full text-center">
                  {t("requests.suggest_category")}
                </h3>
                <input
                  type="text"
                  name="category"
                  className="text-22 md:text-30 font-bold mt-5 focus:outline-none w-full text-center text-requests"
                  value={formState.category}
                  placeholder={t("requests.category_suggestion") || ""}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      category: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="bg-white fixed bottom-0 w-full md:w-[700px] xl:w-1/2 border-t border-border">
              <Button
                title={t("requests.label")}
                type="requests"
                disabled={!validate()}
                className={`m-mobile md:m-desktop w-[calc(100%-40px)] md:w-[calc(100%-60px)]`}
                onClick={(e) => {
                  e.preventDefault();
                  if (formState.saving !== true) {
                    setFormState({ ...formState, saving: true });
                    createRequest();
                  }
                }}
              />
            </div>
          </>
        )}
        {formState.step === Step.Sent && (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="flex flex-col max-w-xs text-center">
              <span className="text-[50px]">🙌</span>
              <span className="text-[28px]">Your request has been sent to the Echo team</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Request;
