import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Components
import Header from "../core/Header";
import { Search } from "../core/Search";
import Filter from "../core/Filter";
import Greeting from "../core/Greeting";
import CreateOffer from "../core/CreateOffer";
import Toggle from "../core/Toggle";
import Feed from "./Feed";
import { FilterIcon } from "../core/Filter";

// Types
import {
  ActionTypeEdge,
  ActionType,
  SkillType,
  CommunityType,
} from "../../types";

// Queries
import { ACTIONS_QUERY } from "./_queries";
import { ME_QUERY } from "../accounts/_queries";

// Constants
import { DefaultPaginationLimit } from "../../constants";
import { SKILLS_QUERY } from "../members/_queries";

const Offers = () => {
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState(false);

  const [state, setState] = useState({
    query: "",
    skills: [] as string[],
    languages: [] as string[],
    availability: [] as string[],
    communities: [] as string[],
    nodes: [],
    pageInfo: "",
    totalCount: 0,
    options: [],
  });

  const { data: dataMe } = useQuery(ME_QUERY);

  useEffect(() => {
    if (dataMe && dataMe.me.profile) {
      setState({
        ...state,
        communities: dataMe.me.profile.communities.map(
          (com: CommunityType) => com.slug
        ),
      });
    }
  }, [dataMe]); // eslint-disable-line

  const [executeSearch, { /* loading, error, fetchMore, */ data }] =
    useLazyQuery(ACTIONS_QUERY, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (data && data.actions) {
      setState({
        ...state,
        nodes: data.actions.edges.map((edge: ActionTypeEdge) => edge.node),
        pageInfo: data.actions.pageInfo,
        totalCount: data.actions.totalCount,
      });
    }
  }, [data]); // eslint-disable-line

  const runQuery = () => {
    executeSearch({
      variables: {
        query: state.query,
        skills: state.skills || [],
        languages: state.languages || [],
        availability: state.availability || [],
        communities: state.communities,
        first: DefaultPaginationLimit,
        after: "",
      },
    });
  };

  // Initial set up.
  useEffect(() => {
    runQuery();
  }, []);

  useEffect(() => {
    runQuery();
  }, [
    state.query,
    state.skills,
    state.languages,
    state.availability,
    state.communities,
  ]);

  const { data: dataSkills } = useQuery(SKILLS_QUERY);

  /*
  const { loading, error, data, refetch, fetchMore } = useQuery(ACTIONS_QUERY, {
    variables: {
      query: state.query,
      skills: state.skills || [],
      languages: state.languages || [],
      availability: state.availability || [],
      first: DefaultPaginationLimit,
      after: "",
    },
  });

  useEffect(() => {
    refetch({
      languages: state.languages,
      availability: state.availability,
      skills: state.skills,
      query: state.query,
    });
  }, [state]); // eslint-disable-line
  */

  const updateSkills = (label: string, active: boolean) => {
    if (label === "All Offers") {
      setState({
        ...state,
        skills: [],
      });
    } else {
      if (active) {
        setState({
          ...state,
          skills: state.skills.filter((el) => el !== label),
        });
      } else {
        setState({
          ...state,
          skills: [...state.skills, label],
        });
      }
    }
  };

  const updateLanguages = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        languages: state.languages.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        languages: [...state.languages, label],
      });
    }
  };

  const updateAvailability = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        availability: state.availability.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        availability: [...state.availability, label],
      });
    }
  };

  const updateCommunities = (label: string, active: boolean) => {
    if (active) {
      setState({
        ...state,
        communities: state.communities.filter((el) => el !== label),
      });
    } else {
      setState({
        ...state,
        communities: [...state.communities, label],
      });
    }
  };

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  //if (loading) return null;
  //if (error) return null;

  //const nodes = data.actions.edges.map((edge: ActionTypeEdge) => edge.node);
  //const pageInfo = data.actions.pageInfo;

  const options =
    dataSkills && dataSkills.skills.map((e: SkillType) => e.title);

  const toggleOverlay = () => setOverlay((overlay) => !overlay);

  const offers = state.nodes.filter((action: ActionType) => action.target.id);
  const pageInfo = data ? data.actions.pageInfo : "";

  // console.log(pageInfo);

  return (
    <React.Fragment>
      <Header />
      <main>
        <div className="hidden md:block">
          <Search
            mode="Search"
            placeholder={t("members.search") || ""}
            onChangeQuery={updateQuery}
            query={state.query}
          />
        </div>
        <div className="flex items-center justify-between mb-desktop md:my-mobile mx-mobile md:mx-desktop">
          <Greeting type="offers" total={offers.length} />
          <FilterIcon toggleOverlay={toggleOverlay} />
        </div>
        <Toggle />
        <Search
          mode="Skills"
          placeholder="All Offers"
          onChangeFilter={updateSkills}
          skills={state.skills}
          filterOptions={options}
        />
        <div className="flex items-start border-t border-border">
          <Feed
            actions={offers}
            more={pageInfo.hasNextPage}
            onLoadMore={() => {
              if (pageInfo.hasNextPage) {
                executeSearch({
                  variables: {
                    after: pageInfo.endCursor,
                  },
                });
              }
            }}
          />
          <Filter
            overlay={overlay}
            onChangeLanguage={updateLanguages}
            onChangeAvailability={updateAvailability}
            onChangeCommunity={updateCommunities}
            toggleOverlay={toggleOverlay}
            languages={state.languages}
            communities={state.communities}
            availability={state.availability}
          />
        </div>
      </main>
      <CreateOffer />
    </React.Fragment>
  );
};

export default Offers;
