import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

// Caches query results in browser memory and enables 
// pagination over results within actions and the user search.
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      keyFields: [],
      fields: {
        actions: relayStylePagination(),
        userSearch: relayStylePagination(),
      },
    },
  },
});

export default cache;
