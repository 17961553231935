import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Assets
import { AccessSupport, OfferSupport } from "../../assets/icons/global";

// Types
import { SkillType } from "../../types";
import Button from "../core/Button";
import Selector from "../core/Selector";

// Core logic
import { Title } from "./_core";
import { StageProps, Step } from "./_types";

// Queries
import {
  SKILLS_QUERY,
  UPDATE_USER_PROFILE_FULL_MUTATION,
} from "../members/_queries";

const SkillsStage = ({ formState, setFormState }: StageProps) => {
  const { t } = useTranslation();
  const { data: dataSkills } = useQuery(SKILLS_QUERY);
  const [updateUserProfile, resultUpdateUserProfile] = useMutation(
    UPDATE_USER_PROFILE_FULL_MUTATION,
    {
      variables: {
        email: formState.email,
        firstName: formState.firstName,
        lastName: formState.lastName,
        bio: "",
        project: "",
        role: "",
        linkedIn: "",
        location: "",
        languages: [] as string[],
        availability: [] as string[],
        time: 1,
        skills: formState.skills,
        starSkills: [] as string[],
        roles: formState.roles,
      },
    }
  );

  const [coreState, setCoreState] = useState({
    skills: [],
  });

  useEffect(() => {
    if (resultUpdateUserProfile.data) {
      setFormState({
        ...formState,
        errors: [],
        validation: [],
        step: Step.Complete,
      });
    }
  }, [resultUpdateUserProfile.data]);

  useEffect(() => {
    if (dataSkills) {
      setCoreState({
        ...coreState,
        skills: dataSkills.skills,
      });
    }
  }, [dataSkills]);

  return (
    <>
      <div className="max-w-md">
        <Title
          title={t("sign_up.email.second_stage.success") || ""}
          subtitle={t("sign_up.email.second_stage.continue") || ""}
        />
      </div>

      <div className="flex items-center my-[50px]">
        <div className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-full gradient-1 mr-[15px]"></div>
        <div className="flex flex-col">
          <span className="text-18 font-bold">
            {formState.firstName} {formState.lastName}
          </span>
          <span className="text-14 mt-0.5 text-dark-grey">
            {formState.email}
          </span>
        </div>
      </div>
      <div className="w-full mb-mobile">
        <h3 className="text-offers text-center">
          {t("sign_up.email.second_stage.goal")}
        </h3>
        <div className="my-desktop mx-auto max-w-sm w-full flex items-center justify-center gap-16">
          <div
            className={`flex flex-col shrink-0 items-center font-bold group cursor-pointer transition duration-300 hover:opacity-100 text-offers
      ${!formState.roles.length ? "opacity-100" : "opacity-40"}`}
            onClick={(e) => {
              e.preventDefault();
              setFormState({
                ...formState,
                roles: [],
                skills: [],
              });
            }}
          >
            <div className="group-hover:scale-110 transition duration-300">
              <AccessSupport />
            </div>
            <span className="mt-3">{t("sign_up.second_stage.access")}</span>
          </div>
          <div
            className={`flex flex-col shrink-0 items-center font-bold group cursor-pointer transition duration-300 hover:opacity-100 text-offers
      ${formState.roles.length ? "opacity-100" : "opacity-40"}`}
            onClick={(e) => {
              e.preventDefault();
              setFormState({
                ...formState,
                roles: ["mentor"],
              });
            }}
          >
            <div className="group-hover:scale-110 transition duration-300">
              <OfferSupport />
            </div>
            <span className="mt-3">{t("sign_up.second_stage.offer")}</span>
          </div>
        </div>
      </div>
      {formState.roles.length > 0 && (
        <div className="w-full mb-mobile">
          <h3 className="text-offers text-center">
            {t("sign_up.second_stage.what")}
          </h3>
          <Selector
            options={coreState.skills.map((s: SkillType) => s.title)}
            selected={formState.skills}
            type="icon"
            onSelect={(v: string[]) => {
              setFormState({
                ...formState,
                skills: v,
              });
            }}
          />
        </div>
      )}
      <Button
        title={t("profile.save_profile")}
        className="w-[calc(100%-40px)] max-w-sm"
        //disabled={validateSkills()}
        onClick={(e) => {
          e.preventDefault();
          updateUserProfile();
        }}
      />
      {/*<Link to={ROUTES.OFFERS} className="text-grey mt-5 transition duration-300 cursor-pointer hover:text-dark-grey">Skip for now</Link>*/}
    </>
  );
};

export default SkillsStage;
