import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UserType } from "../../types";

import { Case } from "../../assets/icons/global";

import Image from "./Image";

type ProfileImageProps = {
  user: UserType;
};

const Card = ({ user }: ProfileImageProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start">
      <Image user={user} />
      <div className="flex flex-col mt-[3px] ml-mobile md:ml-desktop">
        <Link
          className="text-18 font-bold"
          to={{ pathname: `/profile/${user.id}` }}
          state={{ prevPath: window.location.pathname }}
        >
          {user.profile?.preferredName}
        </Link>
        {((user.profile?.project && user.profile?.project !== " ") ||
          user.isCurrentUser) && (
          <div className="flex items-center">
            <Case />
            <div className={`text-14 mt-0.5 text-dark-grey`}>
              {user.isCurrentUser ? (
                user.profile?.project ? (
                  user.profile?.project
                ) : (
                  <span className="italic">{t("profile.role")}</span>
                )
              ) : (
                user.profile?.project
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Card;
