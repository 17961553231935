import { gql } from "@apollo/client";

export const CREATE_REQUEST = gql`
  mutation CreateRequest(
    $title: String!
    $description: String!
    $availability: [String]
    $category: String
  ) {
    createRequest(
      title: $title
      description: $description
      availability: $availability
      category: $category
    ) {
      status
      formErrors
      request {
        id
      }
    }
  }
`;