import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Logic
import { useLazyQuery, useMutation, useQuery } from "@apollo/client/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Types
import { OfferType, UserType, LocationStateType } from "../../types";

// Components
import CancelButton from "../core/CancelButton";
import Dropdown from "../core/Dropdown";
import Button from "../core/Button";
import { SkillList } from "../core/Skills";
import Card from "../core/Card";
import Exchange from "../core/Exchange";
import Time from "../core/Time";
import Overview from "../core/Overview";
import Thanks from "../core/Thanks";
import TranslateButton from "../core/TranslateButton";

// Assets
import { Pencil, Delete } from "../../assets/icons/global";

// Queries
import {
  USER_PROFILE_SHORT_QUERY,
  USER_PROFILE_QUERY,
} from "../members/_queries";
import { OFFER_QUERY, DELETE_OFFER_MUTATION } from "./_queries";

// Constants
import { IMAGE_PATH, ROUTES } from "../../constants";
import {
  CREATE_DIALOGUE_MUTATION,
  DIALOGUES_QUERY,
} from "../conversation/_queries";
import { getLanguage, getSHA256Hash, setError } from "../core/utils";
import { TRANSLATIONS_QUERY } from "../core/_queries";

// Localisation
import i18n from "../../i18n";

type HeaderProps = {
  user: UserType;
  offer: OfferType;
  prevPath: string;
};

const Header = ({ user, offer, prevPath }: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    deleting: false,
  });

  const [deleteOffer, result] = useMutation(DELETE_OFFER_MUTATION, {
    variables: { id: offer?.id },
    refetchQueries: [
      {
        query: USER_PROFILE_QUERY,
        variables: { id: user.id },
      },
    ],
  });

  useEffect(() => {
    if (result.data && result.data?.deleteOffer.status === 200) {
      setFormState({ ...formState, deleting: false });
      navigate(`/profile/${user.id}/`, {
        state: { prevPath: ROUTES.OFFERS },
      });
    }
  }, [result.data]);

  const action = user.isCurrentUser && (
    <>
      <Link
        to={`${ROUTES.PROFILE}/${user.id}/${offer.id}/edit`}
        state={{ prevPath: window.location.pathname }}
        className="pill bg-black text-white text-13 font-bold flex items-center mr-2.5 transition duration-300 hover:bg-light-grey hover:text-dark-grey group"
      >
        <Pencil />
        <span className="ml-1.5">{t("offer.edit")}</span>
      </Link>
      <div
        onClick={(e) => {
          e.preventDefault();
          if (formState.deleting !== true) {
            setFormState({
              ...formState,
              deleting: true,
            });
            deleteOffer({ variables: { id: offer.id } });
          }
        }}
        className="pill cursor-pointer bg-black text-white text-13 font-bold flex items-center mr-2.5 transition duration-300 hover:bg-light-grey hover:text-dark-grey group"
      >
        <Delete />
        <span className="ml-1.5">{t("offer.delete_label")}</span>
      </div>
    </>
  );
  return (
    <div className="h-[160px] md:h-[260px] w-full relative bg-cover bg-center gradient-1 p-mobile md:p-desktop">
      <div className="relative z-10 flex justify-between">
        <CancelButton pathname={prevPath || ROUTES.OFFERS} />
        <div className="flex">
          {action}
          {!user.isCurrentUser && (
            <Dropdown type="offer" invert={true} id={offer.id} user={user} />
          )}
        </div>
      </div>
      {offer.picture?.length !== 0 && (
        <div
          className="absolute z-0 top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${IMAGE_PATH}${offer.picture})` }}
        ></div>
      )}
    </div>
  );
};

type OfferProps = {
  user: UserType;
  offer: OfferType;
};

const Offer = ({ user, offer }: OfferProps) => {
  const { t } = useTranslation();

  const [translation, setTranslation] = useState(false);
  const [tOffer, { data: dataTOffer }] = useLazyQuery(TRANSLATIONS_QUERY);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  const handleClick = () => {
    Promise.all([
      getSHA256Hash(offer.title as string),
      getSHA256Hash(offer.description as string),
    ]).then((keys) => {
      tOffer({
        variables: {
          keys: keys,
          values: [offer.title as string, offer.description as string],
          language: getLanguage(i18n.language),
        },
      });
      setTranslation(!translation);
    });
  };

  return (
    <>
      <div className="md:pb-[120px]">
        <Header
          user={user}
          offer={offer}
          prevPath={(state as LocationStateType)?.prevPath}
        />
        <div className="p-mobile md:p-desktop">
          <div className="flex justify-between items-center">
            <Exchange time={offer.taken} type="offer" />
            <Time time={offer.price} />
          </div>

          <h2 className="font-bold mt-2.5 mb-[5px]">
            {translation ? dataTOffer?.translations[0]?.text : offer.title}
          </h2>

          <SkillList skills={offer.skills || []} />

          <div
            className="text-18 mt-mobile md:mt-desktop"
            dangerouslySetInnerHTML={{
              __html: translation
                ? dataTOffer?.translations[1]?.text
                : offer.description || "",
            }}
          />

          <div className="widgets mt-1.5">
            <TranslateButton active={translation} onClick={handleClick} />
          </div>
        </div>

        <Overview
          languages={offer.languages || []}
          availability={offer.availability || []}
          strings={true}
        />
        {user.profile?.notes?.length! > 0 && <Thanks user={user} />}
      </div>

      <div className="w-full relative p-mobile md:bg-white md:border-t md:border-border md:w-[700px] xl:w-1/2 md:p-desktop md:fixed md:bottom-0 md:right-0 md:rounded-bl-[30px] flex justify-between">
        <Card user={user} />
        {!user.isCurrentUser && (
          <Button
            className="fixed bottom-5 left-1/2 translate-x-[-50%] w-[calc(100%-40px)] md:max-w-xs md:relative md:bottom-0 md:left-0 md:translate-x-0"
            type="offers"
            title={t("profile.message", { name: user.firstName }) || ""}
            onClick={() => {
              createDialogue({ variables: { userId: user.id } });
            }}
          />
        )}
      </div>
    </>
  );
};

const OfferDetails = () => {
  const { userId, offerId } = useParams();
  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
  } = useQuery(USER_PROFILE_SHORT_QUERY, {
    variables: { id: userId },
  });
  const {
    loading: loadingOffer,
    error: errorOffer,
    data: dataOffer,
  } = useQuery(OFFER_QUERY, {
    variables: { id: offerId },
  });

  if (loadingUser || errorUser || loadingOffer || errorOffer) return null;

  return <Offer user={dataUser.user} offer={dataOffer.offer} />;
};

export default OfferDetails;
