import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import { useGoogleLogin } from "@react-oauth/google";

import { Title, CTA, saveUser } from "./_core";

import Button from "../core/Button";
import GoogleButton from "../core/GoogleButton";

import { COMMUNITY_QUERY, GOOGLE_AUTH_MUTATION } from "./_queries";
import { CommunityType } from "../../types";
import { IMAGE_PATH, GOOGLE_CALENDAR_SCOPE } from "../../constants";
import { Mode } from "./_types";

type ModeProps = {
  setMode: (mode: Mode) => void;
};

const SignUpStart = ({ setMode }: ModeProps) => {
  const { t } = useTranslation();
  const { community: communitySlug } = useParams();
  const [community, setCommunity] = useState<null | CommunityType>(null);

  const { loading, error, data } = useQuery(COMMUNITY_QUERY, {
    variables: { slug: communitySlug || "" },
  });

  const handleEmailClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMode(Mode.SIGNUP);
  };

  const [auth, resultAuth] = useMutation(GOOGLE_AUTH_MUTATION);

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    scope:
      GOOGLE_CALENDAR_SCOPE,
    onSuccess: async ({ code }) => {
      auth({ variables: { code: code } });
    },
  });

  useEffect(() => {
    if (resultAuth.data) {
      const token = resultAuth.data.googleAuth.token;
      if (token) {
        saveUser(
          { username: resultAuth.data.googleAuth.social.uid },
          resultAuth.data.googleAuth.token,
          resultAuth.data.googleAuth.refreshToken
        );
        setMode(Mode.SIGNUP_GOOGLE);
      }
    }
  }, [resultAuth.data]);

  useEffect(() => {
    if (data) {
      if (data.community?.name) {
        setCommunity(data.community);
      }
    }
  }, [data]);

  return (
    <>
      {community && (
        <div className="community-header">
          <img
            className="w-[70px] h-[70px] rounded-sm shrink-0 mb-3"
            src={`${IMAGE_PATH}/media/${community.picture}`}
            alt={community.name}
          />
          {/* <h1>{community.name}</h1> */}
        </div>
      )}
      <Title title={t("sign_up.title") || ""} />
      <Button
        type="outline"
        onClick={handleEmailClick}
        title={t("sign_up.label") || ""}
        className="w-full max-w-sm mb-3"
      />
      <GoogleButton
        type="outline"
        onClick={() => loginWithGoogle()}
        title={t("sign_up.label_google") || ""}
        className="w-full max-w-sm"
      />
      <CTA
        text={t("sign_up.have_account") || ""}
        path="login"
        link={t("sign_up.sign_in") || ""}
      />
      <div className="text-11 text-grey mt-5 text-center max-w-[280px]">
        {t("sign_up.agreement")}{" "}
        <a
          href="/pdfs/echo-privacy-policy.pdf"
          target="_blank"
          rel="noreferrer"
          className="underline hover:text-black transition"
        >
          {t("login.privacy_policy")}
        </a>
        ,{" "}
        <a
          href="/pdfs/echo-terms-and-conditions.pdf"
          target="_blank"
          rel="noreferrer"
          className="underline hover:text-black transition"
        >
          {t("login.tos")}
        </a>{" "}
        {t("login.and")}{" "}
        <a
          href="/pdfs/echo-community-values.pdf"
          target="_blank"
          rel="noreferrer"
          className="underline hover:text-black transition"
        >
          {t("login.community_values")}
        </a>
      </div>
    </>
  );
};

export default SignUpStart;
