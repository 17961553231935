export type MessageType = {
  text: string;
  trade?: TradeType;
  timestamp?: string;
  author?: UserType;
  own?: boolean;
  status?: string;
};

export type ImageType = {
  title: string;
  path: string;
  description?: string;
};

export type TradeType = {
  id?: string;
  supplier?: UserType;
  recipient?: UserType;
  units: number;
  offer?: OfferType;
  note?: NoteType;
  request?: RequestType;
  title: string;
  price: number;
};

export type OfferType = {
  id?: string;
  title: string;
  price: number;
  url?: string;
  short?: string;
  description?: string;
  picture?: string;
  images?: ImageType[];
  skills?: SkillType[];
  availability?: string[];
  taken?: string;
  profile?: ProfileType;
  languages?: LanguageType[];
};

export type TargetType = OfferType | TradeType;

export type NoteType = {
  id?: string;
  creator: UserType;
  text: string;
  timeCreated?: string;
};

export type RequestType = {
  title: string;
  url: string;
};

export type UserTypeEdge = {
  node: UserType;
};

export type UserType = {
  id?: string;
  profile?: ProfileType;
  name?: string;
  isCurrentUser?: boolean;
  firstName?: string;
  lastName?: string;
  isMentor?: boolean;
};

export type RangeType = {
  start?: string;
  end?: string;
};

export type CalendarType = {
  timezone?: string;
  busy?: RangeType[];
};

export type ProfileType = {
  [0]: any;
  preferredName?: string;
  project?: string;
  role?: string;
  location?: string;
  linkedIn?: string;
  bio?: string;
  picture?: string;
  balance?: number;
  calendar?: CalendarType;
  offers?: OfferType[];
  notes?: NoteType[];
  requests?: RequestType[];
  trades?: TradeType[];
  skills?: SkillEntryType[];
  languages?: LanguageType[];
  communities?: CommunityType[];
  time?: number;
  taken?: number;
};

export type DialogueType = {
  id?: string;
  users: UserType[];
  image?: string;
  lastMessage?: MessageType;
  messages?: MessageType[];
  title?: string;
  formattedTitle?: string;
  defaultPicture?: string;
  timestamp: string;
};

export type ActionTypeEdge = {
  node: ActionType;
};

export type ActionType = {
  id?: string;
  actor: UserType;
  target: TargetType;
  verb: string;
  timestamp?: string;
};

export type WalletType = {
  owner: UserType;
  project?: ProjectType;
  balance: number;
};

export type ProjectType = {
  owner: UserType;
  title: string;
  slug: string;
};

export type SkillEntryType = {
  id?: string;
  skill: SkillType;
  offering: boolean;
};

export type LanguageType = {
  id?: string;
  name: string;
};

export type SkillType = {
  id?: string;
  title: string;
  category: number;
};

export type PlaceType = {
  id?: string;
  name: string;
  picture?: string;
  slug: string;
  communities: CommunityType[];
};

export type CommunityType = {
  id?: string;
  name: string;
  picture?: string;
  slug: string;
};

export type ErrorType = {
  type: string;
  message: string;
};

export enum Segment {
  ACTIVITY,
  PEOPLE,
  MESSAGES,
  TOAST,
}

export enum VerbType {
  CREATED,
  DELETED,
  UPDATED,
  INFO,
}

export enum Type {
  MESSAGE,
  TRADE,
  ACTION,
}

export type NotificationType = {
  id: string;
  segment: Segment;
  type: Type;
  action?: VerbType;
  text?: string;
  own?: boolean;
  subselection?: string[];
  time?: string;
  author?: UserType;
};

export type LocationStateType = {
  prevPath: string;
};
