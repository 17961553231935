import React from "react";
import { Trans } from "react-i18next";

import { useQuery } from "@apollo/client";
import { ME_QUERY } from "../accounts/_queries";

type GreetingProps = {
  message?: string;
  type?: string;
  total?: number;
};

const Greeting = ({ message, type, total }: GreetingProps) => {
  const { loading, error, data } = useQuery(ME_QUERY);

  if (loading || error) return null;

  const user = data.me;
  const name = user.firstName;
  return (
    <h2>
      {type === "members" ? (
        <span>
          <Trans i18nKey="members.heading" count={total}>
            👋 Hi {{ name }}, there are <strong>{{ count: total }}</strong>{" "}
            members in the Echo community
          </Trans>
        </span>
      ) : type === "offers" ? (
        <span>
          <Trans i18nKey="offers.heading" count={total}>
            👋 Hi {{ name }}, there are <strong>{{ count: total }}</strong>{" "}
            active offers on Echo today
          </Trans>
        </span>
      ) : type === "messages" ? (
        <span>
          <Trans i18nKey="messages.heading" count={total}>
            You have <strong>{{ count: total }}</strong> conversations
          </Trans>
        </span>
      ) : (
        <span>{message}</span>
      )}
    </h2>
  );
};

export default Greeting;
