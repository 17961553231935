import React from "react";

import { UserType, NoteType } from "../../types";

import Image from "./Image";
import { Carousel } from "../external/Slider";

type PostProps = {
  note: NoteType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Post = ({ note }: PostProps) => (
  <div className="flex ml-mobile md:ml-desktop">
    <Image user={note.creator} small={true} />
    <div className="ml-[15px] flex flex-col">
      <span className="font-bold mb-[3px]">{note.creator.profile?.preferredName}</span>
      <span className="text-12 md:text-13 text-dark-grey">{note.text}</span>
    </div>
  </div>
);

type ThanksProps = {
  user: UserType;
};

const Thanks = ({ user }: ThanksProps) => (
  <div className="w-full bg-gradient-to-r from-members-100 to-white py-mobile md:py-desktop">
    <Carousel arrows={false} >
      {user.profile?.notes?.map(
        (note: NoteType, index: string | number | undefined) => (
          <Post note={note} key={index} onClick={() => null} />
        )
      )}
    </Carousel>
  </div>
);

export default Thanks;
