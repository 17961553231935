import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ME_QUERY } from "../accounts/_queries";

import { useMutation, useQuery } from "@apollo/client";
import { RESEND_ACTIVATION_EMAIL_MUTATION } from "../accounts/_queries";
import { ROUTES } from "../../constants";

import { Arrow } from "../../assets/icons/global";

export const ProfileProgress = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    show: false,
    showButton: true,
  });

  const { loading, error, data } = useQuery(ME_QUERY);

  const [resendActivationEmail, resultResendActivationEmail] = useMutation(
    RESEND_ACTIVATION_EMAIL_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.me) {
      setState({
        ...state,
        show: !data.me.verified || !data.me.profile.complete,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      resultResendActivationEmail.data &&
      resultResendActivationEmail.data.resendActivationEmail?.success
    ) {
      setState({ ...state, showButton: false });
    }
  }, [resultResendActivationEmail.data]);

  if (loading || error) return null;

  let reminderPrompt;

  if (!data.me.verified) {
    reminderPrompt = (
      <span className="font-bold">{t("verification_reminder.title")}</span>
    );
  } else if (data.me.profile.picture === "") {
    reminderPrompt = (
      <span className="font-bold">
        {t("profile_completion_reminder.upload_photo")}
      </span>
    );
  } else if (data.me.profile.skills.length === 0) {
    reminderPrompt = (
      <span className="font-bold">
        {t("profile_completion_reminder.add_skills")}
      </span>
    );
  } else if (data.me.profile.bio === "") {
    reminderPrompt = (
      <span className="font-bold">
        {t("profile_completion_reminder.write_bio")}
      </span>
    );
  }

  return (
    <div
      className={`${
        state.show ? "block" : "hidden"
      } bg-white border border-members shadow-progress rounded-[15px] overflow-hidden`}
    >
      <div className="p-mobile md:p-desktop text-members">{reminderPrompt}</div>
      <button
        className={`${
          state.showButton ? "flex" : "hidden"
        } bg-members-100 w-full items-center justify-between px-mobile md:px-desktop py-[15px] border-t border-members font-bold text-18 text-members transition duration-300 hover:bg-members hover:text-white group`}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (!data.me.verified) {
            const { target } = event;
            if (target instanceof HTMLElement) {
              if (!target.getAttribute("disabled")) {
                resendActivationEmail({
                  variables: { email: data.me.email },
                });
              }
              target.toggleAttribute("disabled", true);
            }
          } else if (!data.me.profile.complete) {
            navigate(`${ROUTES.PROFILE}/${data.me.pk}/edit`);
          }
        }}
      >
        <span>
          {!data.me.verified
            ? t("verification_reminder.label")
            : !data.me.profile.complete
            ? t("profile_completion_reminder.label")
            : null}
        </span>
        <Arrow button={true} />
      </button>
    </div>
  );
};
