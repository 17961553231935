import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import Compose from "./Compose";
import Conversation from "./Conversation";

import Header from "../core/Header";
import { Search } from "../core/Search";

import UserSelector from "./UserSelector";
import DialogueList from "./DialogueList";

import { DialogueType } from "../../types";
import { ROUTES } from "../../constants";
import { DIALOGUES_SEARCH_QUERY } from "./_queries";

import { Write } from "../../assets/icons/global";

interface MessagesProps {
  dialogues?: DialogueType[];
}

const SEARCH_COPY = "Search messages";
const SEARCH_MIN_LENGTH = 0;

const Messaging = ({ dialogues }: MessagesProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  
  useEffect(() => {
    document.body.classList.add("messages");
    return () => {
      document.body.classList.remove("messages");
    };
  }, []);

  const [state, setState] = useState({
    query: "",
    filter: "",
    dialogues: [],
  });

  const [executeSearch, { data }] = useLazyQuery(DIALOGUES_SEARCH_QUERY);

  useEffect(() => {
    if (data) {
      setState({ ...state, dialogues: data.dialoguesSearch || [] });
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (state.query.length > SEARCH_MIN_LENGTH) {
      executeSearch({
        variables: { query: state.query },
      });
    } else {
      setState({
        ...state,
        query: "",
      });
    }
  }, [state.query]); // eslint-disable-line

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  const updateFilter = (filter: string) => {
    setState({
      ...state,
      filter: filter,
    });
  };

  const _dialogues = state.query.length ? state.dialogues : dialogues;

  return (
    <>
      <Header />
      <main className="hidden lg:flex flex-col h-full">
        <Search
          placeholder={SEARCH_COPY}
          onChangeQuery={updateQuery}
          onChangeFilter={updateFilter}
          query={state.query}
        />
        <div className="flex lg:border-t lg:border-border grow">
          <div className="w-full md:w-1/2 shrink-0">
            <DialogueList dialogues={_dialogues} />
          </div>
          {id ?
            <Conversation id={id} />
          : location.pathname === "/messages/new" ?
            <UserSelector />
          :
            <Compose />
          }
        </div>
      </main>

      <main className="lg:hidden mt-0 pt-[90px] md:pt-desktop">
        <div className="h-[calc(100vh-90px)] md:h-[calc(100vh-30px)] overflow-hidden">
          {id ?
            <Conversation id={id} />
          : location.pathname === "/messages/new" ?
            <UserSelector />
          :
            <>
              <div className="flex border-b border-border pl-mobile md:pl-desktop">
                <div
                  onClick={() => navigate(`${ROUTES.MESSAGES}/new`)}
                  className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] shrink-0 bg-members-100 flex justify-center items-center rounded-full transition duration-300 hover:bg-members cursor-pointer group"
                >
                  <Write />
                </div>
                
                <Search
                  placeholder={SEARCH_COPY}
                  onChangeQuery={updateQuery}
                  onChangeFilter={updateFilter}
                  query={state.query}
                />
              </div>
              <DialogueList dialogues={_dialogues} />
            </>
          }
        </div>
      </main>
    </>
  );
};

{/*{id && (
  <>
    <Messages />
    {conversation}
  </>
)}
{!id && (
  <Messages />
)}
{conversation}*/}

export default Messaging;
