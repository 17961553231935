import { gql } from "@apollo/client";

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NotificationSubscription {
    notificationSubscription {
      id
      segment
      type
      action
      text
      subselection
      author {
        id
        profile {
          id
          preferredName
        }
      }
      own
    }
  }
`;

export const TRANSLATIONS_QUERY = gql`
  query Translations($keys: [String], $values: [String], $language: String) {
    translations(keys: $keys, values: $values, language: $language) {
      text
    }
  }
`;
