import React from "react";

export const Icon = ({title, type}:{title:string, type?:string}) => (
  title === "Admin" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.33427 26.1667H18.6676C23.3576 26.1667 24.1976 24.2883 24.4426 22.0017L25.3176 12.6683C25.6326 9.82167 24.8159 7.5 19.8343 7.5H8.1676C3.18594 7.5 2.36927 9.82167 2.68427 12.6683L3.55927 22.0017C3.80427 24.2883 4.64427 26.1667 9.33427 26.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.3335 7.50001V6.56668C9.3335 4.50168 9.3335 2.83334 13.0668 2.83334H14.9335C18.6668 2.83334 18.6668 4.50168 18.6668 6.56668V7.50001" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.3332 15.6667V16.8333C16.3332 16.845 16.3332 16.845 16.3332 16.8567C16.3332 18.1283 16.3215 19.1667 13.9998 19.1667C11.6898 19.1667 11.6665 18.14 11.6665 16.8683V15.6667C11.6665 14.5 11.6665 14.5 12.8332 14.5H15.1665C16.3332 14.5 16.3332 14.5 16.3332 15.6667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.2585 13.3333C22.5635 15.2933 19.4835 16.46 16.3335 16.8567" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.05566 13.6483C5.68066 15.445 8.644 16.53 11.6657 16.8683" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Business" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6668 20.9167H16.3335M2.3335 20.9167V9.24999C2.3335 4.58332 3.50016 3.41666 8.16683 3.41666M25.6668 20.9167V9.24999C25.6668 4.58332 24.5002 3.41666 19.8335 3.41666" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.6668 19.0617V22.9C11.6668 25.2333 10.7335 26.1667 8.40016 26.1667H5.60016C3.26683 26.1667 2.3335 25.2333 2.3335 22.9V19.0617C2.3335 16.7283 3.26683 15.795 5.60016 15.795H8.40016C10.7335 15.795 11.6668 16.7283 11.6668 19.0617ZM25.6668 19.0617V22.9C25.6668 25.2333 24.7335 26.1667 22.4002 26.1667H19.6002C17.2668 26.1667 16.3335 25.2333 16.3335 22.9V19.0617C16.3335 16.7283 17.2668 15.795 19.6002 15.795H22.4002C24.7335 15.795 25.6668 16.7283 25.6668 19.0617Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Coaching" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 22.5033H20.1133C19.18 22.5033 18.2933 22.865 17.64 23.5183L15.645 25.49C14.735 26.3883 13.2533 26.3883 12.3433 25.49L10.3483 23.5183C9.695 22.865 8.79667 22.5033 7.875 22.5033H7C5.06333 22.5033 3.5 20.9517 3.5 19.0383V6.31C3.5 4.39667 5.06333 2.845 7 2.845H21C22.9367 2.845 24.5 4.39667 24.5 6.31V19.0383C24.5 20.94 22.9367 22.5033 21 22.5033Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.6668 18.77C18.6668 16.67 16.5785 14.9667 14.0002 14.9667C11.4218 14.9667 9.3335 16.67 9.3335 18.77M14.0002 12.1667C14.3571 12.1667 14.7106 12.0964 15.0404 11.9598C15.3702 11.8231 15.6699 11.6229 15.9223 11.3705C16.1747 11.1181 16.375 10.8184 16.5116 10.4886C16.6482 10.1588 16.7185 9.80532 16.7185 9.44834C16.7185 9.09137 16.6482 8.73789 16.5116 8.40808C16.375 8.07828 16.1747 7.77861 15.9223 7.52619C15.6699 7.27377 15.3702 7.07354 15.0404 6.93693C14.7106 6.80032 14.3571 6.73001 14.0002 6.73001C13.2792 6.73001 12.5878 7.01641 12.078 7.52619C11.5682 8.03598 11.2818 8.7274 11.2818 9.44834C11.2818 10.1693 11.5682 10.8607 12.078 11.3705C12.5878 11.8803 13.2792 12.1667 14.0002 12.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Creative" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.0827 24.4166C5.05103 25.3849 6.61436 25.3849 7.5827 24.4166L22.7494 9.24993C23.7177 8.2816 23.7177 6.71826 22.7494 5.74993C21.781 4.7816 20.2177 4.7816 19.2494 5.74993L4.0827 20.9166C3.11436 21.8849 3.11436 23.4483 4.0827 24.4166Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.0112 10.9883L17.5112 7.48831" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.9165 3.34668L11.6665 2.83334L11.1532 4.58334L11.6665 6.33334L9.9165 5.82001L8.1665 6.33334L8.67984 4.58334L8.1665 2.83334L9.9165 3.34668Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.25 10.3467L7 9.83334L6.48667 11.5833L7 13.3333L5.25 12.82L3.5 13.3333L4.01333 11.5833L3.5 9.83334L5.25 10.3467Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.75 16.18L24.5 15.6667L23.9867 17.4167L24.5 19.1667L22.75 18.6533L21 19.1667L21.5133 17.4167L21 15.6667L22.75 16.18Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Digital" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4999 15.6667C9.72994 16.0517 9.08827 16.6233 8.60994 17.335C8.34161 17.7433 8.34161 18.2567 8.60994 18.665C9.08827 19.3767 9.72994 19.9483 10.4999 20.3333M17.7449 15.6667C18.5149 16.0517 19.1566 16.6233 19.6349 17.335C19.9033 17.7433 19.9033 18.2567 19.6349 18.665C19.1566 19.3767 18.5149 19.9483 17.7449 20.3333" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.60183 9.84501L25.0252 9.83334M10.5002 26.1667H17.5002C23.3335 26.1667 25.6668 23.8333 25.6668 18V11C25.6668 5.16668 23.3335 2.83334 17.5002 2.83334H10.5002C4.66683 2.83334 2.3335 5.16668 2.3335 11V18C2.3335 23.8333 4.66683 26.1667 10.5002 26.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Finance" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.4694 22.55V8.78331C22.4694 7.03331 21.7227 6.33331 19.8677 6.33331H18.6544C16.7994 6.33331 16.0527 7.03331 16.0527 8.78331V22.55" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.13672 22.55V14.6167C6.13672 12.8667 6.88338 12.1667 8.73839 12.1667H9.95172C11.8067 12.1667 12.5534 12.8667 12.5534 14.6167V22.55" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.33301 22.6667H25.6663" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Funding" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5178 9.73998V15.7483C22.5178 19.3417 20.4644 20.8816 17.3844 20.8816H7.12942C6.60442 20.8816 6.10276 20.835 5.63609 20.73C5.34442 20.6834 5.06443 20.6017 4.80776 20.5084C3.05776 19.855 1.99609 18.3383 1.99609 15.7483V9.73998C1.99609 6.14665 4.04942 4.60669 7.12942 4.60669H17.3844C19.9978 4.60669 21.8761 5.71502 22.3778 8.24668C22.4594 8.71335 22.5178 9.19165 22.5178 9.73998Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.0194 13.2401V19.2485C26.0194 22.8418 23.966 24.3818 20.886 24.3818H10.631C9.76769 24.3818 8.98604 24.2652 8.30937 24.0085C6.92104 23.4952 5.97603 22.4335 5.6377 20.7302C6.10436 20.8352 6.60603 20.8818 7.13103 20.8818H17.386C20.466 20.8818 22.5194 19.3418 22.5194 15.7485V9.74012C22.5194 9.19179 22.4727 8.70183 22.3794 8.24683C24.596 8.71349 26.0194 10.2768 26.0194 13.2401Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.249 15.8299C13.95 15.8299 15.329 14.4509 15.329 12.7499C15.329 11.0488 13.95 9.66986 12.249 9.66986C10.5479 9.66986 9.16895 11.0488 9.16895 12.7499C9.16895 14.4509 10.5479 15.8299 12.249 15.8299Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.57812 10.1834V15.3168" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9268 10.1837V15.317" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "HR" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 18C18.3492 18 21.875 14.6048 21.875 10.4167C21.875 6.22852 18.3492 2.83334 14 2.83334C9.65076 2.83334 6.125 6.22852 6.125 10.4167C6.125 14.6048 9.65076 18 14 18Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.77287 16.2733L8.76123 24.8833C8.76123 25.9333 9.49623 26.4466 10.4062 26.015L13.5329 24.5333C13.7896 24.405 14.2212 24.405 14.4779 24.5333L17.6162 26.015C18.5146 26.435 19.2612 25.9333 19.2612 24.8833V16.0633" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Languages" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_1728_30980)">
      <path d="M15.3313 26.1667C14.6316 26.1667 14.1482 25.4665 14.3963 24.8122L19.0686 12.4894C19.1423 12.2952 19.3284 12.1667 19.5362 12.1667H21.2959C21.5036 12.1667 21.6897 12.2952 21.7634 12.4894L26.4358 24.8122C26.6838 25.4665 26.2005 26.1667 25.5007 26.1667H25.2127C24.7859 26.1667 24.4061 25.8958 24.2672 25.4922L23.2744 22.6084H17.616L16.571 25.506C16.428 25.9025 16.0518 26.1667 15.6303 26.1667H15.3313ZM18.316 20.5667H22.516L20.4744 14.7917H20.3577L18.316 20.5667ZM5.37312 21.9596C4.9826 22.3501 4.34943 22.3501 3.95891 21.9596L3.73979 21.7405C3.34926 21.35 3.34927 20.7168 3.73979 20.3263L8.92435 15.1417C8.18546 14.325 7.53893 13.4792 6.98477 12.6042C6.6959 12.1481 6.42685 11.6788 6.1776 11.1963C5.84956 10.5612 6.33084 9.83337 7.04564 9.83337H7.37298C7.75175 9.83337 8.09561 10.0488 8.2774 10.3811C8.54321 10.8669 8.82206 11.3163 9.11393 11.7292C9.51254 12.2931 9.99379 12.8862 10.5577 13.5084C11.4132 12.575 12.123 11.6174 12.6868 10.6355C13.2507 9.65351 13.7271 8.60837 14.116 7.50004H2.16602C1.61373 7.50004 1.16602 7.05233 1.16602 6.50004V6.16671C1.16602 5.61442 1.61373 5.16671 2.16602 5.16671H9.33268V3.83337C9.33268 3.28109 9.7804 2.83337 10.3327 2.83337H10.666C11.2183 2.83337 11.666 3.28109 11.666 3.83337V5.16671H18.8327C19.385 5.16671 19.8327 5.61442 19.8327 6.16671V6.50004C19.8327 7.05233 19.385 7.50004 18.8327 7.50004H16.4494C16.041 8.8806 15.4868 10.2223 14.7868 11.525C14.0869 12.8278 13.2216 14.0528 12.191 15.2L14.5494 17.6076C14.8192 17.883 14.9067 18.2889 14.7742 18.651L14.6543 18.9786C14.4033 19.6649 13.5248 19.8589 13.0081 19.3421L10.4993 16.8334L5.37312 21.9596Z" fill="#6A4783"/>
      </g>
    </svg>  
  : title === "Legal" ?
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.895 13.4734C24.895 19.1784 20.7533 24.5217 15.095 26.0851C14.71 26.1901 14.29 26.1901 13.905 26.0851C8.24664 24.5217 4.10498 19.1784 4.10498 13.4734V8.35171C4.10498 7.39504 4.82833 6.31004 5.72666 5.94838L12.225 3.28841C13.6833 2.69341 15.3283 2.69341 16.7866 3.28841L23.285 5.94838C24.1717 6.31004 24.9067 7.39504 24.9067 8.35171L24.895 13.4734Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5008 15.0834C15.7895 15.0834 16.8341 14.0387 16.8341 12.7501C16.8341 11.4614 15.7895 10.4167 14.5008 10.4167C13.2121 10.4167 12.1675 11.4614 12.1675 12.7501C12.1675 14.0387 13.2121 15.0834 14.5008 15.0834Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.501 15.0834V18.5834" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Marketing" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 11.5833L14.35 16.4833L12.4833 13.6833L8.75 17.4167" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.9165 11.5833H19.2498V13.9167" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5002 26.1667H17.5002C23.3335 26.1667 25.6668 23.8333 25.6668 18V11C25.6668 5.16668 23.3335 2.83334 17.5002 2.83334H10.5002C4.66683 2.83334 2.3335 5.16668 2.3335 11V18C2.3335 23.8333 4.66683 26.1667 10.5002 26.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Mentoring" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5002 26.1667H17.5002C23.3335 26.1667 25.6668 23.8333 25.6668 18V11C25.6668 5.16668 23.3335 2.83334 17.5002 2.83334H10.5002C4.66683 2.83334 2.3335 5.16668 2.3335 11V18C2.3335 23.8333 4.66683 26.1667 10.5002 26.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.1665 10.125C9.33317 11.2917 11.2348 11.2917 12.4132 10.125M15.5865 10.125C16.7532 11.2917 18.6548 11.2917 19.8332 10.125M9.79984 15.6667H18.1998C18.7832 15.6667 19.2498 16.1333 19.2498 16.7167C19.2498 19.6217 16.9048 21.9667 13.9998 21.9667C11.0948 21.9667 8.74984 19.6217 8.74984 16.7167C8.74984 16.1333 9.2165 15.6667 9.79984 15.6667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : title === "Social Enterprise" ?
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6883 14.8033C20.2757 14.8033 21.798 14.1728 22.9204 13.0504C24.0428 11.928 24.6733 10.4057 24.6733 8.81834C24.6733 7.23102 24.0428 5.70871 22.9204 4.58631C21.798 3.4639 20.2757 2.83334 18.6883 2.83334C17.101 2.83334 15.5787 3.4639 14.4563 4.58631C13.3339 5.70871 12.7033 7.23102 12.7033 8.81834C12.7033 10.4057 13.3339 11.928 14.4563 13.0504C15.5787 14.1728 17.101 14.8033 18.6883 14.8033ZM7.91999 23.18C8.87301 23.18 9.78698 22.8014 10.4609 22.1275C11.1347 21.4537 11.5133 20.5397 11.5133 19.5867C11.5133 18.6337 11.1347 17.7197 10.4609 17.0458C9.78698 16.3719 8.87301 15.9933 7.91999 15.9933C6.96698 15.9933 6.053 16.3719 5.37912 17.0458C4.70524 17.7197 4.32666 18.6337 4.32666 19.5867C4.32666 20.5397 4.70524 21.4537 5.37912 22.1275C6.053 22.8014 6.96698 23.18 7.91999 23.18ZM19.89 26.1667C20.2822 26.1667 20.6706 26.0894 21.0329 25.9393C21.3953 25.7892 21.7246 25.5692 22.0019 25.2919C22.2792 25.0146 22.4992 24.6853 22.6493 24.323C22.7994 23.9606 22.8767 23.5722 22.8767 23.18C22.8767 22.7878 22.7994 22.3994 22.6493 22.0371C22.4992 21.6747 22.2792 21.3455 22.0019 21.0681C21.7246 20.7908 21.3953 20.5708 21.0329 20.4207C20.6706 20.2706 20.2822 20.1933 19.89 20.1933C19.0979 20.1933 18.3382 20.508 17.7781 21.0681C17.218 21.6282 16.9033 22.3879 16.9033 23.18C16.9033 23.9721 17.218 24.7318 17.7781 25.2919C18.3382 25.852 19.0979 26.1667 19.89 26.1667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  : title === "Wellbeing" ?
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.1702 9.93833C23.1702 10.4283 23.1352 10.9067 23.0768 11.3617C22.1813 10.9709 21.18 10.8942 20.2355 11.1441C19.2909 11.394 18.4586 11.9558 17.8735 12.7383C17.4684 12.1989 16.9434 11.7611 16.34 11.4594C15.7367 11.1577 15.0714 11.0005 14.3968 11C12.0052 11 10.0685 12.9483 10.0685 15.3633C10.0685 18.49 11.7252 20.8817 13.5685 22.5033C13.5102 22.5383 13.4518 22.55 13.3935 22.5733C13.0435 22.7017 12.4602 22.7017 12.1102 22.5733C9.0885 21.535 2.3335 17.2417 2.3335 9.93833C2.3335 6.71834 4.9235 4.11667 8.12016 4.11667C10.0218 4.11667 11.7018 5.02667 12.7518 6.43834C13.2902 5.71785 13.9891 5.13285 14.7931 4.72982C15.5972 4.32679 16.4841 4.11685 17.3835 4.11667C20.5802 4.11667 23.1702 6.71834 23.1702 9.93833Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.6667 15.3633C25.6667 20.8233 20.615 24.0433 18.3517 24.8133C18.0834 24.9067 17.6517 24.9067 17.3834 24.8133C16.415 24.4867 14.9334 23.705 13.5684 22.5033C11.725 20.8817 10.0684 18.49 10.0684 15.3633C10.0684 12.9483 12.005 11 14.3967 11C15.82 11 17.08 11.6883 17.8734 12.7383C18.4584 11.9558 19.2908 11.394 20.2354 11.1441C21.1799 10.8942 22.1812 10.9709 23.0767 11.3617C24.605 12.0383 25.6667 13.5667 25.6667 15.3633Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  : 
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6668 5.75001V21.5C11.6668 22.76 11.1535 23.915 10.3368 24.755L10.2902 24.8017C10.1852 24.9067 10.0685 25.0117 9.9635 25.0933C9.6135 25.3967 9.21683 25.63 8.8085 25.7933C8.68016 25.8517 8.55183 25.8983 8.4235 25.945C7.9685 26.0967 7.4785 26.1667 7.00016 26.1667C6.68516 26.1667 6.37016 26.1317 6.06683 26.0733C5.91516 26.0383 5.7635 26.0033 5.61183 25.9567C5.42516 25.8983 5.25016 25.84 5.07516 25.7583C5.07516 25.7467 5.07516 25.7467 5.0635 25.7583C4.73683 25.595 4.42183 25.4083 4.13016 25.1867L4.1185 25.175C3.96683 25.0583 3.82683 24.9417 3.6985 24.8017C3.57016 24.6617 3.44183 24.5217 3.3135 24.37C3.09183 24.0783 2.90516 23.7633 2.74183 23.4367C2.7535 23.425 2.7535 23.425 2.74183 23.425C2.74183 23.425 2.74183 23.4133 2.73016 23.4017C2.66016 23.2383 2.60183 23.0633 2.5435 22.8883C2.49915 22.7381 2.46023 22.5864 2.42683 22.4333C2.3685 22.13 2.3335 21.815 2.3335 21.5V5.75001C2.3335 4.00001 3.50016 2.83334 5.25016 2.83334H8.75016C10.5002 2.83334 11.6668 4.00001 11.6668 5.75001Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.6668 19.75V23.25C25.6668 25 24.5001 26.1667 22.7501 26.1667H7.00012C7.47846 26.1667 7.96846 26.0967 8.42346 25.945C8.55179 25.8983 8.68012 25.8517 8.80846 25.7933C9.21679 25.63 9.61346 25.3967 9.96346 25.0933C10.0685 25.0117 10.1851 24.9067 10.2901 24.8017L10.3368 24.755L18.2701 16.8333H22.7501C24.5001 16.8333 25.6668 18 25.6668 19.75ZM5.61179 25.9567C4.91179 25.7467 4.24679 25.3617 3.69846 24.8017C3.13846 24.2533 2.75346 23.5883 2.54346 22.8883C2.76994 23.6091 3.1672 24.2645 3.70143 24.7987C4.23565 25.3329 4.89103 25.7302 5.61179 25.9567Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.4318 13.6717L18.2702 16.8333L10.3368 24.755C11.1535 23.915 11.6668 22.76 11.6668 21.5V10.23L14.8285 7.06834C16.0652 5.83167 17.7218 5.83167 18.9585 7.06834L21.4318 9.54167C22.6685 10.7783 22.6685 12.435 21.4318 13.6717ZM7.00016 22.6667C7.30958 22.6667 7.60633 22.5438 7.82512 22.325C8.04391 22.1062 8.16683 21.8094 8.16683 21.5C8.16683 21.1906 8.04391 20.8938 7.82512 20.675C7.60633 20.4563 7.30958 20.3333 7.00016 20.3333C6.69074 20.3333 6.394 20.4563 6.17521 20.675C5.95641 20.8938 5.8335 21.1906 5.8335 21.5C5.8335 21.8094 5.95641 22.1062 6.17521 22.325C6.394 22.5438 6.69074 22.6667 7.00016 22.6667Z" className={`${type === "members" ? 'stroke-members' : 'stroke-offers'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)