import React from "react";
import { useTranslation } from "react-i18next";

import { Heart } from "../../assets/icons/global";

const Exchange = ({
  time,
  type,
}: {
  time?: number | string;
  type?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={`flex items-center ${type !== "offer" && "mt-3"}`}>
      <div className="w-4 h-4 bg-offers rounded-full flex items-center justify-center mr-[5px]">
        <Heart />
      </div>
      <span className="text-14 text-dark-grey mt-0.5">
        {time! > 0 || type === "offer"
          ? t("members.time_exchanged", { time: time })
          : t("members.new_member")}
      </span>
    </div>
  );
};
export default Exchange;
