import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LogIn from "./LogIn";

// Assets
import { Logo, Title, Gradient, CTA, saveUser } from "./_core";

import Button from "../core/Button";
import LanguageToggle from "./LanguageToggle";
import { useMutation } from "@apollo/client";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "../core/GoogleButton";
import { GOOGLE_AUTH_MUTATION } from "./_queries";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES, GOOGLE_CALENDAR_SCOPE } from "../../constants";
import { ErrorType } from "../../types";

enum modes {
  START,
  LOGIN,
}

const LoginView = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    mode: modes.START,
    errors: [] as ErrorType[],
  });
  const handleEmailClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setState({ ...state, mode: modes.LOGIN });
  };

  const [auth, resultAuth] = useMutation(GOOGLE_AUTH_MUTATION);
  const locationState = useLocation().state as { from: string };
  const navigate = useNavigate();

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    scope: GOOGLE_CALENDAR_SCOPE,
    onSuccess: async ({ code }) => {
      auth({ variables: { code: code } });
    },
  });

  useEffect(() => {
    if (resultAuth.data) {
      const token = resultAuth.data.googleAuth.token;
      if (token) {
        saveUser(
          { username: resultAuth.data.googleAuth.social.uid },
          resultAuth.data.googleAuth.token,
          resultAuth.data.googleAuth.refreshToken
        );
        navigate(locationState?.from ? locationState.from : ROUTES.OFFERS);
      } else {
      }
    }
  }, [resultAuth.data]);

  return (
    <>
      <Logo />
      <LanguageToggle />
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden md:w-[calc(100%-40px)] md:h-[calc(100%-100px)] md:top-20 md:left-5 md:rounded-[40px] md:bg-offers/10">
        <div className="absolute z-10 bg-transparent w-full h-full flex flex-col items-center justify-center md:right-0 md:w-1/2 md:bg-light-grey md:rounded-[40px]">
          {state.mode === modes.START ? (
            <>
              <Title title={t("login.title") || ""} />
              <Button
                onClick={handleEmailClick}
                title={t("login.email2")}
                type="outline"
                className="w-full max-w-sm mb-3"
              />
              <GoogleButton
                type="outline"
                onClick={() => loginWithGoogle()}
                title={t("login.label_google") || ""}
                className="w-full max-w-sm"
              />
              <CTA
                text={t("login.noemail") || ""}
                path="signup"
                link={t("login.signup") || ""}
              />
              <div className="text-11 text-grey mt-5 text-center max-w-[280px]">
                {t("login.agree")}{" "}
                <a
                  href="/pdfs/echo-privacy-policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:text-black transition"
                >
                  {t("login.privacy_policy")}
                </a>
                ,{" "}
                <a
                  href="/pdfs/echo-terms-and-conditions.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:text-black transition"
                >
                  {t("login.tos")}
                </a>{" "}
                {t("login.and")}{" "}
                <a
                  href="/pdfs/echo-community-values.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:text-black transition"
                >
                  {t("login.community_values")}
                </a>
              </div>
            </>
          ) : (
            <LogIn />
          )}
        </div>
        <Gradient />
      </div>
    </>
  );
};

export default LoginView;
