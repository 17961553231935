import React, { useState, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { DialogueType, MessageType } from "../../types";

import { setError } from "../core/utils";
import { CREATE_MESSAGE_MUTATION } from "./_queries";

import { Add, Send } from "../../assets/icons/global";

import TextareaAutosize from "react-textarea-autosize";

type MessageInputProps = {
  dialogue: DialogueType;
  message?: MessageType;
  messageButtonLabel?: string;
  onAddButtonClick: () => void;
};

export const MessageInput = ({
  dialogue,
  message = { text: "" },
  onAddButtonClick,
}: MessageInputProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({ message: message });
  const textArea = useRef(null);

  const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION, {
    onError: (error) => {
      setError(error.message);
    },
    update(cache, { data: { createMessage } }) {
      cache.modify({
        id: cache.identify({
          __typename: "DialogueType",
          id: dialogue.id,
        }),
        fields: {
          messages(existingMessages = []) {
            const newMessageRef = cache.writeFragment({
              data: createMessage.message,
              fragment: gql`
                fragment NewMessage on MessageType {
                  id
                  text
                  author
                  own
                  timestamp
                }
              `,
            });
            return [...existingMessages, newMessageRef];
          },
        },
      });
    },
  });

  const handleTextareaChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    ev.preventDefault();
    setStatus({
      message: {
        ...status.message,
        text: (textArea as any).current.value,
      },
    });
  };

  // TODO: Add UseEffect to disable input whilst round-trip underway?
  const handleClick = () => {
    if (status.message.text.trim() !== "") {
      createMessage({
        variables: {
          dialogueId: dialogue.id,
          text: status.message.text.trim(),
        },
      });
      setStatus({
        message: {
          ...status.message,
          text: "",
        },
      });
    }
  };

  return (
    <div className="mt-auto flex items-end w-full p-mobile md:p-desktop">
      <TextareaAutosize
        minRows={1}
        maxRows={3}
        placeholder={t("messages.new.message") || ""}
        className={`border border-border rounded-xl focus:outline-members py-[12.5px] px-mobile resize-none grow`}
        aria-label="With textarea"
        id="message-input-textarea"
        ref={textArea}
        onChange={handleTextareaChange}
        value={status.message.text}
      />
      <button
        className="w-[50px] h-[50px] bg-members flex justify-center items-center rounded-full transition duration-300 hover:bg-black cursor-pointer group ml-[15px]"
        onClick={handleClick}
      >
        <Send />
      </button>
      <div
        onClick={onAddButtonClick}
        className="w-[50px] h-[50px] bg-members-100 flex justify-center items-center rounded-full transition duration-300 hover:bg-members cursor-pointer group ml-[15px]"
      >
        <Add />
      </div>
    </div>
  );
};

export default MessageInput;
