import { gql } from "@apollo/client";

/*
    $query: String!
    $skills: [String]
    $availability: [String]
    $languages: [String]

    query: $query,
    skills: $skills,
    availability: $availability,
    languages: $languages,
*/

// NOTE: Convert node into a type
export const ACTIONS_QUERY = gql`
  query Actions(
    $first: Int
    $after: String
    $skills: [String]
    $availability: [String]
    $languages: [String]
    $communities: [String]
    $query: String
  ) {
    actions(
      first: $first
      after: $after
      skills: $skills
      availability: $availability
      languages: $languages
      communities: $communities
      query: $query
    ) {
      edges {
        node {
          id
          actor {
            __typename
            ... on UserType {
              id
              profile {
                id
                preferredName
                bio
                picture
                project
              }
            }
          }
          target {
            __typename
            ... on OfferType {
              id
              title
              short
              description
              price
              availability
              languages
              skills {
                id
                title
              }
            }
          }
          verb
          timestamp
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const OFFER_QUERY = gql`
  query Offer($id: String!) {
    offer(id: $id) {
      id
      title
      short
      description
      picture
      price
      availability
      skills {
        id
        title
      }
      languages
      taken
    }
  }
`;

export const OFFER_DRAFT_QUERY = gql`
  query OfferDraft {
    offerDraft {
      id
      title
      short
      description
      picture
      price
      availability
      skills {
        id
        title
      }
      languages {
        id
        name
      }
      taken
    }
  }
`;

export const UPDATE_OFFER_MUTATION = gql`
  mutation UpdateOffer(
    $id: String
    $title: String!
    $description: String!
    $availability: [String]
    $skills: [String]
    $languages: [String]
    $price: Float
  ) {
    updateOffer(
      id: $id
      title: $title
      description: $description
      availability: $availability
      skills: $skills
      languages: $languages
      price: $price
    ) {
      status
      formErrors
      offer {
        id
        title
        description
        picture
        skills {
          id
          title
        }
        languages
        availability
        price
      }
    }
  }
`;

export const DELETE_OFFER_MUTATION = gql`
  mutation DeleteOffer($id: String!) {
    deleteOffer(id: $id) {
      status
      formErrors
    }
  }
`;
