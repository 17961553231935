import React from "react";
import { Link, useLocation } from "react-router-dom";

// Assets
import { ArrowLeft } from "../../assets/icons/global";

type BackButtonProps = {
  onBackLinkClick?: () => void;
  style?: string;
};

const BackButton = ({ onBackLinkClick, style }: BackButtonProps) => {
  const location = useLocation();
  return (
  <div className={`${location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/password-reset" ? "bg-white" : "bg-light-grey"} ${style === "adaptive" ? 'top-1/2 translate-y-[-50%] hover:bg-members' : 'top-desktop hover:bg-offers'} absolute left-desktop w-desktop h-desktop rounded-full overflow-hidden cursor-pointer transition group z-50`}>
    {onBackLinkClick ? (
      <button onClick={() => onBackLinkClick()} className="w-full h-full flex items-center justify-center">
        <ArrowLeft />
      </button>
    ) : (
      <Link to="./" className="w-full h-full flex items-center justify-center">
        <ArrowLeft />
      </Link>
    )}
  </div>
  )
};

export default BackButton;
