import React from "react";
import { useTranslation } from "react-i18next";

import { CommunityType, PlaceType } from "../../types";
import Checkbox from "./Checkbox";

type CommunitiesProps = {
  places: PlaceType[];
  selectedPlaces: string[];
  communities: string[];
  placeChange: any;
  communityChange: any;
};

const Communities = ({
  places,
  selectedPlaces,
  communities,
  placeChange,
  communityChange,
}: CommunitiesProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-center px-mobile">
      <div className="places">
        <h3>{t("sign_up.community.select.place")}</h3>
        <div className="p-3.5 mb-4 w-full flex flex-col text-18 max-w-lg mx-auto text-black">
          {places.map((place: PlaceType, index: number) => (
            <Checkbox
              label={place.name}
              value={place?.slug || ""}
              key={index}
              optionChange={(val: string) => {
                placeChange(val);
              }}
              active={selectedPlaces.includes(place.slug)}
              bg={"border-offers before:bg-offers"}
            />
          ))}
        </div>
      </div>
      <div className="communities">
        <h3>{t("sign_up.community.select.community")}</h3>
        <div className="p-3.5 mb-4 w-full flex flex-col text-18 max-w-lg mx-auto text-black">
          {places
            .filter((place) => selectedPlaces.includes(place.slug))
            .map((place: PlaceType) =>
              place.communities.map((community: CommunityType, index) => (
                <Checkbox
                  label={community.name}
                  value={community.slug || ""}
                  key={index}
                  optionChange={(val: string) => {
                    communityChange(val);
                  }}
                  active={communities.includes(community.slug)}
                  bg={"border-offers before:bg-offers"}
                />
              ))
            )}
        </div>
      </div>
    </div>
  );
};

export default Communities;
