import * as React from "react";
import { useEffect } from "react";

import { Navigate, Routes, Route, useLocation } from "react-router-dom";

// Load initial React views
import SignUpView from "./components/accounts/SignUpView";
import LoginView from "./components/accounts/LoginView";
import Messages from "./components/conversation/Messages";
import Members from "./components/members/Members";
import ProfileView from "./components/members/Profile";
import OfferEdit from "./components/offers/OfferEdit";
import Offers from "./components/offers/Offers";
import Request from "./components/requests/Request";
import Drawer from "./components/core/Drawer";

// TODO: Update authentication code
import { isAuthenticated, RequireAuth } from "./components/accounts/_core";

import { LocationStateType } from "./types";

// Constants
import { ROUTES, USER_KEY } from "./constants";

import "./styles/globals.scss";
import PasswordReset from "./components/accounts/PasswordReset";
import Activate from "./components/accounts/Activate";
import SendPasswordResetEmail from "./components/accounts/SendPasswordResetEmail";

function App() {
  const { state, pathname } = useLocation();
  const prevPath = (state as LocationStateType)?.prevPath;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//code.tidio.co/d9atc5hsbswslkgmvtp4r2kla8baohvj.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);

    if (window.tidioChatApi && user) {
      const userData = JSON.parse(user);
      window.tidioChatApi.setVisitorData({
        email: userData["email"],
      });
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.SIGNUP}
          element={
            isAuthenticated() ? (
              <Navigate to={ROUTES.OFFERS} replace />
            ) : (
              <SignUpView />
            )
          }
        />
        <Route
          path={ROUTES.LOGIN}
          element={
            isAuthenticated() ? <Navigate to={ROUTES.OFFERS} /> : <LoginView />
          }
        />
        <Route path={ROUTES.PASSWORD_RESET}>
          <Route index element={<SendPasswordResetEmail />} />
          <Route index={false} path=":token" element={<PasswordReset />} />
        </Route>
        <Route path={ROUTES.ACTIVATE}>
          <Route index={false} path=":token" element={<Activate />} />
        </Route>
        <Route
          path={ROUTES.OFFERS}
          element={
            <RequireAuth>
              <Offers />
            </RequireAuth>
          }
        ></Route>
        {[
          `${ROUTES.MESSAGES}/*`,
          `${ROUTES.MESSAGES}/new`,
          `${ROUTES.MESSAGES}/:id`,
          `${ROUTES.MESSAGES}/:id/:status`,
        ].map((path, index) => (
          <Route
            key={index}
            path={path}
            element={
              <RequireAuth>
                <Messages />
              </RequireAuth>
            }
          ></Route>
        ))}
        <Route
          path={ROUTES.PEOPLE}
          element={
            <RequireAuth>
              <Members />
            </RequireAuth>
          }
        ></Route>
        <Route
          path={`${ROUTES.PROFILE}/*`}
          element={
            <RequireAuth>
              <>
                {prevPath === "/offers" ? (
                  <Offers />
                ) : prevPath === "/messages" ? (
                  <Messages />
                ) : (
                  <Members />
                )}
                <Drawer type={prevPath === "/members" ? "members" : "offers"}>
                  <ProfileView />
                </Drawer>
              </>
            </RequireAuth>
          }
        ></Route>
        <Route
          path={`${ROUTES.OFFER}/*`}
          element={
            <RequireAuth>
              <>
                {prevPath === "/offers" ? (
                  <Offers />
                ) : prevPath === "/messages" ? (
                  <Messages />
                ) : (
                  <Members />
                )}
                <Drawer type="offers">
                  <OfferEdit />
                </Drawer>
              </>
            </RequireAuth>
          }
        ></Route>
        <Route
          path={`${ROUTES.REQUEST}`}
          element={
            <RequireAuth>
              <>
                {prevPath === "/offers" ? (
                  <Offers />
                ) : prevPath === "/messages" ? (
                  <Messages />
                ) : (
                  <Members />
                )}
                <Drawer type="requests">
                  <Request />
                </Drawer>
              </>
            </RequireAuth>
          }
        ></Route>
        <Route
          path={`${ROUTES.COMMUNITY}`}
          element={
            isAuthenticated() ? (
              <Navigate to={ROUTES.OFFERS} replace />
            ) : (
              <SignUpView />
            )
          }
        />
        <Route
          path={`/`}
          element={
            isAuthenticated() ? (
              <Navigate to={ROUTES.OFFERS} replace />
            ) : (
              <Navigate to={ROUTES.SIGNUP} replace />
            )
          }
        />
      </Routes>
    </>
  );
}

export default App;
