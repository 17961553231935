export const enum modes {
  SEND_MESSAGE,
  CREATE_TRADE,
}

export type State = {
  mode: modes | null;
};

export const conversationReducer = (state: State, action: any) => {
  switch (action.type) {
    case modes.SEND_MESSAGE:
      return {
        mode: modes.SEND_MESSAGE,
      };
    case modes.CREATE_TRADE:
      return {
        mode: modes.CREATE_TRADE,
      };
    default:
      return state;
  }
};
