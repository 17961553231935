import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { UserType, RangeType } from "../../types";

import { ROUTES } from "../../constants";

import { Carousel } from "../external/Slider";

import { CREATE_BOOKING_MUTATION } from "./_queries";
import { useMutation } from "@apollo/client";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

// const TZ_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

type BookingButtonProps = {
  id: String;
  start: String;
  end: String;
};

const BookingButton = ({ id, start, end }: BookingButtonProps) => {
  const [state, setState] = useState({
    confirmed: false,
    complete: false,
  });
  const [createBooking, resultCreateBooking] = useMutation(
    CREATE_BOOKING_MUTATION
  );

  useEffect(() => {
    if (
      resultCreateBooking.data?.createBooking?.errors?.length === 0 &&
      resultCreateBooking.data?.createBooking?.success === true
    ) {
      setState({ ...state, complete: true });
    }
  }, [resultCreateBooking.data]);

  const handleClick = () => {
    if (state.confirmed) {
      // Trigger your function when confirmed
      createBooking({ variables: { id, start, end } });
    } else {
      // Flip to Confirm button on first click
      setState({ ...state, confirmed: true });
    }
  };

  return state.complete ? (
    <span className="border border-grey py-2 px-4 rounded">Booked</span>
  ) : (
    <button
      className={`border border-grey hover:border-members-200 hover:bg-members-200 hover:text-white text-grey py-2 px-4 rounded focus:outline-none ${
        state.confirmed ? "bg-members text-white" : ""
      }`}
      onClick={handleClick}
    >
      {state.confirmed ? "Confirm" : "Book"}
    </button>
  );
};

type SlotRange = {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
};

type DayProps = {
  day: number;
  id: String;
  timezone: string;
  slots: SlotRange[];
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Day = ({ day, id, timezone, slots, onClick }: DayProps) => {
  const currentDate = dayjs().tz(timezone).add(day, "day");

  return (
    <div className="overflow-hidden min-h-[120px]relative flex flex-col ml-mobile md:ml-desktop mb-mobile md:mb-desktop mt-2.5 md:mt-[15px]">
      <div className="date mb-3">{currentDate.format("LL")}</div>
      <div className="slots">
        {slots.map((slot, index) => (
          <div
            key={index}
            className="slot flex items-center justify-between mb-2 px-5 py-5 my-3px rounded-[5px] border border-border"
          >
            <div className="flex items-center">
              <label className="mr-4">{slot.start.format("h:mma")}</label>
            </div>
            <BookingButton
              id={id}
              start={slot.start.format()}
              end={slot.end.format()}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

type CalendarProps = {
  user: UserType;
};

function generateTimeSlots(
  day: number,
  timezone: string,
  excludedRanges: RangeType[]
): SlotRange[] {
  const officeStartHour = 9; // 9 AM
  const officeEndHour = 17; // 5 PM
  const slotDuration = 1; // 1 hour

  const startDate = dayjs().tz(timezone).add(day, "day");

  const currentDate = dayjs().tz(timezone);

  const currentStartHour = currentDate.hour() + 1;

  const officeHourStart = startDate
    .clone()
    .set("hour", officeStartHour)
    .set("minute", 0)
    .set("second", 0);

  const startHour = currentDate.isAfter(officeHourStart)
    ? currentStartHour
    : officeStartHour;

  const timeSlots: { start: dayjs.Dayjs; end: dayjs.Dayjs }[] = [];

  for (
    let currentHour = startHour;
    currentHour < officeEndHour;
    currentHour += slotDuration
  ) {
    const slotStart = officeHourStart
      .clone()
      .set("hour", currentHour)
      .set("minute", 0)
      .set("second", 0);
    const slotEnd = slotStart.clone().add(slotDuration, "hours");

    // Check if the time slot is within any excluded range
    const isWithinExcludedRange = excludedRanges.some((range) => {
      const rangeStart = dayjs.tz(range.start, timezone);
      const rangeEnd = dayjs.tz(range.end, timezone);

      return slotStart.isBetween(rangeStart, rangeEnd, null, "[]");
    });

    if (!isWithinExcludedRange) {
      timeSlots.push({
        start: slotStart,
        end: slotEnd,
      });
    }
  }

  return timeSlots;
}

const DEFAULT_RANGE = 7; // Days

const Calendar = ({ user }: CalendarProps) => {
  const { t } = useTranslation();
  return user.profile?.calendar?.timezone ? (
    <div className="flex flex-col border-t border-border pt-mobile md:pt-desktop">
      <div className="flex items-center justify-between px-mobile md:px-desktop">
        <div className="flex items-center">
          <span className="text-16 md:text-18 font-bold">
            {t("profile.calendar")}
          </span>
        </div>
        <div
          className={`flex items-center ${
            user.profile?.calendar ? "mr-[60px]" : "mr-0"
          }`}
        >
          {user.isCurrentUser && user.isMentor && <></>}
        </div>
      </div>

      {user.profile?.calendar ? (
        <Carousel arrows={true}>
          {user.profile?.calendar &&
            [...Array(DEFAULT_RANGE).keys()].map((day) => (
              <Day
                day={day}
                id={user.id || ""}
                timezone={user.profile?.calendar?.timezone || "Europe/London"}
                key={day}
                slots={generateTimeSlots(
                  day,
                  user.profile?.calendar?.timezone || "Europe/London",
                  user.profile?.calendar?.busy || []
                )}
                onClick={() => null}
              />
            ))}
        </Carousel>
      ) : (
        user.isCurrentUser && (
          <Link
            to={`${ROUTES.OFFER}/new`}
            className="rounded-xl border border-border border-dashed bg-light-grey text-dark-grey mt-[15px] mx-mobile md:mx-desktop mb-mobile md:mb-desktop p-mobile md:p-desktop"
          >
            <span className="text-16 md:text-18 font-bold mb-2.5 block">
              {t("profile.label_add_offer")}
            </span>
            <p className="italic">{t("profile.offers_placeholder")}</p>
          </Link>
        )
      )}
    </div>
  ) : (
    <></>
  );
};

export default Calendar;
