import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

import { createUploadLink } from "apollo-upload-client";

import { DEFAULT_PATH, TOKEN } from "../constants";

// NOTE: You should use an absolute URL when creating the following URIs
const options = {
  httpUri:
    process.env.GRAPHQL_HTTP_ENDPOINT ||
    window.location.protocol + DEFAULT_PATH,
  wsUri:
    process.env.GRAPHQL_WS_ENDPOINT ||
    (window.location.protocol === "https:" ? "wss:" : "ws:") + DEFAULT_PATH,
};

const httpLink = createUploadLink({
  uri: options.httpUri,
  credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: options.wsUri,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = localStorage.getItem(TOKEN);
      return {
        headers: {
          Authorization: token ? `JWT ${token}` : "",
        },
      };
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export default splitLink;
