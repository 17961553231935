import React from "react";

type ButtonProps = {
  className?: string;
  disabled?: boolean;
  title: string;
  type?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button = ({ className, disabled, title, type, onClick }: ButtonProps) => (
  <button
    className={`${
      type === "outline"
        ? "border border-border bg-white hover:border-offers hover:text-offers"
        : disabled
        ? "bg-light-grey text-border border-[1px] shadow-border"
        : type === "members"
        ? "bg-members-100 text-members border-[1px] shadow-members border-members hover:bg-members hover:text-white hover:shadow-none"
        : type === "requests"
        ? "bg-requests-100 text-requests border-[1px] shadow-requests border-requests hover:bg-requests hover:text-white hover:shadow-none"
        : type === "transparent"
        ? "bg-white text-offers border-[1px] shadow-offers border-offers hover:bg-offers hover:text-white hover:shadow-none"
        : "bg-offers-200 text-offers border-[1px] shadow-offers border-offers hover:bg-offers hover:text-white hover:shadow-none"
    } font-bold text-20 rounded-full py-[9px] md:py-3.5 transition duration-300 ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
  </button>
);

export default Button;
