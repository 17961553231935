import React, { ReactNode, useState, useCallback, useContext } from "react";

import { NotificationType } from "../../types";

const NotificationContext = React.createContext({
  notifications: [] as NotificationType[],
  addNotification: (notif: NotificationType) => {},
  removeNotification: (id: string) => {},
});

const useNotification = () => {
  const { notifications, addNotification, removeNotification } =
    useContext(NotificationContext);
  return { notifications, addNotification, removeNotification };
};

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState([] as NotificationType[]);

  const removeNotification = (id: string) =>
    setNotifications(notifications.filter((n) => n.id !== id));

  const addNotification = (notif: NotificationType) =>
    setNotifications([...notifications, notif]);

  const contextValue = {
    notifications,
    addNotification: useCallback(
      (notification) => addNotification(notification),
      []
    ),
    removeNotification: useCallback((id: string) => removeNotification(id), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default useNotification;
