import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UserType, OfferType } from "../../types";

import { ROUTES } from "../../constants";

import { Availability, Pencil } from "../../assets/icons/global";

import { Carousel } from "../external/Slider";
import Dropdown from "../core/Dropdown";
import Time from "../core/Time";

type PostProps = {
  user: UserType;
  offer: OfferType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Post = ({ user, offer }: PostProps) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden border border-border min-h-[120px] shadow-card rounded-[15px] p-mobile md:p-desktop relative flex flex-col ml-mobile md:ml-desktop mb-mobile md:mb-desktop mt-2.5 md:mt-[15px]">
      <Link
        className="text-18 md:text-24 font-bold text-offers mb-3 md:mt-4 md:mb-0 md:order-2 pr-10"
        to={{
          pathname: `${ROUTES.PROFILE}/${user.id}/${offer.id}`,
        }}
      >
        {offer.title}
      </Link>

      <div className="flex flex-wrap items-center mt-1 md:mt-0 md:order-1">
        {offer.availability &&
          offer.availability.map((availability, index) => (
            <div
              key={index}
              className="flex shrink-0 items-center bg-offers-100 text-offers rounded-full mr-1.5 py-1 px-1.5 mt-1.5"
            >
              <Availability type="button" />
              <span className="ml-1 text-12 mt-px leading-none">
                {t(availability)}
              </span>
            </div>
          ))}
        {offer.languages &&
          offer.languages.map((language, index) => (
            <span
              key={index}
              className="border border-offers-100 text-offers rounded-[3px] mr-1.5 pt-[3px] pb-0.5 px-1.5 text-12 leading-snug mt-1.5"
            >
              {t(language as unknown as string)}
            </span>
          ))}
      </div>

      <div className="absolute top-mobile md:top-desktop right-mobile md:right-desktop">
        <Time time={offer.price} />
      </div>

      <div className="absolute bottom-mobile md:bottom-desktop right-mobile md:right-desktop">
        <Dropdown type="offer" direction="up" id={offer.id} user={user} />
      </div>
    </div>
  );
};

type OffersProps = {
  user: UserType;
};

const Offers = ({ user }: OffersProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col border-t border-border pt-mobile md:pt-desktop">
      <div className="flex items-center justify-between px-mobile md:px-desktop">
        <div className="flex items-center">
          <span className="text-16 md:text-18 font-bold">
            {t("profile.current_offers")}
          </span>
        </div>
        <div
          className={`flex items-center ${
            user.profile?.offers?.length ? "mr-[60px]" : "mr-0"
          }`}
        >
          {user.isCurrentUser && (
            <Link
              to={`${ROUTES.OFFER}/new`}
              className="rounded-full py-0.5 px-3 bg-light-grey text-dark-grey text-13 font-bold flex items-center min-h-[30px] transition duration-300 hover:bg-black hover:text-white group"
            >
              <Pencil />
              <span className="ml-1.5">{t("profile.new_offer")}</span>
            </Link>
          )}
        </div>
      </div>

      {user.profile?.offers?.length ? (
        <Carousel arrows={true}>
          {user.profile?.offers
            ?.slice(0)
            .reverse()
            .map((offer: OfferType, index: string | number | undefined) => (
              <Post
                user={user}
                offer={offer}
                key={index}
                onClick={() => null}
              />
            ))}
        </Carousel>
      ) : (
        user.isCurrentUser && (
          <Link
            to={`${ROUTES.OFFER}/new`}
            className="rounded-xl border border-border border-dashed bg-light-grey text-dark-grey mt-[15px] mx-mobile md:mx-desktop mb-mobile md:mb-desktop p-mobile md:p-desktop"
          >
            <span className="text-16 md:text-18 font-bold mb-2.5 block">
              {t("profile.label_add_offer")}
            </span>
            <p className="italic">{t("profile.offers_placeholder")}</p>
          </Link>
        )
      )}
    </div>
  );
};

export default Offers;
