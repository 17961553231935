import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { DialogueType, NotificationType, Type, UserType } from "../../types";
import { PAUSE_DIALOGUE_MUTATION } from "./_queries";

import ProfileImage from "./ProfileImage";

import useNotification from "../core/Notification";
import { formatTime } from "../core/utils";
import { ROUTES } from "../../constants";
import { useMutation } from "@apollo/client";

interface DialogueProps {
  dialogue: DialogueType;
  selected?: boolean;
  notifications?: NotificationType[];
}

const Dialogue = ({
  dialogue,
  selected,
  notifications = [],
}: DialogueProps) => {
  const { status } = useParams();
  const { removeNotification } = useNotification();

  const [pauseDialogue] = useMutation(PAUSE_DIALOGUE_MUTATION);

  useEffect(() => {
    if (status) {
      pauseDialogue({ variables: { dialogueId: dialogue.id } });
    }
  }, []);

  const handleClick = () => {
    if (notifications.length) {
      removeNotification(notifications[0].id);
    }
  };

  const formatTradeMessage = (
    user: UserType | undefined,
    time: string | undefined
  ) => {
    if (user !== undefined && time !== undefined) {
      return `${user.profile?.preferredName} gave ${time} Echo${
        parseInt(time) > 1 ? "s" : ""
      }`;
    }
    return "";
  };

  const mostRecentMessage = () => {
    if (dialogue.messages?.length) {
      const lastMessage = dialogue.messages[dialogue.messages.length - 1];
      if (lastMessage.trade === null) {
        return lastMessage.text;
      }
      return formatTradeMessage(
        lastMessage.trade?.recipient,
        `${lastMessage.trade?.units}`
      );
    }
    return dialogue.lastMessage;
  };

  const lastMessage = notifications.length
    ? notifications[0].type === Type.MESSAGE
      ? notifications[0].text
      : formatTradeMessage(notifications[0].author, notifications[0].text)
    : mostRecentMessage();

  const timestamp = notifications.length
    ? new Date().toISOString()
    : dialogue.timestamp;

  return (
    <Link
      className={`p-mobile md:p-desktop border-b border-border flex items-center transition duration-300 ${
        notifications.length ? " bg-members-100" : ""
      }${selected ? " bg-light-grey" : ""}`}
      to={{ pathname: `${ROUTES.MESSAGES}/${dialogue.id}` }}
      onClick={handleClick}
    >
      <ProfileImage
        image={dialogue.defaultPicture}
        name={dialogue.formattedTitle}
      />
      <div className="flex items-center w-[calc(100%-80px)] ml-mobile md:ml-desktop">
        <div className="mr-5">
          <div
            className={`font-bold ${
              notifications.length ? "text-members" : "text-black"
            }`}
          >
            {dialogue.formattedTitle}
          </div>
          <div className="text-grey">{lastMessage}</div>
        </div>
        <div className="text-10 text-grey ml-auto">{formatTime(timestamp)}</div>
      </div>
    </Link>
  );
};

export default Dialogue;
