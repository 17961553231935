import React, { MouseEvent, useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Queries
import { TOKEN_AUTH_MUTATION } from "./_queries";

// Data
import { ErrorType } from "../../types";

// Components
import Button from "../core/Button";
import BackButton from "../core/BackButton";
import { Logo, Title, CTA } from "./_core";
import { Toast } from "../core/Toast";

// Utilities
import { setError } from "../core/utils";

import { isAuthenticated, saveUser } from "./_core";

import { ROUTES } from "../../constants";

type InputProps = {
  title: string;
  placeholder: string;
  autocomplete?: string;
  type?: string;
  onChange: (val: string) => void;
};

const Input = ({
  title,
  placeholder,
  autocomplete,
  type,
  onChange,
}: InputProps) => {
  const handleChange = (evt: { target: { value: string } }) => {
    onChange(evt.target.value);
  };
  return (
    <div className="mb-8 flex flex-col text-18 text-black max-w-lg mx-auto">
      <span className="font-bold mb-2.5">{title}</span>
      <input
        type={`${type === "password" ? "password" : "text"}`}
        className="border border-border p-3.5 rounded-sm outline-offers"
        placeholder={placeholder}
        onChange={handleChange}
        autoComplete={autocomplete}
      />
    </div>
  );
};

type EmailProps = {
  active: boolean;
  onChange: (email: string) => void;
};

const Email = ({ active, onChange }: EmailProps) => {
  const { t } = useTranslation();
  return (
    <div className={`w-full ${!active ? "" : " hidden"}`}>
      <Title title={t("login.email") || ""} />
      <Input
        onChange={onChange}
        title={t("login.email") || ""}
        placeholder=""
        autocomplete="email"
      />
    </div>
  );
};
type PasswordProps = {
  active: boolean;
  errors: ErrorType[];
  onChange: (password: string) => void;
};

const Password = ({ active, errors, onChange }: PasswordProps) => {
  const { t } = useTranslation();
  return (
    <div className={`w-full ${active ? "" : " hidden"}`}>
      <Title title={t("login.password") || ""} />
      {errors.length !== 0 && (
        <Toast show={true} className="error" message={errors[0].message} />
      )}
      <Input
        onChange={onChange}
        title="Password"
        placeholder=""
        autocomplete="current-password"
        type="password"
      />
    </div>
  );
};

const LogInForm = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    email: "",
    password: "",
    login: false,
    next: false,
    authenticated: false,
    errors: [] as ErrorType[],
  });

  const [tokenAuth, result] = useMutation(TOKEN_AUTH_MUTATION, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    },
  });

  const locationState = useLocation().state as { from: string };
  const navigate = useNavigate();

  // TODO: Ensure that this doesn't generate Maximum Depth Exceeded error.
  useEffect(() => {
    if (result.data) {
      if (result.data.tokenAuth.success) {
        saveUser(
          result.data.tokenAuth.user,
          result.data.tokenAuth.token,
          result.data.tokenAuth.refreshToken
        );
        setState({
          ...state,
          password: "",
          authenticated: true,
        });
      } else {
        setState({
          ...state,
          authenticated: false,
          errors: [
            {
              type: "error",
              message: t("login.error"),
            },
          ],
        });
      }
    }
  }, [result.data]); // eslint-disable-line

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    if (state.email) {
      setState({
        ...state,
        login: false,
        next: true,
      });
    }
    if (state.email && state.password) {
      tokenAuth({
        variables: { email: state.email, password: state.password },
      });
    }
  };

  const handleChangeEmail = (email: string) => {
    setState({
      ...state,
      email: email.toLowerCase(),
    });
  };

  const handleChangePassword = (password: string) => {
    setState({ ...state, password: password });
  };

  return !isAuthenticated() ? (
    <>
      {state.errors.length !== 0 && (
        <Toast
          show={true}
          className="error"
          message={state.errors[0].message}
        />
      )}
      <BackButton
        onBackLinkClick={() => {
          navigate("/");
        }}
      />
      <Logo />
      <form className="relative flex flex-col w-full h-full items-center justify-center px-mobile md:px-desktop z-10">
        <Email onChange={handleChangeEmail} active={state.next} />
        <Password
          onChange={handleChangePassword}
          errors={state.errors}
          active={state.next}
        />
        <Button
          className="mt-14 w-full max-w-sm"
          onClick={handleClick}
          title={state.next ? t("login.default") : t("login.next")}
        />
        <CTA path="password-reset" link={t("login.forgotten_password") || ""} />
      </form>
    </>
  ) : (
    <Navigate to={locationState?.from ? locationState.from : ROUTES.OFFERS} />
  );
};

const LogIn = () => {
  return <LogInForm />;
};

export default LogIn;
