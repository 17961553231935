import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { SkillType, LocationStateType } from "../../types";
import {
  availabilityOptions,
  languageOptions,
  ROUTES,
  IMAGE_PATH,
} from "../../constants";

import { ME_QUERY } from "../accounts/_queries";

import {
  OFFER_QUERY,
  UPDATE_OFFER_MUTATION,
  OFFER_DRAFT_QUERY,
} from "./_queries";

import {
  SKILLS_QUERY,
  UPDATE_OFFER_PICTURE_MUTATION,
  USER_PROFILE_QUERY,
} from "../members/_queries";

import { ACTIONS_QUERY } from "../offers/_queries";

import Button from "../core/Button";
import Info from "../core/Info";
import CancelButton from "../core/CancelButton";
import { SkillStrings } from "../core/Skills";
import Card from "../core/Card";
import Time from "../core/Time";
import Overview from "../core/Overview";
import Saving from "../core/Saving";

// Components
import Selector from "../core/Selector";
import BasicEditor from "../external/BasicEditor";

enum Step {
  Start,
  Category,
  Preview,
}

const OfferEdit = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const prevPath = (state as LocationStateType)?.prevPath;
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState(false);

  const { data } = useQuery(OFFER_QUERY, {
    variables: { id: offerId },
    skip: offerId ? false : true,
  });

  const { data: dataOfferDraft } = useQuery(OFFER_DRAFT_QUERY, {
    variables: {},
    skip: offerId ? true : false,
  });

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataMe,
  } = useQuery(ME_QUERY);

  const {
    loading: loadingSkills,
    error: errorSkills,
    data: dataSkills,
  } = useQuery(SKILLS_QUERY);

  const offer = data?.offer || dataOfferDraft?.offerDraft;
  const user = dataMe?.me;

  const [formState, setFormState] = useState({
    step: Step.Start,
    id: offer?.id,
    title: offer?.title || "",
    description: offer?.description || "",
    picture: offer?.picture || "",
    availability: offer?.availability || ([] as string[]),
    languages: offer?.languages || ([] as string[]),
    skills:
      offer?.skills?.map((v: { title: string }) => v.title) || ([] as string[]),
    price: offer?.price || 1,
    saving: false,
  });

  const [descriptionState, setDescriptionState] = useState(
    offer?.description || ""
  );

  useEffect(() => {
    if (descriptionState) {
      setFormState({
        ...formState,
        description: descriptionState,
      });
    }
  }, [descriptionState]);

  useEffect(() => {
    if (dataOfferDraft) {
      setFormState({
        ...formState,
        ...dataOfferDraft?.offerDraft,
      });
    }
  }, [dataOfferDraft]);

  const baseVariables = {
    id: formState.id,
    title: formState.title,
    description: formState.description,
    availability: formState.availability,
    skills: formState.skills,
    languages: formState.languages,
    price: formState.price,
  };

  const [updateOffer, result] = useMutation(UPDATE_OFFER_MUTATION, {
    variables: offer ? { ...baseVariables, id: offer?.id } : baseVariables,
    refetchQueries: [
      {
        query: USER_PROFILE_QUERY,
        variables: { id: user.id },
      },
      ACTIONS_QUERY,
    ],
  });

  const [updateOfferPicture, resultPicture] = useMutation(
    UPDATE_OFFER_PICTURE_MUTATION
  );

  const imgRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (result.data) {
      setFormState({ ...formState, saving: false });
      navigate(`/profile/${user.pk}/${result.data.updateOffer.offer.id}`, {
        state: { prevPath: ROUTES.OFFERS },
      });
    }
  }, [result.data]);

  useEffect(() => {
    if (resultPicture.data) {
      const id = resultPicture.data.updateOfferPicture.offer.id;
      const picture = resultPicture.data.updateOfferPicture.offer.picture;
      setFormState({
        ...formState,
        id: id,
        picture: picture,
      });
    }
  }, [resultPicture.data]);

  if (loadingUser || errorUser) return null;
  if (loadingSkills || errorSkills) return null;

  const validateStart = () => {
    if (
      formState.title !== "" &&
      formState.description !== "" &&
      formState.description !== "<p><br></p>"
    ) {
      return true;
    }
    return false;
  };

  const validateCategory = () => {
    if (
      formState.skills.length > 0 &&
      formState.languages.length > 0 &&
      formState.availability.length > 0
    ) {
      return true;
    }
    return false;
  };

  const Progress = () => (
    <div className="flex px-mobile pt-mobile md:px-desktop md:pt-desktop justify-center w-full">
      <span
        onClick={(e) => {
          e.preventDefault();
          setFormState({
            ...formState,
            step: Step.Start,
          });
        }}
        className={`w-6 h-1.5 rounded-full transition duration-300 mx-[5px] cursor-pointer ${
          formState.step === Step.Start ? "bg-offers" : "bg-offers-100"
        }`}
      ></span>
      <span
        className={`w-6 h-1.5 rounded-full transition duration-300 mx-[5px] cursor-pointer ${
          formState.step === Step.Category ? "bg-offers" : "bg-offers-100"
        }`}
      ></span>
    </div>
  );

  return (
    <>
      {formState.saving && <Saving text={t("offer.posting")} />}
      <div className="w-full h-full">
        <div className="relative z-10 flex justify-between p-mobile md:p-desktop border-b border-border">
          <CancelButton pathname={prevPath} />
          <h3>{offerId ? t("offer.edit2") : t("offer.add")}</h3>
          <div
            onClick={() => setInfo((info) => !info)}
            className={`${
              info ? "bg-offers" : "bg-offers-200"
            } hover:bg-offers transition duration-300 text-18 text-white rounded-full w-[30px] h-[30px] flex justify-center items-center cursor-pointer`}
          >
            i
          </div>
        </div>
        <div
          className={`${
            validateCategory() && "valid"
          } flex flex-col h-[calc(100%-187px)] md:h-[calc(100%-246px)] overflow-scroll`}
        >
          {info && <Info />}
          {formState.step === Step.Start && (
            <>
              <div className="flex flex-col grow p-mobile md:p-desktop">
                <input
                  type="text"
                  name="title"
                  className="text-22 font-bold mb-5 focus:outline-none"
                  value={formState.title}
                  placeholder={t("profile.add_offer.title") || ""}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      title: e.target.value,
                    });
                  }}
                />
                <BasicEditor
                  initialValue={descriptionState}
                  onEditorChange={(content: string) => {
                    setDescriptionState(content);
                  }}
                />
              </div>
              <div className="flex relative items-center p-mobile md:p-desktop border-y border-border">
                {formState.picture.length !== 0 && (
                  <img
                    className="w-20 h-20 absolute top-[45px] rounded-full"
                    src={`${IMAGE_PATH}${formState.picture}`}
                    onClick={() => imgRef.current?.click()}
                    alt=""
                  />
                )}
                <input
                  type="file"
                  className={`${
                    formState.picture.length !== 0
                      ? "bg-none"
                      : "file bg-offers-100 hover:bg-offers-200"
                  } bg-center bg-[length:40px] bg-no-repeat appearance-none w-20 h-20 shrink-0 rounded-full border-b border-[1px] border-offers mr-5 shadow-offers cursor-pointer transition duration-300 relative`}
                  name="uploadPicture"
                  accept="image/*"
                  ref={imgRef}
                  onChange={({ target: { validity, files } }) => {
                    if (validity.valid && files?.length) {
                      updateOfferPicture({
                        variables: { id: formState.id, file: files[0] },
                      });
                    }
                  }}
                />
                <label
                  htmlFor="uploadPicture"
                  onClick={() => imgRef.current?.click()}
                >
                  <h3>
                    {formState.picture
                      ? t("profile.add_offer.change_photo")
                      : t("profile.add_offer.add_photo")}
                  </h3>
                  <span className="block text-14 mt-1.5 md:text-16 text-dark-grey">
                    {t("profile.add_offer.photo_small")}
                  </span>
                  <span className="block mt-2.5 text-10 md:text-12 text-dark-grey">
                    {t("profile.add_offer.photo_help")}
                  </span>
                </label>
              </div>
              <div className="bg-white fixed bottom-0 w-full md:w-[700px] xl:w-1/2 border-t border-border">
                <Progress />
                <Button
                  title={t("profile.add_offer.next")}
                  disabled={!validateStart()}
                  className={`m-mobile md:m-desktop w-[calc(100%-40px)] md:w-[calc(100%-60px)]`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (validateStart()) {
                      setFormState({
                        ...formState,
                        step: Step.Category,
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
          {formState.step === Step.Category && (
            <>
              <div className="flex flex-col">
                <div className="pt-mobile md:pt-desktop border-b border-border">
                  <h3 className="w-full text-center">
                    {t("profile.add_offer.categories")}
                  </h3>
                  <Selector
                    options={dataSkills.skills.map((s: SkillType) => s.title)}
                    selected={formState.skills}
                    type="icon"
                    onSelect={(v: string[]) => {
                      setFormState({
                        ...formState,
                        skills: v,
                      });
                    }}
                  />
                </div>
                <div className="pt-mobile md:pt-desktop border-b border-border">
                  <label htmlFor="language">
                    <h3 className="w-full text-center">
                      {t("profile.add_offer.languages")}
                    </h3>
                  </label>
                  <Selector
                    options={languageOptions}
                    selected={formState.languages}
                    centered={true}
                    onSelect={(v: string[]) => {
                      setFormState({
                        ...formState,
                        languages: v,
                      });
                    }}
                  />
                </div>
                <div className="pt-mobile md:pt-desktop border-b border-border">
                  <label htmlFor="availability">
                    <h3 className="w-full text-center">
                      {t("profile.add_offer.where")}
                    </h3>
                  </label>
                  <Selector
                    options={availabilityOptions}
                    selected={formState.availability}
                    centered={true}
                    onSelect={(v: string[]) => {
                      setFormState({
                        ...formState,
                        availability: v,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col py-mobile md:py-desktop border-b border-border">
                  <label className="w-full bg text-center mb-5" htmlFor="price">
                    <h3 className="mb-[5px]">{t("profile.add_offer.time")}</h3>
                    <span className="text-grey">
                      {t("profile.add_offer.guide")}
                    </span>
                  </label>
                  <input
                    type="range"
                    className="w-3/4 mx-auto"
                    id="price"
                    name="price"
                    min="1"
                    max="5"
                    value={formState.price}
                    step="1"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        price: e.target.value,
                      })
                    }
                  />
                  <label
                    className="text-center text-grey mt-2.5 text-14"
                    htmlFor="price"
                  >
                    {formState.price} {t("profile.add_offer.hours")}
                  </label>
                </div>
              </div>
              <div className="bg-white fixed bottom-0 w-full md:w-[700px] xl:w-1/2 border-t border-border">
                <Progress />
                <Button
                  disabled={!validateCategory()}
                  className={`m-mobile md:m-desktop w-[calc(100%-40px)] md:w-[calc(100%-60px)]`}
                  title={t("profile.add_offer.preview")}
                  onClick={(e) => {
                    e.preventDefault();
                    if (validateCategory()) {
                      setFormState({
                        ...formState,
                        step: Step.Preview,
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
          {formState.step === Step.Preview && (
            <>
              <div className="min-h-[160px] md:min-h-[260px] w-full relative bg-cover bg-center gradient-1 p-mobile md:p-desktop overflow-auto">
                {formState.picture !== "" && (
                  <div
                    className="absolute z-0 top-0 left-0 w-full h-full bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${IMAGE_PATH}${formState.picture})`,
                    }}
                  ></div>
                )}
              </div>
              <div className="p-mobile md:p-desktop">
                <div className="flex justify-between items-center">
                  <span className="text-grey">
                    {t("profile.add_offer.preview")}
                  </span>
                  <Time time={formState.price} />
                </div>

                <h2 className="font-bold mt-2.5 mb-[5px]">{formState.title}</h2>

                <SkillStrings skills={formState.skills || []} />

                <div
                  className="text-18 mt-mobile md:mt-desktop"
                  dangerouslySetInnerHTML={{
                    __html: formState.description || "",
                  }}
                />
              </div>

              <Overview
                languages={formState.languages || []}
                availability={formState.availability || []}
                strings={true}
              />

              <div className="w-full relative p-mobile md:bg-white  md:border-border md:w-[700px] xl:w-1/2 md:p-desktop md:fixed md:bottom-0 md:right-0 md:rounded-bl-[30px] flex justify-between">
                <Card user={user} />
                <div className="fixed bottom-5 left-1/2 translate-x-[-50%] w-[calc(100%-40px)] md:relative md:bottom-0 md:left-0 md:translate-x-0 md:w-1/2 flex">
                  <Button
                    className="w-full"
                    type="transparent"
                    title="Edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setFormState({
                        ...formState,
                        step: Step.Start,
                      });
                    }}
                  />
                  <Button
                    className="w-full ml-2.5"
                    type="offers"
                    title={
                      formState.saving
                        ? t("profile.add_offer.saving")
                        : t("profile.add_offer.post")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (formState.saving !== true) {
                        setFormState({ ...formState, saving: true });
                        updateOffer();
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OfferEdit;
