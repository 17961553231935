import React, { Suspense } from "react";
import * as ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

// Load Apollo packages
import { ApolloProvider } from "@apollo/client";

// Create Apollo client
import ApolloClient from "./client/apolloClient";

// Load Google Oauth Provider
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./i18n";

import App from "./app";
import "./styles/globals.scss";
import { GOOGLE_CLIENT_ID } from "./constants";

// Notification Provider
import { NotificationProvider } from "./components/core/Notification";

// Analytics
import ReactGA from "react-ga4";

ReactGA.initialize("G-SEGP8L13JL");

const init = () => {
  const client = ApolloClient;
  ReactDOM.render(
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ApolloProvider client={client}>
        <NotificationProvider>
          <Router>
            <Suspense fallback="loading">
              <App />
            </Suspense>
          </Router>
        </NotificationProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>,
    document.getElementById("app")
  );
};

init();
