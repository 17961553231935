// const DEFAULT_HOST = window.location.host;
const DEFAULT_HOST = "echoapi.civic.co";
// const DEFAULT_HOST = "localhost:8000";

export const DEFAULT_PATH = `//${DEFAULT_HOST}/graphql/`;

export const IMAGE_PATH = "https://echo.civic.co";
export const DEFAULT_URL = "https://staging.economyofhours.co";

export const USER_KEY = "currentUser";

export const TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";

export const DefaultPaginationLimit = 100;

export enum Action {
  New,
  Edit,
  View,
}

export enum Step {
  Start,
  Category,
  Preview,
}

export enum Category {
  HelpAndAdvice = 1,
  Business = 2,
  DigitalAndCreative = 3,
  LearningWellbeingAndSkillshare = 4,
  Space = 5,
}

export enum FilterMode {
  Language,
  Availability,
  Community,
}

export const ROUTES = {
  OFFERS: "/offers",
  LOGIN: "/login",
  PASSWORD_RESET: "/password-reset",
  ACTIVATE: "/activate",
  MESSAGES: "/messages",
  OFFER: "/offer",
  PROFILE: "/profile",
  PEOPLE: "/members",
  SIGNUP: "/signup",
  REQUEST: "/request",
  COMMUNITY: "/:community",
};

export enum ActivityType {
  JOINED = "joined",
  CREATED = "created",
}

export const languageOptions = ["English", "Ukrainian"];

export const availabilityOptions = ["Online", "In-person"];

export const timeOptions = [
  { label: "1 hour", value: 1 },
  { label: "2 hours", value: 2 },
  { label: "3 hours", value: 3 },
  { label: "4 hours", value: 4 },
  { label: "5 hours", value: 5 },
];

export enum GQLAuthErrors {
  UNAUTHENTICATED = "Unauthenticated.",
  INVALID_TOKEN = "Invalid token.",
  EXPIRED_TOKEN = "Expired token.",
  NO_SUFFICIENT_PERMISSIONS = "Permissions found could not satisfy the required permissions.",
  NOT_VERIFIED = "Please verify your account.",
  MISSING_TOKEN = "No JWT found",
}

export const GOOGLE_CLIENT_ID =
  "296075727565-b9bei384hjk17s3idhesf3v6nav869l7.apps.googleusercontent.com";

export const GOOGLE_CALENDAR_SCOPE =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.freebusy";
