import React from "react";

import { UserType } from "../../types";

import { Skills } from "../core/Skills";

import Card from "../core/Card"
import Exchange from "../core/Exchange"
import Dropdown from "../core/Dropdown";

type PostProps = {
  user: UserType;
};

const Post = ({ user }: PostProps) => {
  return (
    <>
      <div className="w-full md:w-[calc(100%-60px)] border-b md:border border-border md:shadow-card md:rounded-xl p-mobile md:m-desktop md:p-desktop relative">
        <Card user={user} />
        <div className="flex flex-col pl-[70px] md:pl-[90px]">
          <Skills reduced={true} style="outline" skills={user.profile?.skills || []} />
          <Exchange time={user.profile?.taken} />
        </div>
        <div className="absolute top-[17px] md:top-[27px] right-mobile md:right-desktop z-20">
          <Dropdown type="member" id={user.id} user={user} />
        </div>
      </div>
    </>
  );
};

export default Post;
