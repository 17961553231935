import React from "react";

type SavingProps = {
  text: string;
};

const Saving = ({ text }: SavingProps) => {
  return (
    <div className="fixed w-full h-full text-center flex items-center justify-center z-50 left-0 top-0 bg-gradient-to-r from-requests-100 to-white">
			<div className="flex flex-col w-full items-center">
				<span className="text-22 md:text-30 mb-desktop">{text}</span>
				<div className="gradient-1 w-[200px] h-[200px] rounded-full animate-spin"></div>
			</div>
    </div>
  );
};

export default Saving;
