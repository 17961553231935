import React from "react";
import { useTranslation } from "react-i18next";

const Info = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full absolute z-50 flex flex-col bg-white p-mobile md:p-desktop h-[calc(100%-71px)] md:h-[calc(100%-91px)]">
      <h2 className="mb-5 mx-auto">{t("profile.add_offer.info.how")}</h2>
      <span className="rounded-xl bg-offers-100 p-5 mb-5">
        {t("profile.add_offer.info.note_1")}
      </span>
      <span className="rounded-xl bg-offers-100 p-5 mb-5">
        {t("profile.add_offer.info.note_2")}
      </span>
      <span className="rounded-xl bg-offers-100 p-5 mb-5">
        {t("profile.add_offer.info.note_3")}
      </span>
      <span className="rounded-xl bg-offers-100 p-5 mb-5">
        {t("profile.add_offer.info.note_4")}
      </span>
      <span className="rounded-xl bg-offers-100 p-5 mb-5">
        {t("profile.add_offer.info.note_5")}
      </span>
    </div>
  );
};

export default Info;
