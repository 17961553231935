import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTES } from "../../constants";

const CreateOffer = () => {
  const { t } = useTranslation();
  return (
    <Link
      className={`fixed w-[calc(100%-40px)] z-30 left-mobile md:left-desktop text-center bottom-5 bg-offers-200 text-offers border-[1px] shadow-offers border-offers hover:bg-offers hover:text-white hover:shadow-none font-bold text-20 rounded-full transition duration-300 py-[9px] md:py-3.5 sm:max-w-xs sm:left-1/2 sm:translate-x-[-50%] md:left-desktop md:bottom-desktop md:transform-none md:w-[300px]`}
      to={{
        pathname: `${ROUTES.OFFER}/new`,
      }}
      state={{ prevPath: window.location.pathname }}
    >
      {t("profile.label_add_offer")}
    </Link>
  );
};

export default CreateOffer;
