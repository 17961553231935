import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Constants
import { ROUTES } from "../../constants";

// Components
import BackButton from "../core/BackButton";
import Button from "../core/Button";
import { Toast } from "../core/Toast";

// Core logic
import { Title, Input } from "./_core";
import { FormStateType, StageProps, Step } from "./_types";
import { UPDATE_USER_ACCOUNT_MUTATION } from "./_queries";

const validateProfile = (formState: FormStateType) => {
  if (formState.firstName !== "" && formState.lastName !== "") {
    return false;
  }
  return true;
};

const ShortProfileStage = ({ formState, setFormState }: StageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateUserAccount, resultUpdateUserAccount] = useMutation(
    UPDATE_USER_ACCOUNT_MUTATION
  );

  useEffect(() => {
    if (resultUpdateUserAccount.data) {
      setFormState({
        ...formState,
        errors: [],
        validation: [],
        step: Step.Communities,
      });
    }
  }, [resultUpdateUserAccount.data]);

  return (
    <>
      <BackButton
        onBackLinkClick={() => {
          navigate(ROUTES.SIGNUP);
        }}
      />
      <Title title={t("sign_up.email.first_stage.title") || ""} />
      <div className="w-full flex flex-col items-center px-mobile">
        <Input
          title={t("sign_up.email.first_stage.first_name") || ""}
          value={formState.firstName}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
        />
        <Input
          title={t("sign_up.email.first_stage.last_name") || ""}
          value={formState.lastName}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
        />
        <Button
          className="mt-4 w-full max-w-sm mb-10"
          title={t("sign_up.email.first_stage.next") || ""}
          disabled={validateProfile(formState)}
          onClick={(e) => {
            e.preventDefault();
            updateUserAccount({
              variables: {
                firstName: formState.firstName,
                lastName: formState.lastName,
              },
            });
          }}
        />
      </div>
      {formState.errors.length !== 0 && (
        <Toast
          show={true}
          className="error"
          message={formState.errors[0].message}
        />
      )}
    </>
  );
};

export default ShortProfileStage;
