import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Types
import { DialogueType, UserType } from "../../types";

// Components
import Button from "../core/Button";
import CancelButton from "../core/CancelButton";

// Utils
import { setError } from "../core/utils";

// Queries
import { SEND_ECHOS_MUTATION } from "./_queries";
import { ME_QUERY } from "../accounts/_queries";

// Third party packages
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import TextareaAutosize from "react-textarea-autosize";

type HeaderProps = {
  title: string;
  prevPath: string;
  onCancel: () => void;
};

const Header = ({ title, prevPath, onCancel }: HeaderProps) => {
  return (
    <div className="flex justify-between p-mobile md:p-desktop border-b border-border">
      <CancelButton pathname={prevPath} onClick={onCancel} />
      <div className="font-bold text-18">{title}</div>
      <span className="w-[30px] h-[30px]"></span>
    </div>
  );
};

type TradeInputProps = {
  dialogue: DialogueType;
  user?: UserType;
  prevPath: string;
  onCancel: () => void;
  onComplete: () => void;
};

const DEFAULT_OFFER_ID = "0";
const DEFAULT_TIME = "1";

export const TradeInput = ({
  dialogue,
  user,
  onCancel,
  onComplete,
  prevPath,
}: TradeInputProps) => {
  const { t } = useTranslation();
  const [sendEchos] = useMutation(SEND_ECHOS_MUTATION, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    },
    refetchQueries: [{ query: ME_QUERY }],
    update(cache, { data: { sendEchos } }) {
      cache.modify({
        id: cache.identify({
          __typename: "DialogueType",
          id: dialogue.id,
        }),
        fields: {
          messages(existingMessages = []) {
            const newMessageRef = cache.writeFragment({
              data: sendEchos.message,
              fragment: gql`
                fragment NewTradeMessage on MessageType {
                  id
                  text
                  author
                  trade
                  own
                  timestamp
                }
              `,
            });
            return [...existingMessages, newMessageRef];
          },
        },
      });
    },
  });

  const [state, setState] = useState({
    time: DEFAULT_TIME,
    offerId: DEFAULT_OFFER_ID,
    post: "",
    error: false,
  });

  const handleSendEchosButtonClick = () => {
    const cBal = user?.profile?.balance;
    if (
      cBal !== undefined &&
      parseInt(state.time) >= 1 &&
      parseInt(state.time) <= cBal
    ) {
      sendEchos({
        variables: {
          dialogueId: dialogue.id,
          time: parseInt(state.time),
          post: state.post,
          offerId: state.offerId,
        },
      });
      onComplete();
    } else {
      setState({ ...state, error: true });
    }
  };

  function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
  }

  const dataOptions = dialogue.users
    .map((u) =>
      u.profile?.offers
        ?.map((o) => ({ value: o.id || DEFAULT_OFFER_ID, label: o.title }))
        .filter(notUndefined)
    )
    .filter(notUndefined);

  const options = [{ value: DEFAULT_OFFER_ID, label: "Gift" }].concat(
    ...dataOptions
  );

  return (
    <>
      <Header title={t("messages.give.give_echos") || ""} prevPath={prevPath} onCancel={onCancel} />

      <div className="p-mobile md:p-desktop">
        <div className={`${state.error ? " error" : ""}`}>
          <div className="flex mb-2.5 items-center justify-between w-full">
            <label className="block font-bold" htmlFor="time">
              {t("messages.give.time")}
            </label>
            <small className="text-grey text-10 font-bold">
              {t("messages.give.small")}
            </small>
          </div>
          <input
            className="border border-border p-3.5 rounded-sm outline-members w-full mb-5"
            name="time"
            placeholder="1"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={state.time}
            onChange={(e) => {
              setState({ ...state, time: e.target.value });
            }}
          />
        </div>
        <label className="block font-bold mb-2.5" htmlFor="offer">
          {t("messages.give.what_for") || ""}
        </label>
        <Dropdown
          options={options}
          onChange={(option) => setState({ ...state, offerId: option.value })}
          value={state.offerId}
          placeholder={t("messages.give.review") || ""}
          className="mb-5"
        />
        <label className="block font-bold mb-2.5" htmlFor="thank-you">
          {t("messages.give.review")}
        </label>
        <small className="block text-grey leading-normal mb-5">
          {t("messages.give.review_note")}
        </small>
        <TextareaAutosize
          minRows={3}
          maxRows={5}
          placeholder={t("messages.give.write_words") || ""}
          onChange={(ev) => setState({ ...state, post: ev.target.value })}
          className="border border-border p-3.5 rounded-sm outline-members w-full mb-5 resize-none"
        />
        <Button
          type="members"
          className="w-full"
          title={t("messages.give.label") || ""}
          onClick={handleSendEchosButtonClick}
        />
      </div>
    </>
  );
};
