import * as React from "react";
import { Link } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";

import { REFRESH_TOKEN, ROUTES, TOKEN, USER_KEY } from "../../constants";
import { ReactComponent as Echo } from "../../assets/svg/brand/echo.svg";
import { ReactComponent as EchoText } from "../../assets/svg/brand/logo.svg";

export const saveUser = (data: Object, token: string, refreshToken: string) => {
  localStorage.setItem(USER_KEY, JSON.stringify(data));
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getUser = () => {
  const user = localStorage.getItem(USER_KEY);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const deleteUser = () => {
  localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => {
  return getUser() ? true : false;
};

type Props = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: Props) => {
  const location = useLocation();
  const auth = isAuthenticated();
  if (!auth) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }
  return children;
};

/*
export const RequireAuth: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const auth = isAuthenticated();
  if (!auth) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }
  return children;
};
*/

export const Logo = () => (
  <>
    <Echo className="w-auto h-10 fixed top-5 right-5 block md:hidden" />
    <EchoText className="w-auto h-10 fixed top-5 left-5 hidden md:block" />
  </>
);

type TitleProps = {
  title?: string;
  subtitle?: string;
};

export const Title = ({ title, subtitle }: TitleProps) => (
  <div className="flex flex-col px-mobile md:px-desktop">
    <h1 className={`${subtitle ? "mb-[15px]" : "mb-14"} text-center`}>
      {title}
    </h1>
    <span className="text-grey text-center text-22">{subtitle}</span>
  </div>
);

type CTAProps = {
  text?: string;
  link?: string;
  path?: string;
};

export const CTA = ({ text, link, path }: CTAProps) => (
  <div className="text-18 text-grey mt-6 text-center">
    {text}{" "}
    <Link
      className="font-bold transition duration-300 hover:text-offers"
      to={{ pathname: `/${path}` }}
    >
      {link}
    </Link>
  </div>
);

export const TermsAndConditions = () => (
  <div className="text-14 text-black">
    By creating this account, you are confirming that you agree to our
    <a
      href="/terms-and-conditions"
      target="_blank"
      rel="noreferrer"
      className="font-bold"
    >
      terms and conditions
    </a>
    and
    <a
      href="/privacy-policy"
      target="_blank"
      rel="noreferrer"
      className="font-bold"
    >
      privacy policy
    </a>
    .
  </div>
);

export const Gradient = () => (
  <div className="absolute w-full aspect-square top-full translate-y-[-50%] translate-x-[-50%] md:top-1/2 md:left-1/2 md:w-1/3">
    <div className="rounded-full z-0 w-full h-full welcome-gradient animate-spin-slow"></div>
  </div>
);

type ValidationErrorTypes = {
  code: string;
  message: string;
};

type ValidationTypes = {
  type: string;
  errors: ValidationErrorTypes[];
};

type InputProps = {
  title?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  type?: string;
  autoComplete?: string;
  error?: boolean;
  hidden?: boolean;
  required?: boolean;
  validation?: ValidationTypes[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = ({
  title,
  placeholder,
  name,
  type,
  value,
  autoComplete,
  hidden,
  error,
  validation,
  required,
  onChange,
}: InputProps) => {
  return (
    <div
      className={`mb-4 w-full flex flex-col text-18 max-w-lg mx-auto text-black${
        hidden ? " hidden" : ""
      }`}
    >
      {title && (
        <span className={`font-bold mb-2.5 ${error && "text-red"}`}>
          {title}{" "}
          {required && (
            <span className={`${error ? "text-red" : "text-offers"}`}>*</span>
          )}
        </span>
      )}
      <input
        className={`border border-border p-3.5 rounded-sm outline-offers ${
          error && "border-red"
        }`}
        type={`${type === "password" ? "password" : "text"}`}
        name={name}
        autoComplete={autoComplete}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={(e) => {
          if (required && value === "") {
            e.target.classList.add("error");
          } else {
            e.target.classList.remove("error");
          }
        }}
      />
      <div className="mt-1">
        {validation
          ?.filter(function (element) {
            return element !== undefined;
          })
          .map(
            (el) =>
              el.type === type &&
              el.errors.map((detail, index) => (
                <span className="text-12 text-red font-bold block" key={index}>
                  {detail.message}
                </span>
              ))
          )}
      </div>
    </div>
  );
};
