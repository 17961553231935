import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client/react";
import { useTranslation } from "react-i18next";

import { CommunityType, UserType, UserTypeEdge } from "../../types";

import { Search } from "../core/Search";
import ProfileImage from "./ProfileImage";

import { DefaultPaginationLimit, ROUTES } from "../../constants";
import { COMMUNITIES_QUERY } from "../accounts/_queries";
import { USER_SEARCH_QUERY } from "../members/_queries";
import { setError } from "../core/utils";
import { CREATE_DIALOGUE_MUTATION, DIALOGUES_QUERY } from "./_queries";

type UserItemProps = {
  user: UserType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const UserItem = ({ user, onClick }: UserItemProps) => {
  return (
    <button
      onClick={onClick}
      className="w-full flex items-center p-[15px] transition duration-300 hover:bg-light-grey"
    >
      <ProfileImage
        image={user.profile?.picture}
        name={user.profile?.preferredName}
      />
      <div className="font-bold text-14 ml-[15px]">
        {user.profile?.preferredName}
      </div>
    </button>
  );
};

type UserListProps = {
  users?: UserType[];
};

const UserList = ({ users = [] }: UserListProps) => {
  const navigate = useNavigate();

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  return (
    <div className="flex flex-col px-mobile md:px-desktop mb-mobile md:mb-desktop">
      <div className="border border-border rounded-xl mt-2.5 h-[calc(100vh-340px)] md:h-[calc(100vh-215px)] lg:h-[calc(100vh-325px)] overflow-auto">
        {users.map((user, index) => {
          return (
            <UserItem
              user={user}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                createDialogue({
                  variables: { userId: user.id },
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const UserSelector = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    query: "",
    filter: "",
    nodes: [],
    pageInfo: "",
    totalCount: 0,
    options: [],
  });

  const [executeSearch, { error, data }] = useLazyQuery(USER_SEARCH_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.userSearch) {
      setState({
        ...state,
        nodes:
          data.userSearch.edges.map((edge: UserTypeEdge) => edge.node) || [],
        pageInfo: data.userSearch.pageInfo,
        totalCount: data.userSearch.totalCount,
      });
    }
  }, [data]); // eslint-disable-line

  const { error: errorCommunities, data: dataCommunities } =
    useQuery(COMMUNITIES_QUERY);

  const runQuery = () => {
    executeSearch({
      variables: {
        query: state.query,
        first: DefaultPaginationLimit,
        after: "",
      },
    });
  };

  // Initial set up.
  useEffect(() => {
    runQuery();
  }, [state.query]); // eslint-disable-line

  useEffect(() => {
    if (dataCommunities && dataCommunities.communities) {
      setState({
        ...state,
        options: dataCommunities.communities.map((e: CommunityType) => [
          e.name,
          e.slug,
        ]),
      });
    }
  }, [dataCommunities]); // eslint-disable-line

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  // NOTE: Using guards for error states
  if (error || errorCommunities) return null;

  return (
    <div className={`flex flex-col w-1/2 border-l border-border w-full`}>
      <span className="text-dark-grey text-18 pt-mobile md:pt-desktop px-mobile md:px-desktop mb-[15px]">
        {t("messages.new.to")}
      </span>
      <Search
        mode="Search"
        placeholder={t("messages.new.search") || ""}
        onChangeQuery={updateQuery}
        query={state.query}
      />
      {state.nodes.length > 0 ? <UserList users={state.nodes} /> : ""}
    </div>
  );
};

export default UserSelector;
