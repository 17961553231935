import React from "react";

import { ReactComponent as Echo } from "../../assets/svg/brand/echo.svg";

const Exchange = ({ time }: {time?: number}) => (
	<div className="flex items-center ml-auto">
		<Echo className="w-[20px] h-[20px] mr-2.5" />
		<span className="mt-px font-bold text-dark-grey">{time}</span>
	</div>
)
export default Exchange