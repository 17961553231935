import React, { useCallback, useEffect } from "react";

import { useQuery } from "@apollo/client";

import { ME_QUERY } from "../accounts/_queries";

import Navigation from "./Navigation";
import Me from "./Me";
import { ProfileProgress } from "./ProfileProgress";
import useNotification from "./Notification";

import { Type } from "../../types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const Header = ({ balance }: { balance?: string }) => {
  const { client, loading, error, data, refetch } = useQuery(ME_QUERY);
  const { notifications } = useNotification();
  const navigate = useNavigate();

  const logOut = useCallback(() => {
    client.resetStore();
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  }, [client, navigate]);

  useEffect(() => {
    if (!loading && data?.me == null) {
      logOut();
    }
  }, [loading, data, logOut]);

  useEffect(() => {
    if (notifications.filter((n) => n.type === Type.TRADE).length) {
      // When a notification arrives, trigger a refetch of the user balance.
      refetch();
    }
  }, [notifications, refetch]);

  if (loading || error) return null;

  return (
    <header className="fixed left-0 top-0 z-50 md:z-0 flex w-full p-[15px] md:w-[360px] md:h-full md:p-desktop md:flex-col md:bg-white md:border-r md:border-border">
      <Navigation />
      <Me user={data.me} />
      <div className="hidden md:block fixed bottom-[200px] left-[30px] w-[300px] z-10">
        <ProfileProgress />
      </div>
    </header>
  );
};

export default Header;
