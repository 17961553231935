import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Toggle = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav
      className={`w-calc(100%-40px) mb-mobile mx-mobile md:hidden flex items-center font-bold leading-none overflow-hidden`}
    >
      <Link
        to={`/offers`}
        className={`w-full grow-1 text-center py-[21.5px] rounded-l-full border-l border-y ${
          location.pathname === "/offers"
            ? "bg-offers-100 border-offers/50 border-r text-offers"
            : "bg-white border-border text-dark-grey"
        }`}
      >
        {t("navigation.offers")}
      </Link>
      <Link
        to={`/members`}
        className={`w-full grow-1 text-center py-[21.5px] rounded-r-full border-r border-y ${
          location.pathname === "/members"
            ? "bg-members-100 border-members/50 border-l text-members"
            : "bg-white border-border text-dark-grey"
        }`}
      >
        {t("navigation.members")}
      </Link>
    </nav>
  );
};
export default Toggle;
