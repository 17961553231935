import React from "react";
import { Link, useLocation } from "react-router-dom";

import { IMAGE_PATH } from "../../constants";

import Balance from "./Balance";

const Me = ({ user }: {user: any}) => {
  const location = useLocation();
    
  return (
    <div className="relative bg-light-grey rounded-full pr-[50px] ml-auto md:bg-white md:w-full md:mb-10 md:pr-0">
      <Balance balance={parseInt(user.profile?.balance)} />
      <Link
        to={`/profile/${user.pk}`}
        state={{ prevPath: location.pathname }}
        className="absolute top-0 right-0 md:right-auto md:left-0 md:flex md:items-center"
      >
        {user.profile?.picture ? (
          <img src={`${IMAGE_PATH}${user.profile?.picture}`} alt={user.name} className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-full gradient-1" />
        ) : (
          <span className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-full font-bold text-20 text-white flex items-center justify-center gradient-1 pt-px">{user.firstName?.charAt(0)}{user.lastName?.charAt(0)}</span>
        )}
        <span className="hidden md:block ml-[15px] text-16 font-bold text-black">{user.firstName}</span>
      </Link>
    </div>
  );
};

export default Me;
