import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Third-party packages
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type ImageInputProps = {
  imgURL: string;
  saving: boolean;
  editing: boolean;
  uploadImage: (e: any) => void;
  startEditing: () => void;
  stopEditing: () => void;
};

const ImageInput = ({
  imgURL,
  saving,
  editing,
  uploadImage,
  startEditing,
  stopEditing,
}: ImageInputProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    imgURL: string;
    newImgURL: string;
    file: File | null;
    filename: string;
  }>({
    imgURL: imgURL,
    newImgURL: "",
    file: null,
    filename: "",
  });
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect: 1,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const makeClientCrop = async (crop: ReactCrop.Crop) => {
    if (imgRef.current !== null && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        crop,
        state.filename
      );
      if (croppedImage !== undefined) {
        setState({ ...state, file: croppedImage });
      }
    }
  };

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropComplete = (crop: ReactCrop.Crop) => {
    makeClientCrop(crop);
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: ReactCrop.Crop,
    fileName: string
  ) => {
    if (
      crop.height === undefined ||
      crop.width === undefined ||
      crop.x === undefined ||
      crop.y === undefined
    ) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d")!;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<File>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          // console.error("Canvas is empty");
          return;
        }
        resolve(new File([blob], fileName));
      }, "image/jpeg");
    });
  };

  return (
    <div
      className={`w-full p-mobile md:p-desktop border-b border-border bg-light-grey ${
        editing ? " editing" : ""
      }`}
    >
      {state.newImgURL !== "" ? (
        <div
          className={`flex flex-col items-center ${saving ? " saving" : ""}`}
        >
          <div className="max-w-sm">
            <h3 className="mb-5 text-center">{t("profile.image.crop")}</h3>
            <ReactCrop
              src={state.newImgURL || state.imgURL}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={(newCrop) => {
                setCrop(newCrop);
              }}
            />
          </div>
          <div className="mt-5">
            <div className="flex items-center">
              <button
                className="pill bg-light-grey text-dark-grey text-13 font-bold flex items-center mr-2.5 transition duration-300 hover:bg-black hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  setState({
                    ...state,
                    newImgURL: "",
                    file: null,
                    filename: "",
                  });
                  stopEditing();
                }}
              >
                {t("profile.image.cancel")}
              </button>
              <button
                className="pill bg-black text-white text-13 font-bold flex items-center mr-2.5 transition duration-300 hover:bg-light-grey hover:text-dark-grey"
                onClick={(e) => {
                  e.preventDefault();
                  if (state.file) {
                    uploadImage({ variables: { file: state.file } });
                  }
                }}
              >
                {t("profile.image.save")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div
            style={{ background: imgURL ? `url(${imgURL})` : `transparent` }}
            className="w-20 h-20 relative rounded-full bg-no-repeat bg-center overflow-hidden shrink-0"
            onClick={(e) => {
              startEditing();
              //fileInputRef.current?.click();
            }}
          >
            <input
              className="gradient-2 w-full h-full absolute top-0 left-0 p-desktop cursor-pointer"
              type="file"
              name="uploadPicture"
              accept="image/*"
              ref={fileInputRef}
              onChange={({ target: { validity, files } }) => {
                if (validity.valid && files && files.length > 0) {
                  const reader = new FileReader();
                  reader.addEventListener("load", () => {
                    if (reader.result !== null)
                      setState({
                        ...state,
                        newImgURL: reader.result as string,
                        file: files[0],
                        filename: files[0].name,
                      });
                  });
                  reader.readAsDataURL(files[0]);
                } else {
                  // TODO: Review cancellation logic.
                  stopEditing();
                }
              }}
            />
          </div>
          <div className="flex flex-col ml-5">
            <label
              className="block mb-0.5 font-bold"
              htmlFor="uploadPicture"
              onClick={(e) => {
                startEditing();
                fileInputRef.current?.click();
              }}
            >
              {imgURL ? t("profile.change_image") : t("profile.upload_image")}
            </label>
            <span className="text-grey text-16">{t("profile.image.help")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
