import React from "react";
import { useTranslation } from "react-i18next";

type TranslateButtonProps = {
  onClick?: () => void;
  active?: boolean;
};

const TranslateButton = ({ onClick, active = false }: TranslateButtonProps) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="border border-offers-100 flex items-center text-offers rounded-[3px] mr-1.5 pt-[3px] pb-0.5 px-1.5 text-12 leading-snug mt-1.5"
    >
      <svg
        className={`-mt-px`}
        width={11}
        height={11}
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g mask="url(#mask0_1728_30980)">
          <path
            className={`fill-offers`}
            d="M15.3313 26.1667C14.6316 26.1667 14.1482 25.4665 14.3963 24.8122L19.0686 12.4894C19.1423 12.2952 19.3284 12.1667 19.5362 12.1667H21.2959C21.5036 12.1667 21.6897 12.2952 21.7634 12.4894L26.4358 24.8122C26.6838 25.4665 26.2005 26.1667 25.5007 26.1667H25.2127C24.7859 26.1667 24.4061 25.8958 24.2672 25.4922L23.2744 22.6084H17.616L16.571 25.506C16.428 25.9025 16.0518 26.1667 15.6303 26.1667H15.3313ZM18.316 20.5667H22.516L20.4744 14.7917H20.3577L18.316 20.5667ZM5.37312 21.9596C4.9826 22.3501 4.34943 22.3501 3.95891 21.9596L3.73979 21.7405C3.34926 21.35 3.34927 20.7168 3.73979 20.3263L8.92435 15.1417C8.18546 14.325 7.53893 13.4792 6.98477 12.6042C6.6959 12.1481 6.42685 11.6788 6.1776 11.1963C5.84956 10.5612 6.33084 9.83337 7.04564 9.83337H7.37298C7.75175 9.83337 8.09561 10.0488 8.2774 10.3811C8.54321 10.8669 8.82206 11.3163 9.11393 11.7292C9.51254 12.2931 9.99379 12.8862 10.5577 13.5084C11.4132 12.575 12.123 11.6174 12.6868 10.6355C13.2507 9.65351 13.7271 8.60837 14.116 7.50004H2.16602C1.61373 7.50004 1.16602 7.05233 1.16602 6.50004V6.16671C1.16602 5.61442 1.61373 5.16671 2.16602 5.16671H9.33268V3.83337C9.33268 3.28109 9.7804 2.83337 10.3327 2.83337H10.666C11.2183 2.83337 11.666 3.28109 11.666 3.83337V5.16671H18.8327C19.385 5.16671 19.8327 5.61442 19.8327 6.16671V6.50004C19.8327 7.05233 19.385 7.50004 18.8327 7.50004H16.4494C16.041 8.8806 15.4868 10.2223 14.7868 11.525C14.0869 12.8278 13.2216 14.0528 12.191 15.2L14.5494 17.6076C14.8192 17.883 14.9067 18.2889 14.7742 18.651L14.6543 18.9786C14.4033 19.6649 13.5248 19.8589 13.0081 19.3421L10.4993 16.8334L5.37312 21.9596Z"
          />
        </g>
      </svg>
      <span className="ml-1">{active ? t("Revert") : t("Translate")}</span>
    </button>
  );
};

export default TranslateButton;
