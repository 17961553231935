import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import BackButton from "../core/BackButton";
import Button from "../core/Button";
import { Toast } from "../core/Toast";
import Communities from "./Communities";

import { Title } from "./_core";
import { StageProps, Step } from "./_types";

import { PLACES_QUERY, UPDATE_USER_COMMUNITIES_MUTATION } from "./_queries";

const validateCommunities = (communities: string[]) => {
  if (communities.length > 0) {
    return false;
  }
  return true;
};

const CommunitiesStage = ({ formState, setFormState }: StageProps) => {
  const { t } = useTranslation();

  const { data: dataPlaces } = useQuery(PLACES_QUERY);

  const [updateCommunities, resultUpdateCommunities] = useMutation(
    UPDATE_USER_COMMUNITIES_MUTATION
  );

  useEffect(() => {
    if (resultUpdateCommunities.data) {
      setFormState({
        ...formState,
        errors: [],
        validation: [],
        step: Step.Skills,
      });
    }
  }, [resultUpdateCommunities.data]);

  return (
    <>
      <BackButton
        onBackLinkClick={() => {
          setFormState({
            ...formState,
            errors: [],
            validation: [],
            step: Step.Profile,
          });
        }}
      />
      <Title title={t("sign_up.email.communities_stage.title") || ""} />
      <div className="w-full flex flex-col items-center px-mobile">
        {dataPlaces && (
          <Communities
            places={dataPlaces.places}
            selectedPlaces={formState.selectedPlaces}
            communities={formState.communities}
            placeChange={(slug: string) => {
              setFormState({
                ...formState,
                selectedPlaces: !formState.selectedPlaces.includes(slug)
                  ? [...formState.selectedPlaces, slug]
                  : formState.selectedPlaces.filter(
                      (placeSlug) => placeSlug === slug
                    ),
              });
            }}
            communityChange={(slug: string) => {
              setFormState({
                ...formState,
                communities: !formState.communities.includes(slug)
                  ? [...formState.communities, slug]
                  : formState.communities.filter(
                      (commSlug) => commSlug === slug
                    ),
              });
            }}
          />
        )}
        <Button
          className="mt-4 w-full max-w-sm mb-10"
          title={t("login.next") || ""}
          disabled={validateCommunities(formState.communities)}
          onClick={(e) => {
            e.preventDefault();
            updateCommunities({
              variables: {
                communities: formState.communities,
              },
            });
          }}
        />
      </div>
      {formState.errors.length !== 0 && (
        <Toast
          show={true}
          className="error"
          message={formState.errors[0].message}
        />
      )}
    </>
  );
};

export default CommunitiesStage;
