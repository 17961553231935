import React, { useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client/react";

// Types
import { LocationStateType, UserType } from "../../types";

// Components
import CancelButton from "../core/CancelButton";
import Dropdown from "../core/Dropdown";
import Button from "../core/Button";
import ProfileEdit from "./ProfileEdit";
import Saved from "./Saved";
import Offers from "../offers/OffersProfile";
import { Skills } from "../core/Skills";
import { ProfileProgress } from "../core/ProfileProgress";
import Card from "../core/Card";
import Exchange from "../core/Exchange";
import Overview from "../core/Overview";
import Thanks from "../core/Thanks";
import OfferDetails from "../offers/Offer";
import OfferEdit from "../offers/OfferEdit";
import Calendar from "./Calendar";
import TranslateButton from "../core/TranslateButton";

// Assets
import { Pencil, Save } from "../../assets/icons/global";
import { ReactComponent as Echo } from "../../assets/svg/brand/echo.svg";

// Queries
import { USER_PROFILE_QUERY } from "./_queries";
import { ME_QUERY } from "../accounts/_queries";
import { TRANSLATIONS_QUERY } from "../core/_queries";
import {
  CREATE_DIALOGUE_MUTATION,
  DIALOGUES_QUERY,
} from "../conversation/_queries";

// Constants
import { ROUTES } from "../../constants";

// Utilities
import { setError, getSHA256Hash, getLanguage } from "../core/utils";

// Localisation
import i18n from "../../i18n";

type HeaderProps = {
  user: UserType;
  prevPath: string;
};

const Header = ({ user, prevPath }: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between w-full p-mobile md:p-desktop">
      <CancelButton pathname={prevPath} />
      {user.isCurrentUser ? (
        <div className="flex">
          <Link
            to={`${ROUTES.PROFILE}/${user.id}/edit`}
            className="pill bg-black text-white text-13 font-bold flex items-center mr-2.5 transition duration-300 hover:bg-light-grey hover:text-dark-grey group"
          >
            <Pencil />
            <span className="ml-1.5">{t("profile.edit")}</span>
          </Link>
          <div className="flex items-center">
            <Echo className="w-[30px] h-[30px]" />
            <span className="ml-2.5 text-dark-grey font-bold">
              {user.profile?.balance}
            </span>
          </div>
          {/*
            <Link to={`${ROUTES.PROFILE}/${user.id}/edit/time`} className="flex items-center">
              <Echo className="w-[30px] h-[30px]" />
              <span className="ml-2.5 text-dark-grey font-bold">{user.profile?.time}</span>
            </Link>
          */}
        </div>
      ) : (
        <Dropdown type="member" id={user.id} user={user} />
      )}
    </div>
  );
};

const Profile = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [translation, setTranslation] = useState(false);

  const [tProfile, { data: dataTProfile }] = useLazyQuery(TRANSLATIONS_QUERY);

  const { client, loading, error, data } = useQuery(USER_PROFILE_QUERY, {
    variables: { id: userId },
  });

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  // const [revokeToken, resultRevokeToken] = useMutation(REVOKE_TOKEN_MUTATION);

  const logOut = () => {
    client.resetStore();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  // useEffect(() => {
  //   if (resultRevokeToken.data) {
  //     if (resultRevokeToken.data.revokeToken.success) {
  //       logOut();
  //     }
  //   }
  // }, [resultRevokeToken.data, client, navigate]);

  if (loading || error) return null;

  const user = data.user;

  // const timeTaken =
  //   user.profile?.taken !== 1
  //     ? `${user.profile?.taken} hours given`
  //     : `${user.profile?.taken} hour given`;

  const handleClick = () => {
    getSHA256Hash(user.profile?.bio as string).then((key) => {
      tProfile({
        variables: {
          keys: [key],
          values: [user.profile?.bio as string],
          language: getLanguage(i18n.language),
        },
      });
      setTranslation(!translation);
    });
  };

  return (
    <>
      <div className="h-full">
        <Header
          prevPath={(state as LocationStateType)?.prevPath || "/members"}
          user={user}
        />
        <div className="flex flex-col h-[calc(100%-210px)] overflow-y-scroll border-y border-border">
          <div className="p-mobile md:p-desktop">
            <Card user={user} />

            <div className="flex mt-[15px]">
              {user.profile?.skills.length > 0 && (
                <Skills skills={user.profile?.skills || []} />
              )}
            </div>

            <Exchange time={user.profile?.taken} />
          </div>

          <Overview
            linkedIn={user.profile?.linkedIn}
            location={user.profile?.location}
            languages={user.profile?.languages || []}
            strings={true}
          />

          <div className="p-mobile md:p-desktop flex flex-col">
            <span className="font-bold mb-5 text-16 md:text-18">
              {t("profile.about")}
            </span>
            {user.profile?.bio ? (
              translation ? (
                dataTProfile?.translations[0]?.text
              ) : (
                user.profile?.bio
              )
            ) : (
              <span className="text-grey italic">{t("profile.bio")}</span>
            )}
            <div className="widgets mt-1.5">
              <TranslateButton active={translation} onClick={handleClick} />
            </div>
          </div>

          {user.isCurrentUser && (
            <>
              <div className="mb-mobile md:mb-desktop mx-auto w-[calc(100%-40px)] md:w-[calc(100%-60px)]">
                <ProfileProgress />
              </div>

              <Link
                to={`${ROUTES.PROFILE}/${user.id}/saved`}
                className="p-mobile md:p-desktop border-t border-border transition duration-300 hover:bg-offers text-18 text-offers hover:text-white flex items-center leading-none cursor-pointer group"
              >
                <Save />
                <span className="ml-2.5">{t("profile.saved")}</span>
                <span className="ml-[5px] font-bold">
                  (
                  {user.profile.savedOffers.length +
                    user.profile.savedUsers.edges.length}
                  )
                </span>
              </Link>
            </>
          )}

          <Offers user={user} />
          <Calendar user={user} />
          {user.profile.notes.length > 0 && <Thanks user={user} />}
        </div>
      </div>

      <Button
        title={
          user.isCurrentUser
            ? t("profile.logout")
            : t("profile.message", { name: user.firstName })
        }
        type="members"
        className="fixed w-[calc(100%-40px)] bottom-mobile left-1/2 translate-x-[-50%] md:w-[calc(700px-60px)] xl:w-[calc(50%-60px)]  md:bottom-desktop md:right-desktop md:left-auto md:translate-x-0 md:max-w-none"
        onClick={() => {
          user.isCurrentUser
            ? logOut()
            : createDialogue({ variables: { userId: userId } });
        }}
      />
    </>
  );
};

const RedirectProfile = () => {
  const { loading, error, data } = useQuery(ME_QUERY, {});
  if (loading || error) return null;
  return <Navigate to={`${ROUTES.PROFILE}/${data.me.pk}`} />;
};

const ProfileView = () => {
  return (
    <Routes>
      <Route index element={<RedirectProfile />} />
      <Route path={`:userId/:offerId/edit`} element={<OfferEdit />} />
      <Route path={`:userId/:offerId/delete`} element={<OfferEdit />} />
      <Route path={`:userId/saved`} element={<Saved />} />
      <Route path={`:userId/edit`} element={<ProfileEdit />} />
      <Route path={`:userId/edit/:mode`} element={<ProfileEdit />} />
      <Route path={`:userId/:offerId`} element={<OfferDetails />} />
      <Route path={`:userId`} element={<Profile />} />
    </Routes>
  );
};

export default ProfileView;
