import { ApolloClient, from } from "@apollo/client";

import authLink from "./authLink";
import errorLink from "./errorLink";
import splitLink from "./splitLink";
import cache from "./cache";

// Create the primary Apollo client instance
// with support for HTTP and Websocket handling.
export default new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: cache,
  connectToDevTools: true,
});
