import React from "react";

import { IMAGE_PATH } from "../../constants";

type UserProps = {
  image?: string;
  name?: string;
}

export const ProfileImage = ({ image, name }: UserProps) => (
  image ? (
    <img
      className="w-[50px] h-[50px] rounded-full shrink-0 bg-offers-100"
      src={`${IMAGE_PATH}${image}`}
      alt={name}
    />
  ) : (
    <span
      className={`w-[50px] h-[50px] rounded-full font-bold text-20 text-white flex items-center justify-center gradient-1 pt-px shrink-0`}
    >
      {name?.charAt(0)}{name?.split(" ")[1].charAt(0)}
    </span>
  )
);

export default ProfileImage;