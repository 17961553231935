import React from "react";
import { useTranslation } from "react-i18next";

import { SkillEntryType, SkillType } from "../../types";
import { Icon } from "../../assets/icons/skills";

// Members

type SkillProps = {
  type: SkillEntryType;
  style?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Skill = ({ type, style, onClick }: SkillProps) => {
  const { t } = useTranslation();
  return (
    <span
      className={`pill !font-normal text-members mt-[5px] md:mt-2.5 mr-[5px] ${
        style === "outline"
          ? "border border-members/20"
          : "bg-members-100 font-bold"
      }`}
      onClick={onClick}
    >
      {t(type.skill.title)}
    </span>
  );
};

type SkillsProps = {
  skills: SkillEntryType[];
  style?: string;
  reduced?: boolean;
};

export const Skills = ({ skills, style, reduced }: SkillsProps) => {
  const limit = reduced ? 3 : skills.length;

  return (
    <div
      className={`flex flex-wrap ${skills && skills.length > 0 ? "" : "empty"}`}
    >
      {skills.map(
        (skill: SkillEntryType, index: string | number | undefined) =>
          index! < limit && (
            <Skill
              type={skill}
              style={style}
              key={index}
              onClick={() => null}
            />
          )
      )}
      {skills.length > 3 && reduced && (
        <span
          className={`pill !font-normal text-members mt-[5px] md:mt-2.5 mr-[5px] ${
            style === "outline"
              ? "border border-members/20"
              : "bg-members-100 font-bold"
          }`}
        >
          +{skills.length - 3}
        </span>
      )}
    </div>
  );
};

// Offers

type SkillItemProps = {
  skill: SkillType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SkillItem = ({ skill, onClick }: SkillItemProps) => {
  const {t} = useTranslation();
  return (
    <span
      className="pill bg-offers-100 text-offers mt-[5px] md:mt-2.5 mr-[5px]"
      onClick={onClick}
    >
      {t(skill.title)}
    </span>
  );
};

type SkillListProps = {
  skills: SkillType[];
};

export const SkillList = ({ skills }: SkillListProps) => (
  <div className="flex">
    {skills.map((skill: SkillType, index: string | number | undefined) => (
      <SkillItem skill={skill} key={index} onClick={() => null} />
    ))}
  </div>
);

// Selecting Skills

type SkillSelectProps = {
  title: string;
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const SkillSelect = ({ title, active, onClick }: SkillSelectProps) => (
  <span
    className={`${
      active ? "opacity-100" : "opacity-40"
    } flex flex-col shrink-0 items-center font-bold text-offers mr-[30px] md:mr-[60px] last:mr-[30px] group cursor-pointer transition duration-300 hover:opacity-100`}
    onClick={onClick}
  >
    <div className="group-hover:scale-110 transition duration-300">
      <Icon title={title} />
    </div>
    <span className="mt-3">{title}</span>
  </span>
);

// Add Offer

export const SkillStrings = ({ skills }: { skills: string[] }) => (
  <div className="flex">
    {skills.map((skill, index: string | number | undefined) => (
      <span
        key={index}
        className="pill bg-offers-100 text-offers mt-[5px] md:mt-2.5 mr-[5px]"
      >
        {skill}
      </span>
    ))}
  </div>
);
