import React, { useState } from "react";

type ToastProps = {
  message: string;
  className?: string;
  show: boolean;
  closeLabel?: string;
};

export const Toast = ({
  message,
  className,
  show,
  closeLabel = "×",
}: ToastProps) => {
  const [state, setState] = useState({
    show: show,
  });
  return (
    <div
      className={`fixed left-0 bottom-0 transition-all duration-600 w-full py-3.5 px-5 flex items-center justify-between bg-offers text-white font-bold ${state.show ? "translate-y-0" : "translate-y-full"} ${className}`}
    >
      <span className="leading-snug">{message}</span>
      <div className="text-24 cursor-pointer transition hover:scale-110" onClick={(e) => setState({ show: false })}>
        {closeLabel}
      </div>
    </div>
  );
};
