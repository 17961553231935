import { ErrorType } from "../../types";

export type ValidationErrorTypes = {
  code: string;
  message: string;
};

export type ValidationType = {
  type: string;
  errors: ValidationErrorTypes[];
};

export type FormStateType = {
  step: Step;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  picture: string;
  savingImage: boolean;
  editingImage: boolean;
  time: number;
  skills: string[];
  errors: ErrorType[];
  validation: ValidationType[];
  roles: string[];
  places: string[];
  selectedPlaces: string[];
  communities: string[];
};

export enum Step {
  Profile,
  Communities,
  Skills,
  Complete,
}

export type StageProps = {
  formState: FormStateType;
  setFormState: (formState: FormStateType) => void;
};

export enum Mode {
  START,
  SIGNUP,
  SIGNUP_GOOGLE,
}
