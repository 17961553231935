import React from "react";

export const ArrowLeft = () => (
  <svg className="h-3.5 w-auto" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.88 1.88L8 0L0 8L8 16L9.88 14.12L3.77333 8L9.88 1.88Z" className="fill-grey transition group-hover:fill-white"/>
  </svg>
)

export const Case = () => (
  <svg className="h-[14px] w-auto mr-1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / basic / case">
      <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8V5C8 3.89543 8.89543 3 10 3ZM4 8H8H16H20V13H13H11H4V8ZM4 19V15H11V16H13V15H20V19H4ZM14 5V6H10V5H14Z" fill="#7E7E7E"/>
    </g>
  </svg>
)

export const Availability = ({type, style} : {type?: string, style?: string}) => (
  <svg className={`${type === "button" ? "h-2.5" : type === "filter" ? "h-[15px]" : "h-[15px]"} w-auto`} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 15.5C12.6421 15.5 16 12.1421 16 8C16 3.85786 12.6421 0.5 8.5 0.5C4.35786 0.5 1 3.85786 1 8C1 12.1421 4.35786 15.5 8.5 15.5Z" className={`${(type === "button" || type === "filter") ? style === "members" ? "stroke-members" : "stroke-offers" : "stroke-grey"}`} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.49971 1.25H6.24971C4.78721 5.63 4.78721 10.37 6.24971 14.75H5.49971" className={`${(type === "button" || type === "filter") ? style === "members" ? "stroke-members" : "stroke-offers" : "stroke-grey"}`} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.75 1.25C12.2125 5.63 12.2125 10.37 10.75 14.75" className={`${((type === "button" || type === "filter") || type === "filter") ? style === "members" ? "stroke-members" : "stroke-offers" : "stroke-grey"}`} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.75 11V10.25C6.13 11.7125 10.87 11.7125 15.25 10.25V11" className={`${(type === "button" || type === "filter") ? style === "members" ? "stroke-members" : "stroke-offers" : "stroke-grey"}`} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.75 5.74995C6.13 4.28745 10.87 4.28745 15.25 5.74995" className={`${(type === "button" || type === "filter") ? style === "members" ? "stroke-members" : "stroke-offers" : "stroke-grey"}`} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Region = ({type, style} : {type?: string, style?: string}) => (
  <svg className={`${type === "button" && '-mt-px'}`} width={type === "button" ? '11' : '16'} height={type === "button" ? '11' : '17'} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g mask="url(#mask0_1728_30980)">
    <path className={`${style === "members" ? "fill-members" : "fill-offers"}`} d="M15.3313 26.1667C14.6316 26.1667 14.1482 25.4665 14.3963 24.8122L19.0686 12.4894C19.1423 12.2952 19.3284 12.1667 19.5362 12.1667H21.2959C21.5036 12.1667 21.6897 12.2952 21.7634 12.4894L26.4358 24.8122C26.6838 25.4665 26.2005 26.1667 25.5007 26.1667H25.2127C24.7859 26.1667 24.4061 25.8958 24.2672 25.4922L23.2744 22.6084H17.616L16.571 25.506C16.428 25.9025 16.0518 26.1667 15.6303 26.1667H15.3313ZM18.316 20.5667H22.516L20.4744 14.7917H20.3577L18.316 20.5667ZM5.37312 21.9596C4.9826 22.3501 4.34943 22.3501 3.95891 21.9596L3.73979 21.7405C3.34926 21.35 3.34927 20.7168 3.73979 20.3263L8.92435 15.1417C8.18546 14.325 7.53893 13.4792 6.98477 12.6042C6.6959 12.1481 6.42685 11.6788 6.1776 11.1963C5.84956 10.5612 6.33084 9.83337 7.04564 9.83337H7.37298C7.75175 9.83337 8.09561 10.0488 8.2774 10.3811C8.54321 10.8669 8.82206 11.3163 9.11393 11.7292C9.51254 12.2931 9.99379 12.8862 10.5577 13.5084C11.4132 12.575 12.123 11.6174 12.6868 10.6355C13.2507 9.65351 13.7271 8.60837 14.116 7.50004H2.16602C1.61373 7.50004 1.16602 7.05233 1.16602 6.50004V6.16671C1.16602 5.61442 1.61373 5.16671 2.16602 5.16671H9.33268V3.83337C9.33268 3.28109 9.7804 2.83337 10.3327 2.83337H10.666C11.2183 2.83337 11.666 3.28109 11.666 3.83337V5.16671H18.8327C19.385 5.16671 19.8327 5.61442 19.8327 6.16671V6.50004C19.8327 7.05233 19.385 7.50004 18.8327 7.50004H16.4494C16.041 8.8806 15.4868 10.2223 14.7868 11.525C14.0869 12.8278 13.2216 14.0528 12.191 15.2L14.5494 17.6076C14.8192 17.883 14.9067 18.2889 14.7742 18.651L14.6543 18.9786C14.4033 19.6649 13.5248 19.8589 13.0081 19.3421L10.4993 16.8334L5.37312 21.9596Z" />
    </g>
  </svg>
)

export const Language = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke-grey" d="M15.5 3.6875V7.51248C15.5 8.46498 15.185 9.26749 14.6225 9.82249C14.0675 10.385 13.265 10.7 12.3125 10.7V12.0575C12.3125 12.5675 11.7425 12.875 11.3225 12.59L10.595 12.11C10.6625 11.8775 10.6925 11.6225 10.6925 11.3525V8.30002C10.6925 6.77002 9.6725 5.75 8.1425 5.75H3.05C2.945 5.75 2.8475 5.75751 2.75 5.76501V3.6875C2.75 1.775 4.025 0.5 5.9375 0.5H12.3125C14.225 0.5 15.5 1.775 15.5 3.6875Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path className="stroke-grey" d="M10.6925 8.30002V11.3525C10.6925 11.6225 10.6625 11.8775 10.595 12.11C10.3175 13.2125 9.4025 13.9025 8.1425 13.9025H6.1025L3.8375 15.41C3.5 15.6425 3.05 15.395 3.05 14.99V13.9025C2.285 13.9025 1.6475 13.6475 1.205 13.205C0.755002 12.755 0.5 12.1175 0.5 11.3525V8.30002C0.5 6.87502 1.385 5.89251 2.75 5.76501C2.8475 5.75751 2.945 5.75 3.05 5.75H8.1425C9.6725 5.75 10.6925 6.77002 10.6925 8.30002Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const LinkedIn = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill-grey" fillRule="evenodd" clipRule="evenodd" d="M3.54167 1.38574C2.46586 1.38574 1.59375 2.25786 1.59375 3.33366C1.59375 4.40946 2.46586 5.28158 3.54167 5.28158C4.61747 5.28158 5.48958 4.40946 5.48958 3.33366C5.48958 2.25786 4.61747 1.38574 3.54167 1.38574ZM2.65625 3.33366C2.65625 2.84465 3.05266 2.44824 3.54167 2.44824C4.03067 2.44824 4.42708 2.84465 4.42708 3.33366C4.42708 3.82266 4.03067 4.21908 3.54167 4.21908C3.05266 4.21908 2.65625 3.82266 2.65625 3.33366Z"/>
    <path className="fill-grey" fillRule="evenodd" clipRule="evenodd" d="M1.59375 6.16699C1.59375 5.87359 1.8316 5.63574 2.125 5.63574H4.95833C5.25173 5.63574 5.48958 5.87359 5.48958 6.16699V15.3753C5.48958 15.6687 5.25173 15.9066 4.95833 15.9066H2.125C1.8316 15.9066 1.59375 15.6687 1.59375 15.3753V6.16699ZM2.65625 6.69824V14.8441H4.42708V6.69824H2.65625Z"/>
    <path className="fill-grey" fillRule="evenodd" clipRule="evenodd" d="M6.55176 6.16699C6.55176 5.87359 6.78961 5.63574 7.08301 5.63574H9.91634C10.2097 5.63574 10.4476 5.87359 10.4476 6.16699V6.47442L10.7559 6.34229C11.286 6.11511 11.8529 5.97266 12.4262 5.92054C14.3917 5.74185 16.1143 7.28643 16.1143 9.26963V15.3753C16.1143 15.6687 15.8764 15.9066 15.583 15.9066H12.7497C12.4563 15.9066 12.2184 15.6687 12.2184 15.3753V10.417C12.2184 10.1822 12.1251 9.95693 11.9591 9.7909C11.7931 9.62486 11.5678 9.53157 11.333 9.53157C11.0982 9.53157 10.8729 9.62486 10.7069 9.7909C10.5409 9.95693 10.4476 10.1822 10.4476 10.417V15.3753C10.4476 15.6687 10.2097 15.9066 9.91634 15.9066H7.08301C6.78961 15.9066 6.55176 15.6687 6.55176 15.3753V6.16699ZM7.61426 6.69824V14.8441H9.38509V10.417C9.38509 9.9004 9.5903 9.40492 9.95565 9.03964C10.3209 8.67428 10.8164 8.46907 11.333 8.46907C11.8496 8.46907 12.3451 8.67428 12.7104 9.03964C13.0757 9.40492 13.2809 9.9004 13.2809 10.417V14.8441H15.0518V9.26963C15.0518 7.92075 13.8752 6.85568 12.5224 6.97868C12.059 7.02079 11.601 7.13604 11.1745 7.31888L10.1256 7.76839C9.96146 7.83873 9.77297 7.82187 9.62387 7.72363C9.47484 7.62531 9.38509 7.45867 9.38509 7.28009V6.69824H7.61426Z" />
  </svg>
)

export const Arrow = ({direction, button} : {direction?: string; button?: boolean}) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={`cursor-pointer ${direction === "left" ? "rotate-180 mr-1.5" : direction === "right" ? "rotate-0 ml-1.5" : undefined}`}>
    <path className={button ? `fill-members group-hover:fill-white transition duration-300` : `fill-black`} d="M8.51938 0.714355L15.9999 8.50008L8.51938 16.2858L7.44148 15.1639L13.0817 9.29348H0.439941V7.70688H13.0819L7.44148 1.83625L8.51938 0.714355Z" />
  </svg>
)

export const Pencil = () => (
  <svg className="h-3 w-auto" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill-dark-grey" d="M8.545 4.765L9.235 5.455L2.44 12.25H1.75V11.56L8.545 4.765ZM11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.825 2.9875 13.825 2.515 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 2.6425L0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425Z"/>
  </svg>
)

export const Search = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill-dark-grey transition duration-300 group-hover:fill-white" d="M14.9375 13.25H14.0487L13.7337 12.9462C14.8362 11.6637 15.5 9.99875 15.5 8.1875C15.5 4.14875 12.2262 0.875 8.1875 0.875C4.14875 0.875 0.875 4.14875 0.875 8.1875C0.875 12.2262 4.14875 15.5 8.1875 15.5C9.99875 15.5 11.6637 14.8362 12.9462 13.7337L13.25 14.0487V14.9375L18.875 20.5512L20.5512 18.875L14.9375 13.25ZM8.1875 13.25C5.38625 13.25 3.125 10.9887 3.125 8.1875C3.125 5.38625 5.38625 3.125 8.1875 3.125C10.9887 3.125 13.25 5.38625 13.25 8.1875C13.25 10.9887 10.9887 13.25 8.1875 13.25Z" />
  </svg>
)

export const Delete = () => (
  <svg width="13" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 4.97998C15.67 4.64998 12.32 4.47998 8.98 4.47998C7 4.47998 5.02 4.57998 3.04 4.77998L1 4.97998" className="stroke-dark-grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.5 3.97L6.72 2.66C6.88 1.71 7 1 8.69 1H11.31C13 1 13.13 1.75 13.28 2.67L13.5 3.97" className="stroke-dark-grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.85 8.14001L16.2 18.21C16.09 19.78 16 21 13.21 21H6.79002C4.00002 21 3.91002 19.78 3.80002 18.21L3.15002 8.14001" className="stroke-dark-grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.33002 15.5H11.66" className="stroke-dark-grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 11.5H12.5" className="stroke-dark-grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Add = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12H16" className="stroke-members transition duration-300 group-hover:stroke-white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 16V8" className="stroke-members transition duration-300 group-hover:stroke-white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" className="stroke-members transition duration-300 group-hover:stroke-white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Send = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke-white" d="M5.34099 4.24525L13.9431 1.37789C17.8034 0.0911245 19.9007 2.19858 18.6241 6.05888L15.7567 14.661C13.8316 20.4463 10.6704 20.4463 8.74535 14.661L7.89426 12.1077L5.34099 11.2566C-0.444392 9.33152 -0.444392 6.18047 5.34099 4.24525Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path className="stroke-white" d="M8.08594 11.6716L11.7132 8.03418" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Save = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill-offers transition duration-300 group-hover:fill-white" d="M9.29743 1.13248L10.6174 3.77248C10.7974 4.13998 11.2774 4.49248 11.6824 4.55998L14.0749 4.95748C15.6049 5.21248 15.9649 6.32248 14.8624 7.41748L13.0024 9.27748C12.6874 9.59248 12.5149 10.2 12.6124 10.635L13.1449 12.9375C13.5649 14.76 12.5974 15.465 10.9849 14.5125L8.74243 13.185C8.33743 12.945 7.66993 12.945 7.25743 13.185L5.01493 14.5125C3.40993 15.465 2.43493 14.7525 2.85493 12.9375L3.38743 10.635C3.48493 10.2 3.31243 9.59248 2.99743 9.27748L1.13743 7.41748C0.0424318 6.32248 0.394932 5.21248 1.92493 4.95748L4.31743 4.55998C4.71493 4.49248 5.19493 4.13998 5.37493 3.77248L6.69493 1.13248C7.41493 -0.30002 8.58493 -0.30002 9.29743 1.13248Z"/>
  </svg>
)

export const SaveOutline = () => (
  <svg className="w-[18px] h-[18px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke-grey" d="M11.5855 2.21761L13.1988 5.44427C13.4188 5.89344 14.0055 6.32427 14.5005 6.40677L17.4247 6.8926C19.2947 7.20427 19.7347 8.56094 18.3872 9.89927L16.1138 12.1726C15.7288 12.5576 15.518 13.3001 15.6372 13.8318L16.288 16.6459C16.8013 18.8734 15.6188 19.7351 13.648 18.5709L10.9072 16.9484C10.4122 16.6551 9.59634 16.6551 9.09217 16.9484L6.35134 18.5709C4.38967 19.7351 3.19801 18.8643 3.71134 16.6459L4.36217 13.8318C4.48134 13.3001 4.27051 12.5576 3.88551 12.1726L1.61217 9.89927C0.273839 8.56094 0.704673 7.20427 2.57467 6.8926L5.49884 6.40677C5.98467 6.32427 6.57134 5.89344 6.79134 5.44427L8.40467 2.21761C9.28467 0.466772 10.7147 0.466772 11.5855 2.21761Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const SendOutline = () => (
  <svg className="w-[18px] h-[18px]" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke-grey" d="M4.78109 3.79336L12.5636 1.19919C16.0561 0.035027 17.9536 1.94169 16.7986 5.43419L14.2044 13.2167C12.4628 18.4509 9.60276 18.4509 7.86109 13.2167L7.09109 10.9067L4.78109 10.1367C-0.453073 8.39503 -0.453073 5.54419 4.78109 3.79336Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path className="stroke-grey" d="M7.26514 10.5125L10.5468 7.2217" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const MessageOutline = () => (
  <svg className="w-[18px] h-[18px]" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="stroke-grey" d="M16.4818 9.89083V13.5575C16.4818 13.7958 16.4727 14.025 16.4452 14.245C16.2343 16.72 14.7768 17.9483 12.091 17.9483H11.7243C11.4952 17.9483 11.2752 18.0583 11.1377 18.2417L10.0377 19.7083C9.55183 20.3592 8.7635 20.3592 8.27766 19.7083L7.17766 18.2417C7.10429 18.1562 7.01461 18.0863 6.91388 18.0359C6.81314 17.9855 6.70337 17.9558 6.591 17.9483H6.22433C3.30016 17.9483 1.8335 17.2242 1.8335 13.5575V9.89083C1.8335 7.205 3.071 5.7475 5.53683 5.53667C5.75683 5.50917 5.986 5.5 6.22433 5.5H12.091C15.0152 5.5 16.4818 6.96667 16.4818 9.89083Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path className="stroke-grey" d="M20.1483 6.22384V9.89051C20.1483 12.5855 18.9108 14.0338 16.445 14.2447C16.4725 14.0247 16.4816 13.7955 16.4816 13.5572V9.89051C16.4816 6.96634 15.015 5.49967 12.0908 5.49967H6.22412C5.98579 5.49967 5.75662 5.50884 5.53662 5.53634C5.74745 3.07051 7.20495 1.83301 9.89079 1.83301H15.7575C18.6816 1.83301 20.1483 3.29967 20.1483 6.22384Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path className="stroke-grey" d="M12.3708 12.1455H12.3799M9.16243 12.1455H9.1716M5.9541 12.1455H5.96327" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Write = () => (
  <svg width="28" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1223 2.25H8.37231C3.99731 2.25 2.24731 4 2.24731 8.375V13.625C2.24731 18 3.99731 19.75 8.37231 19.75H13.6223C17.9973 19.75 19.7473 18 19.7473 13.625V11.875" className="stroke-members group-hover:stroke-white transition duration-300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5381 3.1426L7.64307 10.0376C7.38057 10.3001 7.11807 10.8163 7.06557 11.1926L6.68932 13.8263C6.54932 14.7801 7.22307 15.4451 8.17682 15.3138L10.8106 14.9376C11.1781 14.8851 11.6943 14.6226 11.9656 14.3601L18.8606 7.4651C20.0506 6.2751 20.6106 4.8926 18.8606 3.1426C17.1106 1.3926 15.7281 1.9526 14.5381 3.1426Z" className="stroke-members group-hover:stroke-white transition duration-300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5471 4.13184C14.1334 6.22309 15.7696 7.85934 17.8696 8.45434" className="stroke-members group-hover:stroke-white transition duration-300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const UnsaveOutline = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5855 3.21767L14.1988 6.44433C14.4188 6.8935 15.0055 7.32433 15.5005 7.40683L18.4247 7.89267C20.2947 8.20433 20.7347 9.561 19.3872 10.8993L17.1138 13.1727C16.7288 13.5577 16.518 14.3002 16.6372 14.8318L17.288 17.646C17.8013 19.8735 16.6188 20.7352 14.648 19.571L11.9072 17.9485C11.4122 17.6552 10.5963 17.6552 10.0922 17.9485L7.35134 19.571C5.38967 20.7352 4.19801 19.8643 4.71134 17.646L5.36217 14.8318C5.48134 14.3002 5.27051 13.5577 4.88551 13.1727L2.61217 10.8993C1.27384 9.561 1.70467 8.20433 3.57467 7.89267L6.49884 7.40683C6.98467 7.32433 7.57134 6.8935 7.79134 6.44433L9.40467 3.21767C10.2847 1.46683 11.7147 1.46683 12.5855 3.21767Z" className="stroke-grey" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7175 14.4244C12.9128 14.6196 13.2294 14.6196 13.4246 14.4244C13.6199 14.2291 13.6199 13.9125 13.4246 13.7173L11.6569 11.9495L13.4246 10.1817C13.6199 9.98647 13.6199 9.66989 13.4246 9.47463C13.2294 9.27937 12.9128 9.27937 12.7175 9.47463L10.9497 11.2424L9.18198 9.47463C8.98672 9.27937 8.67014 9.27937 8.47487 9.47463C8.27961 9.66989 8.27961 9.98647 8.47487 10.1817L10.2426 11.9495L8.47487 13.7173C8.27961 13.9125 8.27961 14.2291 8.47487 14.4244C8.67014 14.6196 8.98672 14.6196 9.18198 14.4244L10.9497 12.6566L12.7175 14.4244Z" className="fill-grey"/>
  </svg>
)

export const Heart = () => (
  <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.22034 6.39779C4.09945 6.43889 3.90034 6.43889 3.77945 6.39779C2.74834 6.05879 0.444336 4.64456 0.444336 2.24755C0.444336 1.18945 1.32967 0.333374 2.42122 0.333374C3.06834 0.333374 3.64078 0.634712 3.99989 1.10042C4.359 0.634712 4.935 0.333374 5.57856 0.333374C6.67011 0.333374 7.55545 1.18945 7.55545 2.24755C7.55545 4.64456 5.25145 6.05879 4.22034 6.39779Z" className="fill-white"/>
  </svg>
)

export const AccessSupport = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7866 7.19836C22.12 8.82003 23.73 11.3984 24.0566 14.3734" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.07178 14.4317C4.37511 11.4683 5.96178 8.88999 8.27178 7.25665" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.55469 24.43C10.908 25.1183 12.448 25.5033 14.0697 25.5033C15.633 25.5033 17.103 25.1533 18.4214 24.5117" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.0695 8.98334C15.8607 8.98334 17.3128 7.53125 17.3128 5.74001C17.3128 3.94876 15.8607 2.49667 14.0695 2.49667C12.2783 2.49667 10.8262 3.94876 10.8262 5.74001C10.8262 7.53125 12.2783 8.98334 14.0695 8.98334Z" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.63493 23.24C7.42618 23.24 8.87827 21.7879 8.87827 19.9967C8.87827 18.2054 7.42618 16.7533 5.63493 16.7533C3.84369 16.7533 2.3916 18.2054 2.3916 19.9967C2.3916 21.7879 3.84369 23.24 5.63493 23.24Z" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3654 23.24C24.1566 23.24 25.6087 21.7879 25.6087 19.9967C25.6087 18.2054 24.1566 16.7533 22.3654 16.7533C20.5742 16.7533 19.1221 18.2054 19.1221 19.9967C19.1221 21.7879 20.5742 23.24 22.3654 23.24Z" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const OfferSupport = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9998 14C17.2215 14 19.8332 11.3883 19.8332 8.16668C19.8332 4.94502 17.2215 2.33334 13.9998 2.33334C10.7782 2.33334 8.1665 4.94502 8.1665 8.16668C8.1665 11.3883 10.7782 14 13.9998 14Z" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.97852 25.6667C3.97852 21.1517 8.47018 17.5 14.0002 17.5C15.1202 17.5 16.2052 17.6517 17.2202 17.9317" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25.6668 21C25.6668 21.875 25.4218 22.7033 24.9902 23.4033C24.7452 23.8233 24.4302 24.1967 24.0685 24.5C23.2518 25.235 22.1785 25.6667 21.0002 25.6667C19.2968 25.6667 17.8151 24.7567 17.0101 23.4033C16.5785 22.7033 16.3335 21.875 16.3335 21C16.3335 19.53 17.0102 18.2117 18.0835 17.36C18.8885 16.7183 19.9035 16.3333 21.0002 16.3333C23.5785 16.3333 25.6668 18.4217 25.6668 21Z" stroke="#6A4783" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.1797 21L20.3347 22.155L22.8197 19.8567" stroke="#6A4783" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)


export const Community = ({type, style} : {type?: string, style?: string}) => (
  <svg className={`${type === "button" && '-mt-px'}`} width={type === "button" ? '11' : '16'} height={type === "button" ? '11' : '17'} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g mask="url(#mask0_1728_30980)">
    <path className={`${style === "members" ? "fill-members" : "fill-offers"}`} d="M15.3313 26.1667C14.6316 26.1667 14.1482 25.4665 14.3963 24.8122L19.0686 12.4894C19.1423 12.2952 19.3284 12.1667 19.5362 12.1667H21.2959C21.5036 12.1667 21.6897 12.2952 21.7634 12.4894L26.4358 24.8122C26.6838 25.4665 26.2005 26.1667 25.5007 26.1667H25.2127C24.7859 26.1667 24.4061 25.8958 24.2672 25.4922L23.2744 22.6084H17.616L16.571 25.506C16.428 25.9025 16.0518 26.1667 15.6303 26.1667H15.3313ZM18.316 20.5667H22.516L20.4744 14.7917H20.3577L18.316 20.5667ZM5.37312 21.9596C4.9826 22.3501 4.34943 22.3501 3.95891 21.9596L3.73979 21.7405C3.34926 21.35 3.34927 20.7168 3.73979 20.3263L8.92435 15.1417C8.18546 14.325 7.53893 13.4792 6.98477 12.6042C6.6959 12.1481 6.42685 11.6788 6.1776 11.1963C5.84956 10.5612 6.33084 9.83337 7.04564 9.83337H7.37298C7.75175 9.83337 8.09561 10.0488 8.2774 10.3811C8.54321 10.8669 8.82206 11.3163 9.11393 11.7292C9.51254 12.2931 9.99379 12.8862 10.5577 13.5084C11.4132 12.575 12.123 11.6174 12.6868 10.6355C13.2507 9.65351 13.7271 8.60837 14.116 7.50004H2.16602C1.61373 7.50004 1.16602 7.05233 1.16602 6.50004V6.16671C1.16602 5.61442 1.61373 5.16671 2.16602 5.16671H9.33268V3.83337C9.33268 3.28109 9.7804 2.83337 10.3327 2.83337H10.666C11.2183 2.83337 11.666 3.28109 11.666 3.83337V5.16671H18.8327C19.385 5.16671 19.8327 5.61442 19.8327 6.16671V6.50004C19.8327 7.05233 19.385 7.50004 18.8327 7.50004H16.4494C16.041 8.8806 15.4868 10.2223 14.7868 11.525C14.0869 12.8278 13.2216 14.0528 12.191 15.2L14.5494 17.6076C14.8192 17.883 14.9067 18.2889 14.7742 18.651L14.6543 18.9786C14.4033 19.6649 13.5248 19.8589 13.0081 19.3421L10.4993 16.8334L5.37312 21.9596Z" />
    </g>
  </svg>
)