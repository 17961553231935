import React from "react";
import { useTranslation } from "react-i18next";

import ProfileImage from "./ProfileImage";

import { MessageType } from "../../types";
import { formatTime } from "../core/utils";

import ECHO from "../../assets/svg/brand/echo.svg";

type TradeMessageProps = {
  message: MessageType;
};

export const TradeMessage = ({ message }: TradeMessageProps) => {
  const { t } = useTranslation();
  const unitString =
    message.trade && message.trade?.units > 1 ? "Echos" : "Echo";
  return (
    <div className={`flex flex-col mb-5`}>
      <div className={`flex ${message.own && "flex-row-reverse"}`}>
        <ProfileImage
          image={message.author?.profile?.picture}
          name={message.author?.profile?.preferredName}
        />
        <div
          className={`${
            message.own ? "mr-mobile" : "ml-mobile"
          } bg-black text-white px-5 py-3 rounded-xl flex items-center`}
        >
          {message.trade?.recipient?.profile?.preferredName}{" "}
          {t("messages.give.gave")}{" "}
          <img
            src={ECHO}
            alt="Echo Symbol"
            className="w-[30px] h-[30px] mx-2.5"
          />
          <span className="font-bold">
            {message.trade?.units} {unitString}
          </span>
        </div>
      </div>
      <div
        className={`text-12 text-grey mt-5 ml-auto`}
        data-raw={message.timestamp}
      >
        {formatTime(message.timestamp)}
      </div>
    </div>
  );
};
