import React from "react";

import Button from "../core/Button";
import Post from "./Post";

import { UserType } from "../../types";

type ProfileListProps = {
  users?: UserType[];
  more: boolean;
  onLoadMore: any;
};

const Feed = ({
  users,
  more,
  onLoadMore,
}: ProfileListProps) => {
  return (
    <div className="w-full">
      {
        users?.length ? 
          users.reverse().map((user: UserType, index: string | number | undefined) => (
            <Post user={user} key={index} />
          ))
        : 
          <h2 className="p-mobile md:p-desktop text-grey">No members found</h2>
      }
      {more && (
        <Button
          title="Load more"
          onClick={onLoadMore}
          type="members"
          className="mb-[90px] w-[calc(100%-40px)] m-mobile md:m-desktop md:w-[calc(100%-60px)]"
        />
      )}
    </div>
  );
};

export default Feed;
