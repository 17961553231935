import React from "react";
import { useTranslation } from "react-i18next";

import { LanguageType } from "../../types";
import { Language, Availability, LinkedIn } from "../../assets/icons/global";

type OverviewProps = {
  languages?: LanguageType[];
  availability?: string[];
  location?: string;
  linkedIn?: string;
  strings?: boolean;
};

const Overview = ({
  languages,
  availability,
  location,
  linkedIn,
  strings,
}: OverviewProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`border-y border-border p-mobile md:p-desktop flex text-grey text-12 md:text-16 ${
        linkedIn && "justify-between"
      }`}
    >
      {languages && (
        <div className={`flex items-center ${!linkedIn && "mr-12"}`}>
          <Language />
          <span className="ml-2.5">
            {languages
              .map((language) =>
                strings ? t(language as unknown as string) : t(language.name)
              )
              .join(", ")}
          </span>
        </div>
      )}
      {availability && (
        <div className="flex items-center">
          <Availability />
          <span className="ml-2.5">
            {availability.map((available) => t(available)).join(", ")}
          </span>
        </div>
      )}
      {location && (
        <div className="flex items-center">
          <Availability />
          <span className="ml-2.5">{location}</span>
        </div>
      )}
      {linkedIn && (
        <a href={linkedIn} target="_blank" rel="noreferrer" className="flex items-center">
          <LinkedIn />
          <span className="ml-2.5">LinkedIn</span>
        </a>
      )}
    </div>
  );
};

export default Overview;
