import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

// Constants
import { ROUTES } from "../../constants";

// Components
import BackButton from "../core/BackButton";
import Button from "../core/Button";
import { Toast } from "../core/Toast";

// Core logic
import { Title, Input, saveUser } from "./_core";
import { isEmailAddress } from "../core/utils";
import { FormStateType, StageProps, Step } from "./_types";
import { REGISTER_MUTATION, UPDATE_USER_ACCOUNT_MUTATION } from "./_queries";

const validateProfile = (formState: FormStateType) => {
  if (
    isEmailAddress(formState.email) &&
    formState.firstName !== "" &&
    formState.lastName !== "" &&
    formState.password !== ""
  ) {
    return false;
  }
  return true;
};

const ProfileStage = ({ formState, setFormState }: StageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [register, resultRegister] = useMutation(REGISTER_MUTATION);
  const [updateUserAccount, resultUpdateUserAccount] = useMutation(
    UPDATE_USER_ACCOUNT_MUTATION
  );

  useEffect(() => {
    if (resultRegister.data) {
      const token = resultRegister.data.register.token;
      if (token) {
        saveUser(
          { username: formState.email },
          resultRegister.data.register.token,
          resultRegister.data.register.refreshToken
        );
        updateUserAccount({
          variables: {
            firstName: formState.firstName,
            lastName: formState.lastName,
          },
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message: t("sign_up.error"),
            },
          ],
          validation: [
            resultRegister.data.register.errors.username && {
              type: "email",
              errors: resultRegister.data.register.errors.username,
            },
            resultRegister.data.register.errors.password2 && {
              type: "password",
              errors: resultRegister.data.register.errors.password2,
            },
          ],
        });
      }
    }
  }, [resultRegister.data]);

  useEffect(() => {
    if (resultUpdateUserAccount.data) {
      setFormState({
        ...formState,
        errors: [],
        validation: [],
        step: Step.Communities,
      });
    }
  }, [resultUpdateUserAccount.data]);

  return (
    <>
      <BackButton
        onBackLinkClick={() => {
          navigate(ROUTES.SIGNUP);
        }}
      />
      <Title title={t("sign_up.email.first_stage.title") || ""} />
      <div className="w-full flex flex-col items-center px-mobile">
        <Input
          title={t("sign_up.email.first_stage.first_name") || ""}
          value={formState.firstName}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
        />
        <Input
          title={t("sign_up.email.first_stage.last_name") || ""}
          value={formState.lastName}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
        />
        <Input
          type="email"
          title={t("sign_up.email.first_stage.email") || ""}
          value={formState.email}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              email: e.target.value.toLowerCase(),
            })
          }
          error={
            formState.validation.filter(
              (e) => e !== undefined && e.type === "email"
            ).length > 0
          }
          validation={formState.validation}
        />
        <Input
          type="password"
          title={t("sign_up.email.first_stage.password") || ""}
          required={true}
          onChange={(e) =>
            setFormState({
              ...formState,
              password: e.target.value,
            })
          }
          error={
            formState.validation.filter(
              (e) => e !== undefined && e.type === "password"
            ).length > 0
          }
          validation={formState.validation}
        />
        <Button
          className="mt-4 w-full max-w-sm mb-10"
          title={t("sign_up.email.first_stage.next") || ""}
          disabled={validateProfile(formState)}
          onClick={(e) => {
            e.preventDefault();
            register({
              variables: {
                email: formState.email,
                password: formState.password,
              },
            });
          }}
        />
      </div>
      {formState.errors.length !== 0 && (
        <Toast
          show={true}
          className="error"
          message={formState.errors[0].message}
        />
      )}
    </>
  );
};

export default ProfileStage;
