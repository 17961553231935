import React from "react";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import "./BasicEditor.sass";

type BasicEditorProps = {
  initialValue: string;
  placeholder?: string;
  onEditorChange: (content: string) => void;
};

const BasicEditor = ({
  initialValue,
  placeholder = "Tell us about your offer here. Let people know what to expect from the session, and tell us a little bit about your background!",
  onEditorChange,
}: BasicEditorProps) => {
  const theme = "snow";
  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  };
  const formats = ["bold", "italic", "underline"];
  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  React.useEffect(() => {
    if (quill) {
      if (initialValue) {
        quill.clipboard.dangerouslyPasteHTML(initialValue);
      }
      quill.on("text-change", () => {
        onEditorChange(quill.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <div className="basic-editor">
      <div>
        <div ref={quillRef} />
      </div>
    </div>
  );
};

export default BasicEditor;
