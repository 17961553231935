import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import { CTA } from "./_core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { VERIFY_ACCOUNT_MUTATION } from "./_queries";

// Assets
import { ErrorType } from "../../types";
import { Toast } from "../core/Toast";

const VerifyAccount = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    errors: [] as ErrorType[],
  });

  const [verifyAccount, resultVerifyAccount] = useMutation(
    VERIFY_ACCOUNT_MUTATION
  );

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      verifyAccount({
        variables: {
          token: token,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (resultVerifyAccount.data) {
      const success = resultVerifyAccount.data.verifyAccount.success;
      if (success) {
        navigate(ROUTES.OFFERS);
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message: t("activate.error"),
            },
          ],
        });
      }
    }
  }, [resultVerifyAccount.data]);

  return (
    <>
      {formState.errors.length !== 0 && (
        <Toast
          show={true}
          className="error"
          message={formState.errors[0].message}
        />
      )}
      <CTA path="login" link={t("activate.ready") || ""} />
    </>
  );
};

export default VerifyAccount;
